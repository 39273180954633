import moment from "moment";
export const ProductAndStores = {
	screen_composition: {
		components: [
			{
				type: "div",
				id: "product-store-selection",
				pathSrc: "components",
				componentPath: "ui/wrapperDiv/WrapperDiv",
				staticProps: {
					className: "box-container marginBottom-30",
					customStyle: {
						padding: "20px 30px",
					},
				},
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							// for product-store mapping when in edit-flow
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "stepper_breadCrumb",
										value: "Create MD strategy / Select Products & Stores",
										dataType: "object",
									},
								],
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									conditionOperator: "and",
									conditions: [
										{
											comparison: "exists",
											source: "reducer",
											sourceId: "step_1_A",
											subjectId: "strategy_id",
											dataType: "basic",
										},
									],
								},
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										dataType: "basic",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_id",
									},
									{
										source: "fixed",
										dataType: "object",
										value: "strategy",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "section",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_1_A",
										apiResponseKey: "strategy_dates",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A_copy",
										apiResponseKey: "strategy_dates",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A_copy",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "maxStepCount",
										apiResponseKey: "step_count",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A",
										apiResponseKey:
											"configured_by_sku_store_mapping",
										dataType: "basic",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A",
										apiResponseKey:
											"sku_store_mapping_exists",
										dataType: "basic",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A",
										apiResponseKey:
											"configured_by_product_group",
										dataType: "basic",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A",
										apiResponseKey:
											"configured_by_store_group",
										dataType: "basic",
									},
								],
								onComplete: {
									actions: [
										{
											type: "api_function",
											apiEndPoint: "model",
											runOnLoad: true,
											apiMethod: "post",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"strategy_id",
														dataType: "basic",
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_sku_store_mapping",
														dataType: "basic",
														checkValue: false,
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_product_group",
														dataType: "basic",
														checkValue: false,
													},
													{
														comparison: "multiple",
														conditionOperator: "or",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"step_count",
																dataType:
																	"basic",
																checkValue: 0,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"currentStep",
																dataType:
																	"basic",
																checkValue: 0,
															},
														],
													},
												],
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3001,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													dataType: "basic",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"strategy_id",
												},
												{
													source: "fixed",
													dataType: "object",
													value: "sku",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"section",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													overwrite: true,
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey:
														"filteredProducts_original",
													overwrite: true,
													dataType: "object",
												},
											],
										},
										{
											type: "api_function",
											apiEndPoint: "model",
											runOnLoad: true,
											apiMethod: "post",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"strategy_id",
														dataType: "basic",
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_sku_store_mapping",
														dataType: "basic",
														checkValue: false,
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_product_group",
														dataType: "basic",
														checkValue: true,
													},
												],
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3001,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													dataType: "basic",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"strategy_id",
												},
												{
													source: "fixed",
													dataType: "object",
													value: "product_group",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"section",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "productgroups",
													overwrite: true,
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey:
														"filteredProductGroups_original",
													overwrite: true,
													dataType: "object",
												},
											],
										},
										{
											type: "api_function",
											apiEndPoint: "model",
											runOnLoad: true,
											apiMethod: "post",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"strategy_id",
														dataType: "basic",
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_sku_store_mapping",
														dataType: "basic",
														checkValue: false,
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_store_group",
														dataType: "basic",
														checkValue: false,
													},
													{
														comparison: "multiple",
														conditionOperator: "or",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"step_count",
																dataType:
																	"basic",
																checkValue: 0,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"currentStep",
																dataType:
																	"basic",
																checkValue: 0,
															},
														],
													},
												],
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3001,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													dataType: "basic",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"strategy_id",
												},
												{
													source: "fixed",
													dataType: "object",
													value: "store",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"section",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filteredStores",
													overwrite: true,
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey:
														"filteredStores_original",
													overwrite: true,
													dataType: "object",
												},
											],
										},
										{
											type: "api_function",
											apiEndPoint: "model",
											runOnLoad: true,
											apiMethod: "post",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"strategy_id",
														dataType: "basic",
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_sku_store_mapping",
														dataType: "basic",
														checkValue: false,
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"configured_by_store_group",
														dataType: "basic",
														checkValue: true,
													},
												],
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3001,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													dataType: "basic",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"strategy_id",
												},
												{
													source: "fixed",
													dataType: "object",
													value: "store_group",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"section",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "storegroups",
													overwrite: true,
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey: "tableData",
													subjectId:
														"create_strategy_store_group_list",
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey:
														"filteredStoreGroups_original",
													overwrite: true,
													dataType: "object",
												},
											],
										},
										// Setting unsavedchange flag to false
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"unsavedChangeFlag",
													value: false,
												},
											],
										},
									],
								},
							},
							// Setting unsavedchange flag to false
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "unsavedChangeFlag",
										value: false,
									},
								],
							},
						],
					},
				],
				componentProps: {
					content: [
						{
							type: "accordion",
							staticProps: {
								customStyle: {
									padding: "10px",
								},
								label: "MD Strategy Detail",
								expanded: true,
								defaultExpanded: true,
							},
							componentProps: {
								content: [
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											customStyle: {
												display: "flex",
											},
										},
										componentProps: {
											content: [
												{
													id: "step_1_A",
													type: "form",
													pathSrc: "components",
													componentPath:
														"ui/form/Form",
													staticProps: {
														customStyle: {
															width: "auto",
														},
														fields: [
															{
																type: "text",
																id: "strategy_name",
																placeholder:
																	"Enter Strategy Name",
																variant:
																	"outlined",
																isMandatory: true,
																label: "Strategy Name",
																showLabel: true,
															},
															{
																type: "text",
																id: "strategy_comment",
																placeholder:
																	"Enter Comment",
																variant:
																	"outlined",
																label: "Comment",
																showLabel: true,
															},
															{
																type: "datepicker",
																// id: "strategy_dates",
																placeholder:
																	"Select Date Range",
																variant:
																	"outlined",
																isMandatory: true,
																label: "Time Period",
																showLabel: true,
																minDate: moment(
																	new Date()
																).add(
																	1,
																	"days"
																),
																start_date:
																	moment(
																		new Date()
																	).add(
																		1,
																		"days"
																	),
																maxDate: moment(
																	new Date()
																).add(
																	18,
																	"weeks"
																),
																end_date:
																	moment(
																		new Date()
																	).add(
																		6,
																		"weeks"
																	),
																range_limitation:
																	"limited_period",
																max_selection_period:
																	{
																		num: 18,
																		type: "weeks",
																	},
															},
														],
													},
												},
											],
										},
									},
								],
							},
						},
						{
							type: "div",
							staticProps: {
								className: "horizontal-line marginBottom-20",
								customStyle: {
									margin: "15px 15px 0 15px",
								},
							},
						},
						{
							type: "accordion",
							staticProps: {
								customStyle: {
									padding: "10px",
								},
								label: "Configure Time Period",
								expanded: false,
								defaultExpanded: false,
							},
							componentProps: {
								content: [
									{
										id: "create-pcd-component",
										type: "pcd",
										staticProps: {
											showTitle: false,
											showBoxContainer: false,
											showSubmitButton: false,
											activeTab: "weekly",
											pcdTypes: [
												{
													id: "weekly",
													label: "Default (i.e Weekly)",
												},
												{
													id: "full_custom",
													label: "Custom",
												},
												{
													id: "config_object",
													label: "Fetch from Calendar Configuration",
												},
											],
										},
										componentProps: {
											content: [{}],
										},
									},
								],
							},
						},
					],
				},
			},
			{
				type: "div",
				id: "product-store-mapping-section",
				pathSrc: "components",
				componentPath: "ui/wrapperDiv/WrapperDiv",
				staticProps: {
					className: "box-container marginBottom-30",
					customStyle: {
						padding: "20px 30px",
					},
				},
				componentProps: {
					content: [
						{
							id: "product-store-mapping-section-title",
							isDefaultExport: false,
							type: "text",
							pathSrc: "components",
							componentPath: "ui/typography/Typography",
							staticProps: {
								className:
									"product-store-mapping-section-title",
								variant: "paragraph",
								content: "Product Store Mapping Details",
								customStyle: {
									textAlign: "left",
									font: "16px/25px Poppins",
									letterSpacing: "0px",
									color: "#1D1D1D",
								},
							},
						},
						{
							type: "productStoreMappingType",
						},
					],
				},
			},
			{
				type: "div",
				pathSrc: "components",
				componentPath: "ui/wrapperDiv/WrapperDiv",
				staticProps: {
					className: "flex-container",
					customStyle: {
						marginTop: "20px",
					},
				},
				componentProps: {
					content: [
						{
							type: "createStrategyButton",
						},
					],
				},
			},
		],
	},
};
