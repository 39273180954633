import React, { useEffect } from 'react';
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { removeSnack, resetToastMessage } from '../../store/features/global/global';

let displayedSnacks = [];
const SnackQueue = (props) => {
    const storeDisplayed = (id) => {
        displayedSnacks = [...displayedSnacks, id];
    };
    const removeDisplayed = (id) => {
        displayedSnacks = [...displayedSnacks.filter((key) => id !== key)];
    };
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        props.snacks?.forEach(element => {
            if(displayedSnacks.includes(element.key))return;
            enqueueSnackbar(element?.message,{
                variant: element.alertType || "info",
                onExited: () => {
                    props.removeSnack(element.key);
                    removeDisplayed(element.key);
                },
            })
          storeDisplayed(element.key)  
        });
    },[props.snacks])
    return null
}

const mapStateToProps = (store) => {
    return {
        snacks: store.global.snacks,
    }
}
const mapDispatchToProps = (dispatch) => {
	return {
		resetToastMessage: () => dispatch(resetToastMessage()),
        removeSnack: (key) => dispatch(removeSnack(key))
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(SnackQueue);