/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { createSlice, current } from "@reduxjs/toolkit";
import { API, ExcelAPI, FormAPI } from "../../../utils/axios/index";
import { saveAs } from "file-saver";
import {
	setErrorMessage,
	getQueryParameter,
	replaceArrayItemByKey,
	removeDuplicatesFromArray,
	replaceItemByKeys,
} from "../../../utils/helpers/utility_helpers";
import {
	requestStart,
	requestComplete,
	requestFail,
	requestCompleteNoLoader,
	requestFailNoLoader,
} from "../global/global";

const initialState = {
	componentDisplayControls: {},
	tableData: {},
	selected_filters_for_step4: null,
	// step_1_A: {
	// 	strategy_id: 1576,
	// },
};

// Add keys to init dynamically

const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setControls(state, action) {
			const existingControls = _.cloneDeep(
				state.componentDisplayControls
			);
			state.componentDisplayControls = {
				...existingControls,
				...action.payload,
			};
		},
		clearControls(state, action) {
			state.componentDisplayControls = {};
			state.tableData = {};
			state.formData = {};
		},
		setSelectedRows(state, action) {
			const tableSelectedRowsTemp = _.cloneDeep(state.tableData);
			state.tableData = {
				...tableSelectedRowsTemp,
				...action.payload,
			};
		},
		updateFormData(state, action) {
			const { payload } = action.payload;
			const formDataTemp = _.cloneDeep(state.formData);
			Object.keys(payload)?.map((key) => {
				const valueToBeUpdated = formDataTemp[key];
				if (!_.isEmpty(valueToBeUpdated)) {
					state.formData = {
						...formDataTemp,
						[key]: {
							...valueToBeUpdated,
							...payload?.[key],
						},
					};
				} else {
					state.formData = {
						...formDataTemp,
						[key]: {
							...payload?.[key],
						},
					};
				}
			});
		},
		createReducerState(state, action) {
			const { dataKey } = action.payload;
			state[dataKey] = null;
		},
		updateReducerState(state, action) {
			const {
				dataKey,
				payload,
				overwrite,
				uniqueKey,
				updateType,
				updateOnAllKeyCheck,
			} = action.payload;

			if (state[dataKey] && !overwrite) {
				if (
					(typeof state[dataKey] === "object" &&
						Array.isArray(current(state[dataKey]))) ||
					Array.isArray(payload)
				) {
					// Check if uniqueKey exists and state already has an item with the same uniqueKey. If yes, replace
					if (
						uniqueKey &&
						updateType === "unique_key_item_overwrite"
					) {
						if (updateOnAllKeyCheck) {
							state[dataKey] = replaceItemByKeys(
								current(state[dataKey]),
								payload,
								uniqueKey
							);
						} else
							state[dataKey] = replaceArrayItemByKey(
								current(state[dataKey]),
								payload,
								uniqueKey
							);

						// Check for uniqueness here by unique_key
						if (payload && !updateOnAllKeyCheck)
							state[dataKey] = removeDuplicatesFromArray(
								[...state[dataKey], ...payload],
								uniqueKey
							);
					} else {
						// Check for uniqueness here by unique_key
						if (payload && !updateOnAllKeyCheck)
							state[dataKey] = removeDuplicatesFromArray(
								[...state[dataKey], ...payload],
								uniqueKey
							);
					}
				} else if (typeof state[dataKey] === "object") {
					state[dataKey] = {
						...state[dataKey],
						...payload,
					};
				} else {
					state[dataKey] = payload;
				}
			} else if (!state[dataKey] || overwrite) {
				state[dataKey] = payload;
			}
		},
		clearReducerState(state, action) {
			// action.payload contains all the keys to be cleared.
			// If its empty, clear everything
			if (!_.isEmpty(action.payload?.dataKeys)) {
				action.payload?.dataKeys.forEach((dataKey) => {
					state[dataKey] = null;
				});
				/* temperory fix for clearing the reducer of strategy id and current step to null,
				 to clear the data, will move this details to respective tab screens */

				if (action.payload?.genericUnmount === true) {
					state.maxStepCount = null;
				}
			} else if (_.isEmpty(action.payload)) {
				state = {};
			}
		},
		clearAllDataState(state, action) {
			return initialState;
		},
	},
});

export const {
	updateReducerState,
	clearReducerState,
	createReducerState,
	setControls,
	setSelectedRows,
	updateFormData,
	clearControls,
	clearAllDataState,
} = appSlice.actions;

export default appSlice.reducer;

export const invokeReducerAction =
	({ apiEndPoint, apiMethod, payload }) =>
		(dispatch, getState) => {
			dispatch(requestStart());
			// Set formdata in request headers if file type
			let api = API;
			let formattedPayload = payload;
			if (payload.fileData === true) {
				api = FormAPI;
				formattedPayload = formattedPayload.file;
			}

			if (payload.isDownload === true) {
				api = ExcelAPI;
			}

			// Include request parameters in the URL if its a get API
			let endPoint = apiEndPoint;
			if (apiMethod === "get") {
				let parameterString = getQueryParameter(formattedPayload);
				endPoint = `${apiEndPoint}?${parameterString}`; // Format payload for get API here
			}

			return api[apiMethod](`/${endPoint}`, formattedPayload)
				.then((response) => {
					if (response.data && response.data.status === 200) {
						let data = _.cloneDeep(response.data.data);
						// If get call and no data is available
						if (apiMethod === "get" && _.isEmpty(data)) {
							dispatch(
								requestComplete(
									"There is no data for the selected filters. Please try with a different combination."
								)
							);
						} else if (payload.isDownload) {
							const blob = new Blob([response.data], {
								type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
							});
							saveAs(blob, payload.report_name);
							dispatch(requestComplete());
						} else {
							dispatch(requestComplete(response?.data?.message));
						}
						return data;
					} else {
						// const errorMessage = setErrorMessage(response.error);
						dispatch(
							requestFail(
								response?.data?.error || response?.data?.message
							)
						);
						return false;
					}
				})
				.catch((error) => {
					const errorMessage = setErrorMessage(error);
					dispatch(requestFail(errorMessage));
					return false;
				});
		};
