import store from "../../store";
import { toastError } from "../../store/features/global/global";
import _ from "lodash";

export const RulesValidation = () => {
	const rulesValidationFlag = store.getState()?.app.rulesValidationFlag;
	const rulesData =
		store.getState()?.app?.step_3_strategy_data?.strategy_rules;

	const noOfMarkdownsData = _.find(
		rulesData,
		(data) => data.rule_name === "Number of Markdowns"
	);
	const samePPData = _.find(
		rulesData,
		(data) => data.rule_name === "Min Weeks on Same PP"
	);
	const stepSizeData = _.find(
		rulesData,
		(data) => data.rule_name === "Step Size"
	);
	const applicableDicountData = _.find(
		rulesData,
		(data) => data.rule_name === "Applicable Discount"
	);
	const minMaxDiscountData = _.find(
		rulesData,
		(data) => data.rule_name === "Min-Max Discount"
	);
	const firstMDDiscount = _.find(
		rulesData,
		(data) => data.rule_name === "First Markdown Discount"
	);

	const mdMinVal = parseInt(noOfMarkdownsData?.min_value);
	const mdMaxVal = parseInt(noOfMarkdownsData?.max_value);
	const ppMinVal = parseInt(samePPData?.min_value);
	const stepSizeMinVal = parseInt(stepSizeData?.min_value);
	const stepSizeMaxVal = parseInt(stepSizeData?.max_value);
	const minDiscountVal = parseInt(minMaxDiscountData?.min_value);
	const maxDiscountVal = parseInt(minMaxDiscountData?.max_value);
	const minfirstMDDiscountVal = parseInt(firstMDDiscount?.min_value);
	const maxfirstMDDiscountVal = parseInt(firstMDDiscount?.max_value);
	const applicableDicountLength =
		applicableDicountData?.applicable_value.length;
	let arrData = _.sortBy(applicableDicountData.applicable_value);

	if (
		mdMinVal &&
		ppMinVal &&
		!samePPData.is_disabled &&
		!noOfMarkdownsData.is_disabled
	) {
		if (mdMinVal * ppMinVal > samePPData?.max_allowed_weeks) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"The product of the minimum values for the number of markdowns and the minimum weeks on the same price point (PP) exceeds the total number of PCD."
					)
				);
			return false;
		}
	}

	if (!firstMDDiscount.is_disabled) {
		if (
			minfirstMDDiscountVal < minDiscountVal ||
			minfirstMDDiscountVal > maxDiscountVal
		) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"Minimum First Markdown Discount is outside the Global Discount range."
					)
				);
			return false;
		}
		if (
			maxfirstMDDiscountVal < minDiscountVal ||
			maxfirstMDDiscountVal > maxDiscountVal
		) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"Maximum First Markdown Discount is outside the Global Discount range."
					)
				);
			return false;
		}
	}

	if (applicableDicountLength && !applicableDicountData.is_disabled) {
		let minVal = 0;
		let maxVal = arrData[arrData.length - 1];
		if (applicableDicountLength > 1) minVal = arrData[0];
		if (stepSizeMinVal > maxVal - minVal && !stepSizeData.is_disabled) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"The Min Step Size exceeds the difference between the selected Min and Max Applicable Discounts."
					)
				);
			return false;
		}
		if (stepSizeMaxVal > maxVal - minVal && !stepSizeData.is_disabled) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"The Max Step Size exceeds the difference between the selected Min and Max Applicable Discounts."
					)
				);
			return false;
		}

		if (
			!noOfMarkdownsData.is_disabled &&
			(mdMinVal > applicableDicountLength ||
				mdMaxVal > applicableDicountLength)
		) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"Min or Max values for the number of markdowns exceed the total count of Applicable discounts selected."
					)
				);
			return false;
		}

		if (applicableDicountLength === 1) minVal = arrData[arrData.length - 1];
		if (!minMaxDiscountData.is_disabled) {
			if (
				!firstMDDiscount.is_disabled &&
				((minVal < minfirstMDDiscountVal &&
					maxVal < minfirstMDDiscountVal) ||
					(minVal > maxfirstMDDiscountVal &&
						maxVal > maxfirstMDDiscountVal))
			) {
				rulesValidationFlag &&
					store.dispatch(
						toastError(
							"There must be atleast one Applicable discount within the First Markdown discount range"
						)
					);
				return false;
			}
		} else if (minMaxDiscountData.is_disabled) {
			if (
				!firstMDDiscount.is_disabled &&
				(minfirstMDDiscountVal > minVal ||
					maxfirstMDDiscountVal < maxVal)
			) {
				rulesValidationFlag &&
					store.dispatch(
						toastError(
							"The Applicable discounts must fall within the First Markdown discount range"
						)
					);
				return false;
			}
		}

		if (minDiscountVal > minVal && !minMaxDiscountData.is_disabled) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"Min discount exceeds the minimum Applicable Discount."
					)
				);
			return false;
		}

		if (maxDiscountVal < maxVal && !minMaxDiscountData.is_disabled) {
			rulesValidationFlag &&
				store.dispatch(
					toastError(
						"Max discount is lower than the maximum Applicable Discount."
					)
				);
			return false;
		}

		if (arrData.length > 1) {
			if (
				((minVal > maxfirstMDDiscountVal &&
					maxVal > maxfirstMDDiscountVal) ||
					(minVal < minfirstMDDiscountVal &&
						maxVal < minfirstMDDiscountVal)) &&
				!firstMDDiscount.is_disabled
			) {
				rulesValidationFlag &&
					store.dispatch(
						toastError(
							"Applicable discount should contain a discount point in range of First Markdown Discount."
						)
					);
				return false;
			}
			let discountConsecutiveDiff = 0;
			for (let i = 1; i < arrData.length; i++) {
				let difference = arrData[i] - arrData[i - 1];
				if (difference > discountConsecutiveDiff) {
					discountConsecutiveDiff = difference;
				}
			}
			if (
				stepSizeMaxVal < discountConsecutiveDiff &&
				!stepSizeData.is_disabled
			) {
				rulesValidationFlag &&
					store.dispatch(
						toastError(
							"The difference between consecutive Applicable Discount values exceeds the Max Step Size."
						)
					);
				return false;
			}
		}

		if (arrData.length === 1) {
			if (
				(maxVal > maxfirstMDDiscountVal ||
					maxVal < minfirstMDDiscountVal) &&
				!firstMDDiscount.is_disabled
			) {
				rulesValidationFlag &&
					store.dispatch(
						toastError(
							"Applicable discount should contain a discount point in range of First Markdown Discount."
						)
					);
				return false;
			}
		}
	}

	return true;
};
