import { SnackbarProvider, MaterialDesignContent  } from "notistack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useRef } from "react";
import { styled } from "@mui/material/styles";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: "#e8f5ec",
    border: "1px solid #24a148",
    color: "black",
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: "#fce9ea",
    border: "1px solid #da1e28",
    color: "black",
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: "#fff3ea",
    border: "1px solid #ff832b",
    color: "black",
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: "#e5edf7",
    border: "1px solid #0055af",
    color: "black",
  },
}));

const SnackBarComponent = (props) => {
    const notistackRef = useRef();

  return (
    <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent
        }}
        ref={notistackRef}
        action={snackbarKey=> (
          <IconButton onClick={() => notistackRef.current.closeSnackbar(snackbarKey)}>
            <CloseIcon />
          </IconButton>
        )}
        maxSnack={3}
        iconVariant={{
          error: <DangerousOutlinedIcon style={{ marginRight: 5, color: "#da1e28" }} />,
          info: <InfoOutlinedIcon style={{ marginRight: 5, color: "#0055af" }} />,
          warning: <WarningAmberOutlinedIcon style={{ marginRight: 5, color: "#ff832b" }} />,
          success: <CheckCircleOutlineOutlinedIcon style={{ marginRight: 5, color: "#24a148" }} />,
        }}
        anchorOrigin={{horizontal: "right", vertical: "top"}}
    >
      {props.children}
    </SnackbarProvider>
  )
}


export default SnackBarComponent