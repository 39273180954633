/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import _, { map, isEmpty, cloneDeep, find } from "lodash";
import { API, FormAPI } from "../../../utils/axios/index";
import { FILTER_ORDER, STRATEGY_STATUS_FILTER_DEFAULTS } from "../../../constants/Constants";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import {
	resetFilterData as resetFilterDataHelper,
	getUpdatedFilters,
	sortProductHierarchy,
	eventFilterFormatter,
} from "../../../utils/helpers/filterHelper";
import {
	requestStart,
	requestComplete,
	requestFail,
	requestStartNoLoader,
	requestCompleteNoLoader,
	requestFailNoLoader,
	toastError,
} from "../global/global";
import { findUniqueObjectsInArray } from "../../../utils/helpers/utility_helpers";

const initialState = {
	retainFilters: false,
	filtersList: {}, // Moved to global as screenConfig
	filtersLabels: {}, // Moved to global as elementLabels
	filtersData: {},
	activeScreen: null,
	selectedFilters: {},
	lastSavedFilters: {},
	filteredProducts: [],
	uploadedSkuDetails: [],
	uploadError: {},
};

const filterSlice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		setLastSavedFilterValues(state, action) {
			const { payload, from } = cloneDeep(action.payload);
			let newLastSavedFilters = { ...state.lastSavedFilters };
			newLastSavedFilters[from] = !_.isEmpty(newLastSavedFilters[from])
				? {
						...newLastSavedFilters[from],
						...payload,
				  }
				: payload;
			state.lastSavedFilters = newLastSavedFilters;
		},
		resetFiltersData(state, action) {
			let { payload, activeScreen } = cloneDeep(action.payload);
			state.filtersData = payload;
			state.activeScreen = activeScreen;
		},
		resetFilterOptions(state, action) {
			let newFiltersData = resetFilterDataHelper(state.filtersData);
			state.filtersData = newFiltersData;
			state.activeScreen = action.activeScreen;
		},
		setRetainFilterOptions(state, action) {
			state.retainFilters = action.payload;
		},
		setSelectedFilterOptions(state, action) {
			state.selectedFilters = action.payload;
		},
		setFilteredData(state, action) {
			const {
				filterName,
				dataType,
				payload,
				from,
				activeScreen,
				updateLastSaved,
			} = cloneDeep(action.payload);
			let filtersData = payload;
			if (filterName) {
				let newFiltersData = { ...state.filtersData };

				if (!isEmpty(newFiltersData[filterName])) {
					//case filter name already exists in reducer

					if (dataType === "options") {
						newFiltersData[filterName][dataType] = payload;
					}
					if (
						dataType === "options" &&
						newFiltersData[filterName].callApi
					) {
						newFiltersData[filterName].callApi = false;
					}

					if (dataType === "selectedItems") {
						let resetValues = false;

						map(Object.keys(newFiltersData), (o) => {
							if (
								resetValues &&
								!o?.includes("dateRange") &&
								!o?.includes("channelOptions") &&
								!o?.includes("adTypeOptions") &&
								FILTER_ORDER[filterName]?.includes(o)
							) {
								newFiltersData[o] = {
									options: [],
									selectedItems: [],
									selectedItemsArray: [],
								};
							}
							if (o === filterName && o?.includes("dateRange")) {
								newFiltersData[o] = payload;
								resetValues = true;
							}
							if (o === filterName && !o?.includes("dateRange")) {
								newFiltersData[o][dataType] = payload;
								newFiltersData[o]["selectedItemsArray"] = map(
									payload,
									(e) => e.value
								);
								resetValues = true;
							}
						});
					}
				} else if (
					isEmpty(newFiltersData[filterName]) &&
					dataType === "selectedItems" &&
					(filterName === "channelOptions" ||
						filterName === "adTypeOptions" ||
						filterName.includes("store") ||
						filterName.includes("product"))
				) {
					newFiltersData[filterName] = {};
					newFiltersData[filterName][dataType] = payload;
					newFiltersData[filterName]["selectedItemsArray"] = map(
						payload,
						(e) => e.value
					);
				} else {
					//case filter name doesn't exists in reducer, then add it to the reducer
					//this case will only exist for options type
					 
					if(
						filterName.includes(
						"dateRange"
					))
							newFiltersData[filterName] = payload;

					else if (filterName === "strategy_status")
						newFiltersData[filterName] =
						 {
								options: payload,
								selectedItems: payload.filter((p) =>
									STRATEGY_STATUS_FILTER_DEFAULTS.includes(
										p.value
									)
								),
								selectedItemsArray: map(
									payload.filter((p) =>
										STRATEGY_STATUS_FILTER_DEFAULTS.includes(
											p.value
										)
									),
									(e) => e.value
								),
						  }
					 else 
					 	newFiltersData[filterName] = {
								options: payload,
								selectedItems: [],
								selectedItemsArray: [],
						  };
				}
				filtersData = newFiltersData;
			}
			if (!filterName && !isEmpty(state.filtersData)) {
				let newFiltersData = cloneDeep(state.filtersData);
				map(Object.keys(newFiltersData), (o) => {
					filtersData[o] = newFiltersData[o];
				});
			}
			if (updateLastSaved && filterName && dataType === "selectedItems") {
				let newLastSaved = cloneDeep(state.lastSavedFilters);
				if (
					newLastSaved[from] &&
					newLastSaved[from].filtersData &&
					newLastSaved[from].filtersData[filterName]
				) {
					newLastSaved[from].filtersData[filterName].options =
						payload;
					newLastSaved[from].filtersData[filterName].selectedItems =
						payload;

					state.lastSavedFilters = newLastSaved;
				}
			}
			state.activeScreen = activeScreen;
			state.filtersData = filtersData;
		},
		setFilteredProducts(state, action) {
			state.filteredProducts = action.payload;
		},
		setUploadedSkuDetails(state, action) {
			const uploadedSkuDetailsTemp = _.cloneDeep(
				state.uploadedSkuDetails
			);
			// Find unique
			const uniqueSkuDetails = findUniqueObjectsInArray(
				uploadedSkuDetailsTemp,
				action.payload,
				"product_h5_id"
			);
			state.uploadedSkuDetails = uniqueSkuDetails;
		},
		setUploadError(state, action) {
			state.uploadError = action.payload;
		},
	},
});

export const {
	setFilteredData,
	setLastSavedFilterValues,
	resetFiltersData,
	resetFilterOptions,
	setRetainFilterOptions,
	setSelectedFilterOptions,
	setFilteredProducts,
	setUploadedSkuDetails,
	setUploadError,
} = filterSlice.actions;

export default filterSlice.reducer;

export const getFilterOptions =
	({
		requestObject,
		filterName,
		from,
		selectedItems,
		filterEndpoint,
		updateLastSaved,
		filterfromExisting,
	}) =>
	async (dispatch, getState) => {
		dispatch(requestStartNoLoader());
		await API.post(`/${filterEndpoint}`, requestObject)
			.then((response) => {
				if (response.data && response.data.status === 200) {
					let data = _.cloneDeep(response.data.data);

					// Edit Promo Flow - Limit hierarchy options to event selection
					if (!_.isEmpty(filterfromExisting)) {
						const currentEvent = filterfromExisting?.[0];
						const { product_hierarchy } = currentEvent;
						const existingFilterOptions =
							product_hierarchy[filterName];
						if (existingFilterOptions) {
							data = data.filter((d) =>
								existingFilterOptions
									.map((o) => o.value)
									.includes(d.value)
							);
						}
					}
					const filtersDataPayload = {
						payload: data || [],
						filterName,
						dataType: "options",
						activeScreen: from,
					};
					dispatch(setFilteredData(filtersDataPayload));
					if (selectedItems === "All") {
						const filtersDataPayload = {
							data,
							filterName,
							from,
							activeScreen: from,
						};
						dispatch(setSelectedFilterData(filtersDataPayload));
					} else if (!isEmpty(selectedItems)) {
						//set selected items if data is present
						//case: autofill filters
						const findOption = find(data, {
							value: selectedItems[0] && selectedItems[0].value,
						});
						if (!_.isEmpty(findOption)) {
							const setDefaultSeletedItem = {
								payload: [findOption],
								filterName,
								dataType: "selectedItems",
								from: from,
								activeScreen: from,
								updateLastSaved,
							};
							dispatch(setFilteredData(setDefaultSeletedItem));
						}
					}
					dispatch(requestCompleteNoLoader());
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFailNoLoader(errorMessage));
			});
	};

export const getFilterOptionsForSimulator =
	(payload) => (dispatch, getState) => {
		dispatch(requestStart());
		return API.post(`/filters`, payload)
			.then((response) => {
				if (response.data && response.data.status === true) {
					let data = _.cloneDeep(response.data.data);
					dispatch(requestComplete());
					return data;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
			});
	};

export const setSelectedFilterData =
	({ data, filterName, from, activeScreen }) =>
	(dispatch, getState) => {
		const filtersDataPayload = {
			payload: data,
			filterName,
			dataType: "selectedItems",
			from,
			activeScreen: from,
		};
		dispatch(setFilteredData(filtersDataPayload));
	};

export const setLastSavedFilters =
	({ data, from }) =>
	(dispatch, getState) => {
		// dispatch(requestStart());
		const lastSavedFiltersPayload = {
			payload: cloneDeep(data),
			from,
		};
		dispatch(setLastSavedFilterValues(lastSavedFiltersPayload));
		// dispatch(requestComplete());
	};

export const setFiltersData =
	({ data, from }) =>
	(dispatch, getState) => {
		const resetPayload = {
			payload: data,
			activeScreen: from,
		};
		dispatch(resetFiltersData(resetPayload));
	};

export const resetFilters = (from) => (dispatch, getState) => {
	const resetPayload = {
		activeScreen: from,
	};
	dispatch(resetFilterOptions(resetPayload));
};

export const setSelectedFilters = (selectedFilters) => (dispatch, getState) => {
	dispatch(setSelectedFilterOptions(selectedFilters));
};

export const setRetainFilters = (data) => (dispatch, getState) => {
	dispatch(setRetainFilterOptions(data));
};

/* Decision Dashboard - Last Selected Filters */
export const setLastSelectedFiltersForUser =
	(requestObject) => (dispatch, getState) => {
		dispatch(requestStart());
		return API.post("/cache/filters/", requestObject)
			.then((response) => {
				if (response.data && response.data.status === true) {
					dispatch(requestComplete());
					return true;
				} else {
					dispatch(requestFail());
					return true;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return true;
			});
	};

export const getLastSelectedFiltersForUser =
	({ userId, screenName, filtersOptionsArr }) =>
	(dispatch, getState) => {
		dispatch(requestStart());
		return API.get("/cache/filters")
			.then((response) => {
				if (response.data && response.data.status === true) {
					const { data } = response.data;
					const filtersRequestObj = data.filters;
					if (!_.isEmpty(filtersRequestObj)) {
						const filtersDataObj = {
							product_filters: filtersRequestObj.product,
							store_filters: filtersRequestObj.store,
							item_group: filtersRequestObj.item_group,
							reg_clr: filtersRequestObj,
						};
						const filtersData = getUpdatedFilters({
							data: filtersOptionsArr,
							filtersList: filtersDataObj,
							callApi: true,
						});
						const filterOptions = {
							filters: {
								filters: filtersDataObj,
							},
							filtersData: filtersData,
						};

						dispatch(
							setFiltersData({
								data:
									(filterOptions &&
										filterOptions.filtersData) ||
									{},
								from: screenName,
							})
						);
						dispatch(
							setLastSavedFilters({
								data: filterOptions,
								from: screenName,
							})
						);
						dispatch(requestComplete());
						return filtersDataObj;
					}
					dispatch(requestComplete());
					return true;
				} else if (response.data.status === false) {
					const { message } = response.data;
					const errorMessage = setErrorMessage(message);
					dispatch(requestFail(errorMessage));
					return false;
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return true;
			});
	};

export const getProductsForSelectedFilters =
	(requestObject) => (dispatch, getState) => {
		dispatch(requestStart());
		return API.post("/products", requestObject)
			.then((response) => {
				if (response.data && response.status === 200) {
					const { data } = response.data;
					dispatch(setUploadedSkuDetails(data));
					dispatch(requestComplete());
					return data;
				} else {
					dispatch(requestFail());
				}
			})
			.catch((error) => {
				const errorMessage = setErrorMessage(error);
				dispatch(requestFail(errorMessage));
				return true;
			});
	};

export const getDetailsForSkuFromExcel = (file) => (dispatch, getState) => {
	dispatch(requestStart());
	const formData = new FormData();
	formData.append("file", file);

	return FormAPI.post("/get-products-from-file", formData)
		.then((response) => {
			if (response.data && response.status === 200) {
				const { data, message } = response.data;
				const sortedSkuDetails = sortProductHierarchy(data);
				const inactiveSkus = sortedSkuDetails?.product_h5
					?.filter((sku) => sku.is_active === 0)
					?.map((s) => s.product_h5_id);

				dispatch(setUploadedSkuDetails(sortedSkuDetails?.product_h5));
				dispatch(
					setUploadError({
						invalid: data.invalid,
						out_of_event: data.out_of_event,
						inactive: inactiveSkus,
					})
				);
				dispatch(requestComplete());
			} else {
				dispatch(requestFail());
				return false;
			}
		})
		.catch((error) => {
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return false;
		});
};

export const getDetailsForSku = (requestObject) => (dispatch, getState) => {
	dispatch(requestStart());
	return API.post("/sku-details", requestObject)
		.then((response) => {
			if (response.data && response.status === 200) {
				const { data, message } = response.data;
				const sortedSkuDetails = sortProductHierarchy(data);
				const inactiveSkus = sortedSkuDetails?.product_h5
					?.filter((sku) => sku.is_active === 0)
					?.map((s) => s.product_h5_id);

				dispatch(setUploadedSkuDetails(sortedSkuDetails?.product_h5));
				dispatch(
					setUploadError({
						invalid: data.invalid,
						out_of_event: data.out_of_event,
						inactive: inactiveSkus,
					})
				);
				dispatch(requestComplete());
			} else {
				dispatch(requestFail());
			}
		})
		.catch((error) => {
			const errorMessage = setErrorMessage(error);
			dispatch(requestFail(errorMessage));
			return true;
		});
};
