import axios from "axios";
import moment, { updateLocale } from "moment";
import _ from "lodash";
import { firebaseobj } from "../../components/auth/components/FirebaseInitialize";

const { REACT_APP_API_BASE_URL, REACT_APP_PROMO_URL } = process.env;

const API = axios.create({
	baseURL: REACT_APP_API_BASE_URL,
	timeout: 9999999,
	headers: {
		"Content-Type": "application/json",
	},
});

API.interceptors.request.use(
	(request) => {
		//Reading cookies cookies
		function getCookie(cookieName) {
			const name = cookieName + "=";
			const decodedCookie = decodeURIComponent(document.cookie);
			const cookieArray = decodedCookie.split(";");
			for (let i = 0; i < cookieArray.length; i++) {
				let cookie = cookieArray[i].trim();
				if (cookie.indexOf(name) === 0) {
					return cookie.substring(name.length, cookie.length);
				}
			}
			return null; // Return null if the cookie is not found
		}
		let cookieValue = getCookie("pricesmart-ia-token");
		let token = localStorage.getItem("token");
		if (_.isEmpty(token)) {
			token = cookieValue;
			localStorage.setItem("token", token);
		}
		request.headers.common = {
			Authorization: `Bearer ${token}`,
			AppVersion: process.env.REACT_APP_VERSION,
		};

		// Check if SAML token is valid. If expiry time is less than 5 mins, generate new token
		firebaseobj
			?.auth()
			.currentUser?.getIdTokenResult()
			.then((authResult) => {
				const expirationTime = authResult.expirationTime;
				const fiveMinsFromNow = moment().add(5, "minutes");
				if (
					moment.utc(expirationTime).local().isBefore(fiveMinsFromNow)
				) {
					console.log("Token about to expire. Generating new");
					// Generate new token and save
					firebaseobj
						.auth()
						.currentUser.getIdToken(true)
						.then((accessToken) => {
							localStorage.setItem("token", accessToken);
						})
						.catch(() => {
							console.log("Unable to generate refresh token");
						});
				}
			});

		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

API.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response } = error;
		let pathname = window.location && window.location.pathname;
		if (
			response &&
			(response.status === 403 || response.status === 401) &&
			pathname !== "/login"
		) {
			localStorage.clear();
			// Check if SSO user. Logout of session
			if (firebaseobj?.auth().currentUser) {
				firebaseobj.auth().signOut();
			}
			// window.location.href = REACT_APP_PROMO_URL;
			window.location.href = "/";
			// OR Do whatever is required to logout
		} else if (response && response.status === 503) {
			localStorage.clear();
			// Check if SSO user. Logout of session
			if (firebaseobj?.auth().currentUser) {
				firebaseobj.auth().signOut();
			}
			window.location.href = "/under-maintenance";
		}
		return Promise.reject(error);
	}
);

const ExcelAPI = axios.create({
	baseURL: REACT_APP_API_BASE_URL,
	timeout: 9999999,
	headers: {
		"Content-Type": "application/json",
	},
	responseType: "arraybuffer",
});

ExcelAPI.interceptors.request.use(
	(request) => {
		let token = localStorage.getItem("token");
		request.headers.common = {
			Authorization: `Bearer ${token}`,
			AppVersion: process.env.REACT_APP_VERSION,
		};

		// Check if SAML token is valid. If expiry time is less than 5 mins, generate new token
		firebaseobj
			?.auth()
			.currentUser?.getIdTokenResult()
			.then((authResult) => {
				const expirationTime = authResult.expirationTime;
				const fiveMinsFromNow = moment().add(5, "minutes");
				if (
					moment.utc(expirationTime).local().isBefore(fiveMinsFromNow)
				) {
					console.log("Token about to expire. Generating new");
					// Generate new token and save
					firebaseobj
						.auth()
						.currentUser.getIdToken(true)
						.then((accessToken) => {
							localStorage.setItem("token", accessToken);
						})
						.catch(() => {
							console.log("Unable to generate refresh token");
						});
				}
			});

		// Do something before request is sent
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

ExcelAPI.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response } = error;
		let pathname = window.location && window.location.pathname;
		if (
			response &&
			(response.status === 403 || response.status === 401) &&
			pathname !== "/login"
		) {
			localStorage.clear();
			// Check if SSO user. Logout of session
			if (firebaseobj?.auth().currentUser) {
				firebaseobj.auth().signOut();
			}
			// window.location.href = REACT_APP_PROMO_URL;
			window.location.href = "/";
			// OR Do whatever is required to logout
		}
		return Promise.reject(error);
	}
);

const FormAPI = axios.create({
	baseURL: REACT_APP_API_BASE_URL,
	timeout: 9999999,
	headers: {
		"Content-Type": "multipart/form-data",
	},
});

FormAPI.interceptors.request.use(
	(request) => {
		let token = localStorage.getItem("token");
		request.headers.common = {
			Authorization: `Bearer ${token}`,
			AppVersion: process.env.REACT_APP_VERSION,
		};

		// Check if SAML token is valid. If expiry time is less than 5 mins, generate new token
		firebaseobj
			?.auth()
			.currentUser?.getIdTokenResult()
			.then((authResult) => {
				const expirationTime = authResult.expirationTime;
				const fiveMinsFromNow = moment().add(5, "minutes");
				if (
					moment.utc(expirationTime).local().isBefore(fiveMinsFromNow)
				) {
					console.log("Token about to expire. Generating new");
					// Generate new token and save
					firebaseobj
						.auth()
						.currentUser.getIdToken(true)
						.then((accessToken) => {
							localStorage.setItem("token", accessToken);
						})
						.catch(() => {
							console.log("Unable to generate refresh token");
						});
				}
			});

		// Do something before request is sent
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);

FormAPI.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const { response } = error;
		let pathname = window.location && window.location.pathname;
		if (
			response &&
			(response.status === 403 || response.status === 401) &&
			pathname !== "/login"
		) {
			localStorage.clear();
			// Check if SSO user. Logout of session
			if (firebaseobj?.auth().currentUser) {
				firebaseobj.auth().signOut();
			}
			// window.location.href = REACT_APP_PROMO_URL;
			window.location.href = "/";
			// OR Do whatever is required to logout
		}
		return Promise.reject(error);
	}
);

export { API, ExcelAPI, FormAPI };
