import moment from "moment";

export const calendarConfig = {
	fstDayOfWk: 1, // Monday
	fstMoOfYr: 0, // Jan
};

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_START_DATE_MOMENT = moment().add(0, "days");
export const DEFAULT_END_DATE_MOMENT = moment().add(120, "days");
export const DEFAULT_START_DATE = moment()
	.add(0, "days")
	.format(DEFAULT_DATE_FORMAT);
export const DEFAULT_END_DATE = moment()
	.add(120, "days")
	.format(DEFAULT_DATE_FORMAT);
export const DEFAULT_START_DATE_MOMENT_REPORTING = moment().subtract(
	31,
	"days"
);
export const DEFAULT_END_DATE_MOMENT_REPORTING = moment().subtract(1, "days");
export const DEFAULT_START_DATE_MOMENT_DECISION_DASHBOARD = moment().subtract(
	14,
	"days"
);

export const NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER = 1;

export const singleSelectStyles = {
	control: (provided, state) => ({
		...provided,
		height: "32px",
		minHeight: "32px",
		fontSize: "0.8rem",
		border: "1px solid #cccccc",
		top: "2px",
		cursor: "pointer",
		boxShadow: "none",
		"&:hover": {
			border: "1px solid #cccccc",
		},
	}),
	valueContainer: (provided, state) => ({
		...provided,
		height: "32px",
		padding: "0 6px",
	}),
	input: (provided, state) => ({
		...provided,
		margin: "0px",
		color: "transparent",
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		height: "32px",
		// minHeight: "32px",
		padding: "6px",
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: "none",
	}),
	indicatorContainer: (provided, state) => ({
		...provided,
		padding: "0px 8px",
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: "2px 4px 2px",
		fontSize: "0.8rem",
	}),
	menuList: (provided, state) => ({
		...provided,
		fontSize: "0.8rem",
		padding: 0,
	}),
};

export const FILTER_ORDER = {
	product_h1: [
		"product_h2",
		"product_h3",
		"product_h4",
		"brand",
		"strategy_id",
		"calendar_config_ids",
	],
	product_h2: [
		"product_h3",
		"product_h4",
		"brand",
		"strategy_id",
		"calendar_config_ids",
	],
	product_h3: ["product_h4", "brand", "strategy_id", "calendar_config_ids"],
	product_h4: [
		"product_h2",
		"product_h3",
		"product_h4",
		"brand",
		"strategy_id",
		"calendar_config_ids",
	],
	brand: ["strategy_id", "calendar_config_ids"],
	store_h1: [
		"store_h2",
		"store_h3",
		"store_h4",
		"store_h5",
		"store_h6",
		"strategy_id",
		"calendar_config_ids",
	],
	store_h2: [
		"store_h3",
		"store_h4",
		"store_h5",
		"store_h6",
		"strategy_id",
		"calendar_config_ids",
	],
	store_h3: [
		"store_h4",
		"store_h5",
		"store_h6",
		"strategy_id",
		"calendar_config_ids",
	],
	store_h4: ["store_h5", "store_h6", "strategy_id", "calendar_config_ids"],
	store_h5: ["store_h6", "strategy_id", "calendar_config_ids"],
	store_h6: ["strategy_id", "calendar_config_ids"],
	dateRange: ["strategy_id", "calendar_config_ids"],
	rule_type: ["rule_flexibility_type_id", "rule_id"],
	rule_flexibility_type_id: ["rule_id"],
	strategy_status: ["strategy_id"],
};

export const STRATEGY_STATUS_FILTER_DEFAULTS = [0, 5, 1, 2, 3, 4];

export const STRATEGY_STATUS = [
	{
		label: "Draft/Copied",
		value: 0,
	},
	{
		label: "Pending Review",
		value: 1,
	},
	{
		label: "Finalized",
		value: 2,
	},
	{
		label: "Archived",
		value: 6,
	},
];

export const VALID_OFFER_TYPES = {
	percent_off: "% Off",
	extra_amount_off: "$ Off",
	fixed_price: "PP",
	bxgy: "BXGY",
	bundle_offer: "Bundle Offer",
	reg_price: "Reg Price",
	reg_price_cv: "Reg Price CV",
	bxgy_$: "Bundle Offer Buy",
	bmsm: "BMSM",
};

export const DISCOUNT_LEVELS = {
	overall: -200,
	product_h1: 1,
	product_h2: 2,
	product_h3: 3,
	product_h4: 4,
	product_h5: 5,
};

export const PRODUCT_HIERARCHY = [
	{
		label: "Overall",
		value: DISCOUNT_LEVELS.overall,
		hierarchy: -200,
	},
	{
		label: "Division",
		value: DISCOUNT_LEVELS["product_h1"],
		hierarchy: "product_h1",
	},
	{
		label: "Department",
		value: DISCOUNT_LEVELS["product_h2"],
		hierarchy: "product_h2",
	},
	{
		label: "Class",
		value: DISCOUNT_LEVELS["product_h3"],
		hierarchy: "product_h3",
	},
	{
		label: "Sub Class",
		value: DISCOUNT_LEVELS["product_h4"],
		hierarchy: "product_h4",
	},
	{
		label: "SKU",
		value: DISCOUNT_LEVELS["product_h5"],
		hierarchy: "product_h5",
	},
];

export const MODEL_API = {
	WORKBENCH_AGGREGATE_METRICS: {
		MODEL_ID: 1,
		COLUMNS: [
			// "target_gross_margin",
			"finalized_gross_margin",
			"finalized_baseline_gross_margin",
			"finalized_incremental_gross_margin",
			"projected_gross_margin",
			// "target_revenue",
			"finalized_revenue",
			"finalized_baseline_revenue",
			"finalized_incremental_revenue",
			"projected_revenue",
			// "target_sales_units",
			"finalized_sales_units",
			"finalized_baseline_sales_units",
			"finalized_incremental_sales_units",
			"projected_sales_units",
		],
	},
	METRICS_EVENT_TIME: {
		MODEL_ID: 4,
	},
	METRICS_PROMO_TIME: {
		MODEL_ID: 3,
	},
	METRICS_BULK_PROMO_EDIT: {
		MODEL_ID: 5,
	},
	METRICS_EVENT_PRODUCTS: {
		MODEL_ID: 8,
	},
	METRICS_PROMO_PRODUCTS: {
		MODEL_ID: 7,
	},
	METRICS_EVENTS_PROMO_LIST: {
		MODEL_ID: 9,
	},
	METRICS_EVENTS_FILTERS_LIST: {
		MODEL_ID: 10,
	},
	METRICS_REPORTING: {
		MODEL_ID: 12,
	},
	METRICS_REPORTING_TOP_BOTTOM_OFFERS: {
		MODEL_ID: 11,
	},
	METRICS_REPORTING_WATERFALL: {
		MODEL_ID: 13,
	},
	METRICS_REPORTING_EVENT_FILTER: {
		MODEL_ID: 14,
	},
	METRICS_TOP_BOTTOM_OFFERS_DOWNLOAD: {
		MODEL_ID: 15,
	},
	GLOBAL_CONFIG_OPTIONS: {
		MODEL_ID: 16,
		parameters: {
			// event_ad_type: true,
			// offer_types: true,
			// event_channel: true,
		},
	},
	SAP_DOWNLOAD: {
		DOWNLOAD_1: 17,
		DOWNLOAD_2: 20,
		DOWNLOAD_3: 22,
		DOWNLOAD_4: 25,
	},
};

export const ACTION_MAPPING = {
	1: "create_promo",
	2: "edit_promo",
	3: "delete_promo",
	4: "download",
	5: "upload",
	6: "view_promo",
	7: "approve_promo",
	8: "finalize_promo",
	9: "create_event",
	10: "edit_event",
	11: "delete_event",
	12: "lock_event",
	13: "view_event",
	14: "download_marketing_report",
	15: "download_signage_report",
	16: "download_sap_report",
};

// export const ROLE_ACTION_MAPPING = {
// 	1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
// 	2: [4, 6, 9, 10, 11, 12, 13],
// 	3: [1, 2, 3, 4, 5, 6, 7, 8, 13],
// 	4: [4, 6, 13],
// };

export const SUPPORT = "/support";

export const CONFLICT_TYPES = {
	0: "IN FINALIZED",
	1: "IN REQUEST",
};

export const STORE_HIERARCHY_MAPPING = {
	store_h0: "Country",
	store_h1: "DC",
	store_h2: "Region",
	store_h3: "State",
	store_h4: "District",
	store_h5: "City",
	store_h6: "Location",
};

export const overriddenValueColumns = [
	"markdown_finalized",
	"sales_units_ia_finalized",
	"sales_units_baseline",
	"sales_units_ia_incremental",
	"sales_dollars_ia_finalized",
	"sales_dollars_baseline",
	"sales_dollars_ia_incremental",
	"gross_margin_ia_finalized",
	"gross_margin_baseline",
	"gross_margin_ia_incremental",
	"margin_ia_recommended",
	"margin_scenario_1",
	"margin_scenario_2",
	"sales_ia_recommended",
	"sales_scenario_1",
	"sales_scenario_2",
	"sales_units_ia_recommended",
	"sales_units_scenario_1",
	"sales_units_scenario_2",
	"incremental_margin_ia_recommended",
	"incremental_margin_scenario_1",
	"incremental_margin_scenario_2",
	"incremental_sales_ia_recommended",
	"incremental_sales_scenario_1",
	"incremental_sales_scenario_2",
	"incremental_sales_units_ia_recommended",
	"incremental_sales_units_scenario_1",
	"incremental_sales_units_scenario_2",
	"markdown_budget",
	"units_incremental_ia",
	"revenue_incremental_finalized",
	"revenue_incremental_ia",
	"gross_margin_incremental_finalized",
	"gross_margin_incremental_ia",
	"units_finalized",
	"sales_units_baseline",
	"units_incremental_finalized",
	"revenue_finalized",
	"revenue_baseline",
	"revenue_incremental_finalized",
	"gross_margin_finalized",
	"gross_margin_baseline",
	"gross_margin_incremental_finalized",
	"units_baseline",
];

export const rule_flexibility_type_id = {
	Flexible: 0,
	Inflexible: 1,
};

export const Simulation_mapping = {
  ia_recc: {
    label: "IA Recommended",
    value: "ia_recc",
    finalize: true,
    is_edit: false,
    dataKeys: [
      {
        Units: "ia_recc_sales_units",
        "Revenue $": "ia_recc_revenue",
        "Margin $": "ia_recc_gm_dollar",
      },
      {
        "Margin %": "ia_recc_gm_percent",
        "AUR $": "ia_recc_aur",
        "AUM $": "ia_recc_aum",
      },
      { "ST %": "ia_recc_st_percent",
        "Markdown $": "ia_recc_markdown_dollar",
      },
    ],
  },
  draft_scenario: {
    label: "Scenario In Draft",
    value: "draft_override",
    finalize: true,
    dataKeys: [
      {
        Units: "draft_override_sales_units",
        "Revenue $": "draft_override_revenue",
        "Margin $": "draft_override_gm_dollar",
      },
      {
        "Margin %": "draft_override_gm_percent",
        "AUR $": "draft_override_aur",
        "AUM $": "draft_override_aum",
      },
      { "ST %": "draft_override_st_percent" ,
        "Markdown $": "draft_override_markdown_dollar",
      },
    ],
  },
  bl_override: {
    label: "BL Override",
    value: "bl_override",
    finalize: false,
    dataKeys: [
      {
        Units: "bl_override_sales_units",
        "Revenue $": "bl_override_revenue",
        "Margin $": "bl_override_gm_dollar",
      },
      {
        "Margin %": "bl_override_gm_percent",
        "AUR $": "bl_override_aur",
        "AUM $": "bl_override_aum",
      },
      { "ST %": "bl_override_st_percent" ,
        "Markdown $": "bl_override_markdown_dollar",
      },
    ],
  },
  last_week: {
    label: "Last Week",
    finalize: false,
    dataKeys: [
      {
        Units: "lw_sales_units",
        "Revenue $": "lw_revenue",
        "Margin $": "lw_gm_dollar",
      },
      {
        "Margin %": "lw_gm_percent",
        "AUR $": "lw_aur",
        "AUM $": "lw_aum",
      },
      { "ST %": "lw_st_percent",
        "Markdown $": "lw_markdown_dollar",
      },
    ],
  },
  historical: {
    label: "Historical",
    finalize: false,
    dataKeys: [
      {
        Units: "till_date_sales_units",
        "Revenue $": "till_date_revenue",
        "Margin $": "till_date_gm_dollar",
      },
      {
        "Margin %": "till_date_gm_percent",
        "AUR $": "till_date_aur",
        "AUM $": "till_date_aum",
      },
      { "ST %": "till_date_st_percent",
        "Markdown $": "till_date_markdown_dollar",
      },
    ],
  },
};

export const simulation_data_formatter_mapping = {
  Units: "toUnit",
  "Revenue $": "toDollar",
  "Margin $": "toDollar",
  "Margin %": "toPercentage",
  "AUR $": "toDollarWithDecimal",
  "AUM $": "toDollarWithDecimal",
  "ST %": "toPercentage",
  "Markdown $": "toDollar",
};

export const columnsForIaTable = {
	bl_override: {
		label: "BL Override",
		id: "bl_override",
	},
	ia_reco: {
		label: "IA Recom",
		id: "ia_reco",
	},
};

export const lockColumnsKey = ["product_level_value"];

export const cellsWithLockAndInput = ["bl_override", "ia_reco"];

export const optimizationType = {
	ia_optimization: 0,
	partial_optimization: 1,
	no_optimization: 2,
};

export const disbleColumnMenu = ["price_point"];
