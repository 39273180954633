import { simulation_data_formatter_mapping } from "../../Constants";

export const Simulated_data = () => [
	{
		type: "div",
		pathSrc: "components",
		componentPath: "ui/wrapperDiv/WrapperDiv",
		staticProps: {
			customStyle: {
				minWidth: "425px",
				marginRight: "15px",
				padding: "10px 15px",
			},
			className: "box-container",
		},
		componentProps: {
			content: [
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "flex-container",
						customStyle: {
							justifyContent: "space-between",
							marginBottom: "10px",
							minHeight: "40px",
						},
					},
					componentProps: {
						content: [
							{
								type: "text",
								pathSrc: "components",
								componentPath: "ui/typography/Typography",
								staticProps: {
									className: "common-page-title",
									variant: "h4",
									content: "IA Recommended",
								},
							},
							{
								type: "button",
								pathSrc: "components",
								componentPath: "ui/button/Button",
								staticProps: {
									variant: "primary",
									label: "Finalize",
									display: {
										type: "controlled",
										default: {
											show: true,
											enabled: true,
										},
									},
								},
								dataProps: {
									is_visible: {
										type: "derived",
										dataKey: "step_4_flags_options",
										subjectId: "enable_ia_finalise",
									},
								},
								functionProps: [
									{
										functionName: "onClick",
										actions: [
											{
												type: "reducer_function",
												responseFormatter: [
													{
														destination: "reducer",
														dataKey: "unsavedChangeFlag",
														value: false
													}
												]
											},	
											{
												type: "reducer_function",
												confirmation: {
													type: "check",
													conditions: [
														{
															comparison:
																"equals",
															source: "reducer",
															sourceId:
																"step_4_flags_options",
															subjectId:
																"optimisation_type",
															checkValue: 0,
														},
													],
													conditionOperator: "or",
												},
												responseFormatter: [
													{
														destination: "reducer",
														dataKey:
															"show_IA_recommendation_finalize_alert",
														value: true,
														overwrite: true,
													},
													{
														destination: "reducer",
														dataKey:
															"IA_recommendation_finalize_source",
														value: "ia_recc",
														overwrite: true,
													},
													{
														destination: "reducer",
														dataKey:
															"IA_recommendation_finalize_alert_message",
														value: "BL Recommendations will be populated by IA recommendation.",
														overwrite: true,
													},
												],
											},
											{
												type: "api_function",
												apiEndPoint: "model",
												apiMethod: "post",
												confirmation: {
													type: "check",
													conditions: [
														{
															comparison:
																"equals",
															source: "reducer",
															sourceId:
																"step_4_flags_options",
															subjectId:
																"optimisation_type",
															checkValue: 1,
														},
														{
															comparison:
																"equals",
															source: "reducer",
															sourceId:
																"step_4_flags_options",
															subjectId:
																"optimisation_type",
															checkValue: 2,
														},
													],
													conditionOperator: "or",
												},
												params: [
													{
														source: "fixed",
														apiRequestKey: "id",
														value: 3071,
													},
													{
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"strategy_id",
														apiRequestKeyNested:
															"strategy_id",
														apiRequestKey:
															"parameters",
													},
													{
														source: "fixed",
														apiRequestKey:
															"parameters",
														apiRequestKeyNested:
															"source",
														value: "ia_recc",
													},
												],
												responseFormatter: [
													{
														destination: "reducer",
														apiResponseKey:
															"conflict_records",
														dataKey:
															"finalize_strategy_conflict_table",
														overwrite: true,
														dataType: "array",
													},
												],
												onComplete: {
													actions: [
														{
															type: "validation",
															conditions: [
																{
																	comparison:
																		"exists",
																	source: "response",
																},
															],
															conditionOperator:
																"or",
															onValidationSuccess:
																{
																	actions: [
																		{
																			type: "reducer_function",
																			responseFormatter:
																				[
																					// {
																					// 	destination:
																					// 		"reducer",
																					// 	dataKey:
																					// 		"step_4_flags_options",
																					// 	subjectId:
																					// 		"enable_draft_finalise",
																					// 	dataType:
																					// 		"basic",
																					// 	value: false,
																					// 	overwrite: false,
																					// },
																					{
																						destination:
																							"reducer",
																						dataKey:
																							"step_4_flags_options",
																						subjectId:
																							"enable_ia_finalise",
																						dataType:
																							"basic",
																						value: false,
																						overwrite: false,
																					},
																					{
																						destination:
																							"reducer",
																						dataKey:
																							"showFinalizeErrorOnStrategyConflictModal",
																						dataType:
																							"basic",
																						value: true,
																						overwrite: true,
																					},
																				],
																		},
																	],
																},
														},
														{
															type: "validation",
															conditions: [
																{
																	comparison:
																		"notExists",
																	source: "response",
																},
															],
															conditionOperator:
																"or",
															onValidationSuccess:
																{
																	actions: [
																		{
																			type: "reducer_function",
																			responseFormatter: [
																				{
																					destination: "reducer",
																					dataKey: "unsavedChangeFlag",
																					value: false
																				}
																			]
																		},	
																		{
																			type: "redirect",
																			link: "workbench",
																		},
																	],
																},
														},
													],
													// actions: [
													// 	{
													// 		type: "redirect",
													// 		link: "workbench",
													// 	},
													// ],
												},
											},
										],
									},
								],
							},
						],
					},
				},
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "",
					},
					componentProps: {
						content: [
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Units",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Units"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_sales_units",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Revenue $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Revenue $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_revenue",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_gm_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_gm_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUR $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUR $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_aur",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUM $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUM $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_aum",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"ST %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"ST %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_st_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Markdown $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Markdown $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"ia_recc_markdown_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
	{
		type: "div",
		pathSrc: "components",
		componentPath: "ui/wrapperDiv/WrapperDiv",
		staticProps: {
			customStyle: {
				minWidth: "425px",
				marginRight: "15px",
				padding: "10px 15px",
			},
			className: "box-container",
		},
		dataProps: {
			is_visible: {
				type: "derived",
				dataKey: "step_4_flags_options",
				subjectId: "enable_draft_finalise",
			},
		},
		componentProps: {
			content: [
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "flex-container",
						customStyle: {
							justifyContent: "space-between",
							marginBottom: "10px",
							minHeight: "40px",
						},
					},
					componentProps: {
						content: [
							{
								type: "text",
								pathSrc: "components",
								componentPath: "ui/typography/Typography",
								staticProps: {
									className: "common-page-title",
									variant: "h4",
									content: "Scenario In Draft",
								},
							},
							{
								type: "button",
								pathSrc: "components",
								componentPath: "ui/button/Button",
								staticProps: {
									variant: "primary",
									label: "Finalize",
									display: {
										type: "controlled",
										default: {
											show: true,
											enabled: true,
										},
									},
								},
								dataProps: {
									is_visible: {
										type: "derived",
										dataKey: "step_4_flags_options",
										subjectId: "enable_draft_finalise",
									},
								},
								functionProps: [
									{
										functionName: "onClick",
										actions: [
											{
												type: "reducer_function",
												responseFormatter: [
													{
														destination: "reducer",
														dataKey: "unsavedChangeFlag",
														value: false
													}
												]
											},	
											{
												type: "api_function",
												apiEndPoint: "model",
												apiMethod: "post",
												params: [
													{
														source: "fixed",
														apiRequestKey: "id",
														value: 3071,
													},
													{
														source: "reducer",
														sourceId: "step_1_A",
														subjectId:
															"strategy_id",
														apiRequestKeyNested:
															"strategy_id",
														apiRequestKey:
															"parameters",
													},
													{
														source: "fixed",
														apiRequestKey:
															"parameters",
														apiRequestKeyNested:
															"source",
														value: "bl_override",
													},
												],
												responseFormatter: [
													{
														destination: "reducer",
														apiResponseKey:
															"conflict_records",
														dataKey:
															"finalize_strategy_conflict_table",
														overwrite: true,
														dataType: "array",
													},
												],
												onComplete: {
													actions: [
														{
															type: "validation",
															conditions: [
																{
																	comparison:
																		"exists",
																	source: "response",
																},
															],
															conditionOperator:
																"or",
															onValidationSuccess:
																{
																	actions: [
																		{
																			type: "reducer_function",
																			responseFormatter:
																				[
																					// {
																					// 	destination:
																					// 		"reducer",
																					// 	dataKey:
																					// 		"step_4_flags_options",
																					// 	subjectId:
																					// 		"enable_draft_finalise",
																					// 	dataType:
																					// 		"basic",
																					// 	value: false,
																					// 	overwrite: false,
																					// },
																					{
																						destination:
																							"reducer",
																						dataKey:
																							"step_4_flags_options",
																						subjectId:
																							"enable_ia_finalise",
																						dataType:
																							"basic",
																						value: false,
																						overwrite: false,
																					},
																					{
																						destination:
																							"reducer",
																						dataKey:
																							"showFinalizeErrorOnStrategyConflictModal",
																						dataType:
																							"basic",
																						value: true,
																						overwrite: true,
																					},
																				],
																		},
																	],
																},
														},
														{
															type: "validation",
															conditions: [
																{
																	comparison:
																		"notExists",
																	source: "response",
																},
															],
															conditionOperator:
																"or",
															onValidationSuccess:
																{
																	actions: [
																		{
																			type: "reducer_function",
																			responseFormatter: [
																				{
																					destination: "reducer",
																					dataKey: "unsavedChangeFlag",
																					value: false
																				}
																			]
																		},
																		{
																			type: "redirect",
																			link: "workbench",
																		},
																	],
																},
														},
													],
													// actions: [
													// 	{
													// 		type: "redirect",
													// 		link: "workbench",
													// 	},
													// ],
												},
											},
										],
									},
								],
							},
						],
					},
				},
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "",
					},
					componentProps: {
						content: [
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Units",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Units"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_sales_units",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Revenue $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Revenue $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_revenue",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_gm_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_gm_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUR $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUR $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_aur",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUM $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUM $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_aum",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"ST %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"ST %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_st_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Markdown $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Markdown $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"draft_override_markdown_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
	{
		type: "div",
		pathSrc: "components",
		componentPath: "ui/wrapperDiv/WrapperDiv",
		staticProps: {
			customStyle: {
				minWidth: "425px",
				marginRight: "15px",
				padding: "10px 15px",
			},
			className: "box-container",
		},
		dataProps: {
			is_visible: {
				type: "derived",
				dataKey: "step_4_flags_options",
				subjectId: "show_bl_tile",
			},
		},
		componentProps: {
			content: [
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "flex-container",
						customStyle: {
							justifyContent: "space-between",
							marginBottom: "10px",
							minHeight: "40px",
						},
					},
					componentProps: {
						content: [
							{
								type: "text",
								pathSrc: "components",
								componentPath: "ui/typography/Typography",
								staticProps: {
									className: "common-page-title",
									variant: "h4",
									content: "BL Override",
								},
							},
						],
					},
				},
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "",
					},
					componentProps: {
						content: [
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Units",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Units"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_sales_units",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Revenue $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Revenue $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_revenue",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_gm_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_gm_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUR $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUR $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_aur",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUM $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUM $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_aum",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"ST %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"ST %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_st_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Markdown $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Markdown $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"bl_override_markdown_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},

	{
		type: "div",
		pathSrc: "components",
		componentPath: "ui/wrapperDiv/WrapperDiv",
		staticProps: {
			customStyle: {
				minWidth: "425px",
				marginRight: "15px",
				padding: "10px 15px",
			},
			className: "box-container",
		},
		componentProps: {
			content: [
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "flex-container",
						customStyle: {
							justifyContent: "space-between",
							marginBottom: "10px",
							minHeight: "40px",
						},
					},
					componentProps: {
						content: [
							{
								type: "text",
								pathSrc: "components",
								componentPath: "ui/typography/Typography",
								staticProps: {
									className: "common-page-title",
									variant: "h4",
									content: "Last Week",
								},
							},
						],
					},
				},
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "",
					},
					componentProps: {
						content: [
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Units",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Units"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_sales_units",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Revenue $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Revenue $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_revenue",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_gm_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_gm_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUR $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUR $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_aur",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUM $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUM $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_aum",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"ST %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"ST %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_st_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Markdown $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Markdown $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"lw_markdown_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
	{
		type: "div",
		pathSrc: "components",
		componentPath: "ui/wrapperDiv/WrapperDiv",
		staticProps: {
			customStyle: {
				minWidth: "425px",
				marginRight: "15px",
				padding: "10px 15px",
			},
			className: "box-container",
		},
		componentProps: {
			content: [
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "flex-container",
						customStyle: {
							justifyContent: "space-between",
							marginBottom: "10px",
							minHeight: "40px",
						},
					},
					componentProps: {
						content: [
							{
								type: "text",
								pathSrc: "components",
								componentPath: "ui/typography/Typography",
								staticProps: {
									className: "common-page-title",
									variant: "h4",
									content: "Historical",
								},
							},
						],
					},
				},
				{
					type: "div",
					pathSrc: "components",
					componentPath: "ui/wrapperDiv/WrapperDiv",
					staticProps: {
						className: "",
					},
					componentProps: {
						content: [
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Units",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Units"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_sales_units",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Revenue $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Revenue $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_revenue",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_gm_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Margin %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Margin %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_gm_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUR $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUR $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_aur",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"AUM $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"AUM $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_aum",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
							{
								type: "div",
								pathSrc: "components",
								componentPath: "ui/wrapperDiv/WrapperDiv",
								staticProps: {
									customStyle: {
										padding: "5px 0",
										borderTop: "1px solid #E2E2E2",
									},
								},
								componentProps: {
									content: [
										{
											type: "div",
											pathSrc: "components",
											componentPath:
												"ui/wrapperDiv/WrapperDiv",
											staticProps: {
												customStyle: {
													display: "grid",
													gridTemplateColumns:
														"repeat(3, 1fr)",
													border: "1px solid #0055AF",
													borderRadius: "3px",
													padding:
														"10px 10px 5px 10px",
													minHeight: "40px",
												},
											},
											componentProps: {
												content: [
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"ST %",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"ST %"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_st_percent",
																		},
																	},
																},
															],
														},
													},
													{
														type: "div",
														pathSrc: "components",
														componentPath:
															"ui/wrapperDiv/WrapperDiv",
														componentProps: {
															content: [
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h5",
																			content:
																				"Markdown $",
																		},
																},
																{
																	type: "text",
																	pathSrc:
																		"components",
																	componentPath:
																		"ui/typography/Typography",
																	staticProps:
																		{
																			className:
																				"text-14px-normal",
																			variant:
																				"h3",
																			formatter:
																				simulation_data_formatter_mapping[
																					"Markdown $"
																				],
																		},
																	dataProps: {
																		data: {
																			type: "derived",
																			dataKey:
																				"simulated_results",
																			subjectId:
																				"till_date_markdown_dollar",
																		},
																	},
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		},
	},
];
