import React, { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import FirebaseLogin from "./FirebaseUI";
import { firebaseobj } from "./FirebaseInitialize";
import { toastError } from "../../../store/features/global/global";
import { samlLoginCallback } from "../../../store/features/auth/auth";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AnalyticsContext from "../../../utils/postHog/CustomAnalyticsContext";
import { Button } from "impact-ui";

const LoginForm = (props) => {
	const { email, password, onChangeHandler, handleSubmit, inputError } = {
		...props,
	};

	const [showPassword, setShowPassword] = useState(false);

	const dispatch = useDispatch();
	const analytics = useContext(AnalyticsContext);
	const { REACT_APP_SAML_PROVIDER_ID } = process.env;

	const handleUIError = () => {
		dispatch(
			toastError(
				"Unable to authenticate. Please contact the Administrator"
			)
		);
	};

	return (
		<>
			<div
				className="login-form"
				// item
				// xs={10}
				// sm={7}
				// md={5}
				// justifyContent="flex-start"
			>
				<Box
					component="form"
					autoComplete="off"
					noValidate
					onSubmit={handleSubmit}
					sx={{ mt: 1 }}
				>
					<Typography
						id="signInText"
						className="sign-in-text"
						variant="h5"
					>
						Sign in to your account
					</Typography>

					<div className="input-group">
						<InputLabel
							margin="dense"
							htmlFor="loginInputEmail"
							className="input-label"
						>
							Email
						</InputLabel>
						<TextField
							variant="outlined"
							name="email"
							type="email"
							size="small"
							id="loginInputEmail"
							className={
								inputError.email && inputError.email.length
									? "signin-form-input error-border"
									: "signin-form-input"
							}
							aria-describedby="emailHelp"
							placeholder="Email Address"
							required=""
							fullWidth
							autoComplete="off"
							error={inputError.email}
							value={email}
							onChange={onChangeHandler}
						/>
						<p className="error-text">
							{inputError.email
								? "Please enter a valid email address"
								: ""}
						</p>
					</div>

					<div className="input-group">
						<InputLabel
							htmlFor="loginPassword"
							className="input-label"
						>
							Password
						</InputLabel>
						<TextField
							variant="outlined"
							name="password"
							type={`${showPassword ? "text" : "password"}`}
							size="small"
							id="loginPassword"
							className={
								inputError.password &&
								inputError.password.length
									? "signin-form-input error-border"
									: "signin-form-input"
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() =>
												setShowPassword(!showPassword)
											}
											onMouseDown={() =>
												setShowPassword(!showPassword)
											}
											size="small"
										>
											{showPassword ? (
												<VisibilityOutlinedIcon
													color="default"
													fontSize="small"
												/>
											) : (
												<VisibilityOffOutlinedIcon
													color="default"
													fontSize="small"
												/>
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
							placeholder="Password"
							required=""
							fullWidth
							value={password}
							onChange={onChangeHandler}
						/>
						<p className="error-text">
							{inputError.password ? "Please enter password" : ""}
						</p>
					</div>
					<Button
						className="signin-button"
						label="Sign In"
						variant="primary"
						type="submit"
					/>
				</Box>

				<Divider
					sx={{ margin: "15px 100px 0 100px" }}
					className="login-footer"
				>
					Or continue with
				</Divider>
				<Box sx={{ mt: 4 }}>
					<FirebaseLogin
						FirebaseUIConfig={{
							signInFlow: "popup",
							// signInSuccessUrl: "/decision-dashboard",
							signInOptions: [
								{
									provider: REACT_APP_SAML_PROVIDER_ID,
									fullLabel: "Login with SSO",
									buttonColor: "#fff",
									iconUrl: "/IA.svg",
								},
							],
							callbacks: {
								signInSuccessWithAuthResult: function (
									authResult,
									redirectUrl
								) {
									authResult.user
										.getIdToken()
										.then((accessToken) => {
											const samlLoginCallbackPayload = {
												idToken: accessToken,
											};
											dispatch(
												samlLoginCallback(
													samlLoginCallbackPayload
												)
											);
											analytics.trackLogin(email);
										})
										.catch((error) => {
											console.log(error);
											dispatch(
												toastError(
													"Unable to authenticate. Please contact the Administrator"
												)
											);
										});
									return false;
								},
								signInFailure: function (error) {
									return handleUIError(error);
								},
							},
						}}
						firebaseobj={firebaseobj}
					/>
				</Box>
				<Typography
					variant="body"
					component="p"
					className="contact"
					textAlign="center"
				>
					Need any help?{" "}
					<Link
						href="//impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/70759/"
						target="_blank"
						underline="none"
					>
						{"Contact now"}
					</Link>
				</Typography>
			</div>
		</>
	);
};

export default LoginForm;
