import moment from "moment";

export const CREATE_PCD_CONFIG = {
	screen_composition: {
		components: [
			{
				id: "create-pcd-title-wrapper",
				type: "div",
				staticProps: {
					className: "page-title-container",
					customStyle: {
						marginBottom: "20px",
					},
				},
				componentProps: {
					content: [
						{
							id: "create-pcd-title",
							isDefaultExport: false,
							type: "text",
							staticProps: {
								className: "common-page-title",
								variant: "h2",
								content: "Create PCD Config",
							},
						},
						{
							id: "return-to-pcd-button",
							type: "button",
							staticProps: {
								icon: "return",
								iconClass: "",
								className: "",
								customStyle: {
									fontSize: "14px",
									margin: 0,
									fontWeight: 500,
									background: "transparent",
								},
								variant: "secondary",
								label: "Return to All Configs",
								iconType:"svg",
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "redirect",
											link: "pricing-calendar-configuration",
										},
									],
								},
							],
						},
					],
				},
			},
			{
				id: "create-pcd-details",
				type: "div",
				staticProps: {
					className: "box-container",
				},
				componentProps: {
					content: [
						{
							id: "create-store-group-accordion-1",
							type: "accordion",
							staticProps: {
								label: "Select Timeline",
								expanded: true,
								defaultExpanded: true,
								hideCollapse: true,
								hideBlueLine: true,
							},
							componentProps: {
								content: [
									{
										type: "form",
										id: "step_1_A",
										staticProps: {
											fields: [
												{
													type: "text",
													id: "config_name",
													placeholder: "Name",
													variant: "outlined",
													isMandatory: true,
													showLabel: true,
													label: "PCD Config Name",
												},
												{
													type: "datepicker",
													placeholder:
														"Select Date Range",
													variant: "outlined",
													isMandatory: true,
													label: "Date Range",
													showLabel: true,
													minDate: moment(
														new Date()
													).add(1, "days"),
													start_date: moment(
														new Date()
													).add(1, "days"),
													end_date: moment(
														new Date()
													).add(8, "days"),
												},
											],
										},
									},
								],
							},
						},
					],
				},
			},
			{
				id: "create-pcd-component",
				type: "pcd",
				staticProps: {
					showTitle: true,
					showBoxContainer: true,
					showSubmitButton: true,
					activeTab: "weekly",
					pcdTypes: [
						{
							id: "weekly",
							label: "Default (i.e Weekly)",
						},
						{
							id: "full_custom",
							label: "Custom",
						},
					],
				},
				componentProps: {
					content: [{}],
				},
			},
		],
	},
	on_load_functions: [
		{
			type: "api_function",
			apiEndPoint: "model",
			apiMethod: "post",
			on_load_confirmation: {
				type: "check",
				conditions: [
					{
						comparison: "exists",
						source: "reducer",
						sourceId: "step_1_A",
						subjectId: "calendar_config_id",
						dataType: "basic",
					},
				],
			},
			params: [
				{
					source: "fixed",
					apiRequestKey: "id",
					value: 6001,
					dataType: "basic",
				},
				{
					source: "reducer",
					sourceId: "step_1_A",
					subjectId: "calendar_config_id",
					apiRequestKey: "parameters",
					apiRequestKeyNested: "calendar_config_id",
					dataType: "basic",
				},
			],
			responseFormatter: [
				{
					destination: "reducer",
					dataKey: "pcdConfigDetailsOriginal",
					// overwrite: true,
				},
				{
					subjectId: "config_name",
					apiResponseKey: "config_name",
					dataType: "basic",
					destination: "reducer",
					dataKey: "step_1_A",
				},
				{
					subjectId: "start_date",
					apiResponseKey: "start_date",
					dataType: "basic",
					destination: "reducer",
					dataKey: "step_1_A",
				},
				{
					subjectId: "end_date",
					apiResponseKey: "end_date",
					dataType: "basic",
					destination: "reducer",
					dataKey: "step_1_A",
				},
				{
					subjectId: "customise_by",
					apiResponseKey: "customise_by",
					dataType: "basic",
					destination: "reducer",
					dataKey: "step_1_A",
				},
				{
					subjectId: "calendar_pcds",
					apiResponseKey: "calendar_pcds",
					dataType: "basic",
					destination: "reducer",
					dataKey: "step_1_A",
				},
			],
		},
		{
			type: "reducer_function",
			on_load_confirmation: {
				type: "check",
				conditions: [
					{
						comparison: "exists",
						source: "reducer",
						sourceId: "step_1_A",
						subjectId: "calendar_config_id",
						dataType: "basic",
					},
				],
			},
			params: [],
			responseFormatter: [
				{
					destination: "reducer",
					dataType: "object",
					dataKey: "componentDisplayControls",
					subjectId: "create-pcd-submit-button",
					value: {
						show: false,
						enabled: false,
					},
				},
				{
					destination: "reducer",
					dataType: "object",
					dataKey: "componentDisplayControls",
					subjectId: "edit-pcd-submit-button",
					value: {
						show: true,
						enabled: true,
					},
				},
			],
		},
	],
};
