import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import store from "./store/index";
import AppRoutes from "./containers/common/Routes";
import "./App.css";
import SnackQueue from "./containers/common/SnackQueue";
import SnackBarProvider from "./containers/common/SnackBarProvider";

class App extends PureComponent {
	componentDidMount() {
		const { REACT_APP_NODE_ENV, REACT_APP_WHATFIX_URL } = process.env;
		if (REACT_APP_NODE_ENV === "test" || REACT_APP_NODE_ENV === "uat") {
			const script = document.createElement("script");
			script.src = REACT_APP_WHATFIX_URL;
			script.async = true;
			script.type = "text/javascript";
			script.language = "javascript";
			document.head.appendChild(script);
		}
	}

	render() {
		return (
			<Provider store={store}>
				<SnackBarProvider>
					<SnackQueue />
					<AppRoutes />
				</SnackBarProvider>
			</Provider>
		);
	}
}

export default App;
