import React from "react";
import Typography from "@mui/material/Typography";
import MUIBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useLocation } from "react-router-dom";
import { protectedRoutes } from "../../../constants/NewConstants";
import "./Header.scss";
import { connect } from "react-redux";

const Breadcrumbs = (props) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const pathnames = pathname.split("/").filter(Boolean);
	return (
		<MUIBreadcrumbs
			className="breadcrumb-container"
			aria-label="breadcrumb"
		>
			{pathnames.map((path, index) => {
				const isLast = index === pathnames.length - 1;
				const currentRoute = protectedRoutes?.find((pRoute) => {
					return pRoute.breadcrumbPath === path;
				});
				return isLast ? (
					<Typography
						className="active"
						key={currentRoute?.key}
						variant="span"
						component="span"
					>
						{currentRoute.subTitle
							? props.stepper_breadCrumb
							: currentRoute?.title}
					</Typography>
				) : (
					<Link
						key={currentRoute?.key}
						onClick={() => navigate(currentRoute?.path)}
					>
						{currentRoute?.title}
					</Link>
				);
			})}
		</MUIBreadcrumbs>
	);
};
const mapStateToProps = (store) => {
	return {
		stepper_breadCrumb: store.app.stepper_breadCrumb,
	};
};

export default connect(mapStateToProps)(Breadcrumbs);
