import { ProductAndStores } from "./CreateScreenStrategy/ProductAndStore";
import { MdObjectives } from "./CreateScreenStrategy/MdObjectives";
import { Rules } from "./CreateScreenStrategy/Rules";
import { Ia_Recommendation } from "./CreateScreenStrategy/Ia_Recommendation";

export const CREATE_STRATEGY = {
	screen_composition: {
		components: [
			{
				type: "div",
				staticProps: {
					className: "common-content-container",
				},
				componentProps: {
					content: [
						{
							type: "stepper",
							staticProps: {
								className: "",
								// will revert this after complete development , before demo
								confirmOnUnsavedChanges: true,
								createStrategyStepper: true,
								nonLinear: false,
								steps: [
									{
										id: 0,
										label: "Product And Stores",
										nextLabel:
											"Proceed to MD Objectives & Guard Rails",
									},
									{
										id: 1,
										label: "MD Objectives & Guard Rails",
									},
									{
										id: 2,
										label: "Rules",
									},
									{
										id: 3,
										label: "IA Recommended Results",
									},
								],
							},
							componentProps: {
								content: [
									{
										type: "mapped_component",
										id: "ProductAndStores",
										staticProps: {
											stepperContentId: 0,
										},
									},
									{
										type: "mapped_component",
										id: "MdObjectives",
										staticProps: {
											stepperContentId: 1,
										},
									},
									{
										type: "mapped_component",
										id: "StrategyDetailss",
										staticProps: {
											stepperContentId: 1,
										},
									},
									{
										type: "mapped_component",
										id: "Rules",
										staticProps: {
											stepperContentId: 2,
										},
									},
									{
										type: "mapped_component",
										id: "IA_Recommendation",
										staticProps: {
											stepperContentId: 3,
										},
									},
								],
								extraContent: [
									{
										// id: "return-to-product-group-button",
										type: "button",
										staticProps: {
											icon: "return",
											customStyle: {
												fontSize: "14px",
												margin: 0,
												fontWeight: 500,
												background: "transparent",
											},
											variant: "secondary",
											label: "Return to Workbench",
											iconType: "svg",
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "redirect",
														link: "workbench",
													},
												],
											},
										],
									},
								],
							},
							dataProps: {
								currentStep: {
									type: "derived",
									dataKey: "step_1_A",
									subjectId: "currentStep",
								},
								maxStepCount: {
									type: "derived",
									dataKey: "maxStepCount",
								},
							},
						},
					],
				},
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "createStrategyChangeFlag",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
		],
	},
	mapped_components: {
		ProductAndStores: ProductAndStores,
		MdObjectives: MdObjectives,
		Rules: Rules,
		IA_Recommendation: Ia_Recommendation,
	},
	on_load_functions: [
		{
			type: "reducer_function",
			responseFormatter: [
				{
					destination: "reducer",
					dataKey: "filteredProducts",
					dataType: "array",
					overwrite: true,
					value: [],
				},
				{
					destination: "reducer",
					dataKey: "filteredStores",
					dataType: "array",
					overwrite: true,
					value: [],
				},
				{
					destination: "reducer",
					dataKey: "productgroups",
					dataType: "array",
					overwrite: true,
					value: [],
				},
				{
					destination: "reducer",
					dataKey: "storegroups",
					dataType: "array",
					overwrite: true,
					value: [],
				},
				{
					destination: "reducer",
					dataKey: "create_strategy_product_selection_type",
					dataType: "basic",
					value: "product_hierarchy1",
					overwrite: true,
				},
				{
					destination: "reducer",
					dataKey: "create_strategy_store_selection_type",
					dataType: "basic",
					value: "store_hierarchy1",
					overwrite: true,
				},
				{
					destination: "reducer",
					dataKey: "create_strategy_product_mapping_type",
					value: "product_mapping_no",
					dataType: "basic",
					overwrite: true,
				},
			],
		},
		{
			type: "api_function",
			apiEndPoint: "model",
			apiMethod: "post",
			confirmation: {
				type: "check",
				conditionOperator: "and",
				conditions: [
					{
						comparison: "exists",
						source: "reducer",
						sourceId: "step_1_A",
						subjectId: "strategy_id",
						dataType: "basic",
					},
				],
			},
			params: [
				{
					source: "fixed",
					dataType: "object",
					apiRequestKey: "id",
					value: 3001,
				},
				{
					source: "reducer",
					sourceId: "step_1_A",
					subjectId: "strategy_id",
					dataType: "basic",
					apiRequestKey: "parameters",
					apiRequestKeyNested: "strategy_id",
				},
				{
					source: "fixed",
					dataType: "object",
					value: "strategy",
					apiRequestKey: "parameters",
					apiRequestKeyNested: "section",
				},
			],
			responseFormatter: [
				{
					destination: "reducer",
					dataKey: "step_1_A",
					dataType: "object",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A_copy",
					dataType: "object",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A",
					apiResponseKey: "strategy_dates",
					dataType: "object",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A_copy",
					apiResponseKey: "strategy_dates",
					dataType: "object",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A",
					subjectId: "currentStep",
					apiResponseKey: "step_count",
					dataType: "object",
				},
				{
					destination: "reducer",
					dataKey: "maxStepCount",
					apiResponseKey: "step_count",
					dataType: "object",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A",
					apiResponseKey: "configured_by_sku_store_mapping",
					dataType: "basic",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A",
					apiResponseKey: "sku_store_mapping_exists",
					dataType: "basic",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A",
					apiResponseKey: "configured_by_product_group",
					dataType: "basic",
				},
				{
					destination: "reducer",
					dataKey: "step_1_A",
					apiResponseKey: "configured_by_store_group",
					dataType: "basic",
				},
			],
		},
	],
};
