export const MdObjectives = {
	screen_composition: {
		components: [
			{
				type: "div",
				pathSrc: "components",
				componentPath: "ui/wrapperDiv/WrapperDiv",
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "stepper_breadCrumb",
										value: "Create MD strategy / Select MD Objectives & Guard Rails",
										dataType: "object",
									},
								],
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									conditionOperator: "and",
									conditions: [
										{
											comparison: "exists",
											source: "reducer",
											sourceId: "step_1_A",
											subjectId: "strategy_id",
											dataType: "basic",
										},
									],
								},
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										dataType: "basic",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_id",
									},
									{
										source: "fixed",
										dataType: "object",
										value: "strategy",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "section",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_1_A",
										apiResponseKey: "strategy_dates",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A_copy",
										apiResponseKey: "strategy_dates",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "step_1_A_copy",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "maxStepCount",
										apiResponseKey: "step_count",
										dataType: "object",
									},
								],
							},
							// Call to get Strategy Objectives
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "section",
										value: "objective",
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										dataType: "basic",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_id",
									},
									// {
									// 	source: "reducer",
									// 	sourceId: "strategy_id",
									// 	dataType: "basic",
									// 	apiRequestKey: "parameters",
									// 	apiRequestKeyNested: "strategy_id",
									// },
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_2",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "store_recommendation_label",
										dataType: "object",
										apiResponseKey:
											"store_recommendation_level_name",
									},
									{
										destination: "reducer",
										dataKey: "product_recommendation_label",
										dataType: "object",
										apiResponseKey:
											"product_recommendation_level_name",
									},
									{
										destination: "reducer",
										dataKey: "step_2_objectives",
										dataType: "object",
										apiResponseKey: "strategy_objectives",
									},
									{
										destination: "reducer",
										dataKey: "step_2_objectives_copy",
										dataType: "object",
										apiResponseKey: "strategy_objectives",
									},
									{
										destination: "reducer",
										dataKey: "step_2_dropdown",
										subjectId: "product_level",
										dataType: "object",
										apiResponseKey:
											"product_recommendation_level",
									},
									{
										destination: "reducer",
										dataKey: "step_2_dropdown_copy",
										subjectId: "product_level",
										dataType: "object",
										apiResponseKey:
											"product_recommendation_level",
									},
									{
										destination: "reducer",
										dataKey: "step_2_dropdown",
										subjectId: "store_level",
										dataType: "object",
										apiResponseKey:
											"store_recommendation_level",
									},
									{
										destination: "reducer",
										dataKey: "step_2_dropdown_copy",
										subjectId: "store_level",
										dataType: "object",
										apiResponseKey:
											"store_recommendation_level",
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "reducer",
													sourceId:
														"step_2_objectives",
													subjectId: "revenue",
													subjectId1:
														"objective_value",
												},
											],
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue-remove-btn",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue-add-btn",
																value: {
																	show: false,
																	enabled: false,
																},
															},
														],
													},
												],
											},
										},
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "reducer",
													sourceId:
														"step_2_objectives",
													subjectId: "gm_percent",
													subjectId1:
														"objective_value",
												},
											],
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-percent",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-remove-btn",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-add-btn",
																value: {
																	show: false,
																	enabled: false,
																},
															},
														],
													},
												],
											},
										},
									],
								},
							},
							// Call to get Chart Data @ step 2
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								// on_load_confirmation: {
								// 	type: "check",
								// 	conditions: [
								// 		{
								// 			comparison: "exists",
								// 			source: "reducer",
								// 			sourceId: "create_product_group_details",
								// 			subjectId: "product_group_id",
								// 			dataType: "basic",
								// 		},
								// 	],
								// },
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3003,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										dataType: "object",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_id",
									},
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "id",
										value: 3003,
									},
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "parameters",
										apiRequestKeyNested:
											"include_store_lw_metrics",
										value: true,
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_2_chart",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "store_oh",
										apiResponseKey: "store_oh",
										dataType: "object",
									},
								],
							},
							// Call to get the dropdown option in step 2
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3004,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										dataType: "object",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_id",
									},
									// Would be getting dynamically when create strategy API return an Strategy Id
									// {
									// 	source: "fixed",
									// 	dataType: "object",
									// 	apiRequestKey: "parameters",
									// 	apiRequestKeyNested: "strategy_id",
									// 	value: 9,
									// },
									// {
									// 	source: "reducer",
									// 	sourceId: "create_strategy",
									// 	subjectId: "step_2_chart_params",
									// 	dataType: "object",
									// 	apiRequestKey: "parameters",
									// },300
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_2_dropdown_data",
										dataType: "object",
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "createStrategyChangeFlag",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3091,
									},
									{
										source: "fixed",
										dataType: "basic",
										apiRequestKey: "parameters",
										apiRequestKeyNested:
											"po_dc_inventory_high_threshold",
										value: 10,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										dataType: "basic",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_id",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"mdObjectives_po_dc_inventory_table",
										overwrite: true,
										dataType: "array",
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"disablePODCInventoryAlert",
																dataType:
																	"basic",
																value: false,
																overwrite: true,
															},
														],
													},
												],
											},
										},
										{
											type: "validation",
											conditions: [
												{
													comparison: "notExists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"disablePODCInventoryAlert",
																dataType:
																	"basic",
																value: true,
																overwrite: true,
															},
														],
													},
												],
											},
										},
									],
								},
							},
							// Setting unsavedchange flag to false
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "unsavedChangeFlag",
										value: false,
									}
								]
							}
						],
					},
				],
				componentProps: {
					content: [
						{
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							componentProps: {
								content: [
									{
										type: "strategySummaryMdObjectives",
										staticProps: {},
										dataProps: {
											data: {
												type: "derived",
												dataKey: "step_2_chart",
											},
										},
									},
									{
										type: "div",
										staticProps: {
											customStyle: {
												display: "flex",
												alignItems: "center",
												gap: "10px",
											},
										},
										componentProps: {
											content: [
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														className:
															"common-page-title",
														customStyle: {
															marginBottom:
																"10px",
															paddingTop: "10px",
														},
														variant: "h2",
														content:
															"Inventory Info",
													},
												},
												{
													type: "iconTootlip",
													staticProps: {
														toolTipPosition: "top",
														isMaterialIcon: false,
														icon: "fa-solid fa-triangle-exclamation",
														iconAction: true,
														primaryMessage:
															"PO/DC Is high for some SKUs.",
														secondaryMessage:
															"Click on info icon for detailed view.",
														customStyle: {
															fontSize: "16px",
															display: "flex",
														},
														hideIfDisabled: true,
													},
													dataProps: {
														disabled: {
															type: "derived",
															dataKey:
																"disablePODCInventoryAlert",
														},
													},
													functionProps: [
														{
															functionName:
																"onClick",
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"po_dc_inventory_table_overlay_flag",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													],
												},
											],
										},
									},
									{
										type: "chartDistribution",
										pathSrc: "components",
										componentPath:
											"ui/customComponents/chartDistribution/ChartDistribution",
										staticProps: {
											customStyle: {
												alignItems: "center",
												border: "none",
												boxShadow:
													"0px 0px 8px #00000021",
												marginBottom: "25px",
											},
											className: "box-container",
											subClassName: "stepper-container",
											hideLabel: true,
											name: "Inventory",
											subTitle: "Total Inventory",
											chartColors: {
												store_oh: "#EDC69E",
												store_in_transit: "#BAC9EA",
												store_on_order: "#D3DCF0",
												dc_oh: "#83DCD0",
												dc_in_transit: "#94AFAB",
												dc_on_order: "#90AEEE",
											},
										},
										dataProps: {
											data: {
												type: "derived",
												dataKey: "step_2_chart",
											},
										},
									},
								],
							},
						},
						{
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							staticProps: {
								// customStyle: {
								// 	marginTop: "24px",
								// },
							},
							componentProps: {
								content: [
									{
										type: "text",
										pathSrc: "components",
										componentPath:
											"ui/typography/Typography",
										staticProps: {
											className: "common-page-title",
											customStyle: {
												margin: "0",
											},
											variant: "h2",
											content: "MD Objectives*",
										},
									},
									{
										type: "div",
										staticProps: {
											className: "flex-container",
											customStyle: {
												alignItems: "center",
											},
										},
										componentProps: {
											content: [
												{
													type: "text",
													pathSrc: "components",
													componentPath:
														"ui/typography/Typography",
													staticProps: {
														className:
															"common-page-title",
														customStyle: {
															textAlign: "left",
															padding:
																"10px 0 20px 0",
														},
														variant: "h5",
														content:
															"*Please fill at least one objectives that are applicable for this MD Strategy.",
													},
												},
												{
													type: "button",
													staticProps: {
														icon: "",
														iconClass: "",
														className: "",
														variant: "url",
														label: "Add more objectives",
														customStyle: {
															fontSize: "14px",
															margin: 0,
															fontWeight: 500,
															background:
																"transparent",
															marginLeft: "20px",
															padding:
																"10px 0px 20px",
														},
													},
													functionProps: [
														{
															functionName:
																"onClick",
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"addObjectivePanel",
																				dataType:
																					"basic",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													],
													dataProps: {
														isDisabled: {
															type: "derived",
															dataKey:
																"editReadOnlyFlag",
														},
													},
												},
											],
										},
									},
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											className: "flex-container",
											customStyle: {
												marginBottom: "30px",
												flexWrap: "wrap",
											},
										},
										componentProps: {
											content: [
												{
													type: "div",
													componentProps: {
														content: [
															{
																id: "step_2_objectives",
																type: "form",
																staticProps: {
																	className:
																		"box-container",
																	customStyle:
																		{
																			width: "400px",
																			padding:
																				"15px 15px 5px",
																			marginRight:
																				"20px",
																		},
																	label: "ST% / Units Target",
																	note: "Both values are interdependent",
																	// flag
																	sales_st: true,
																	inputType:
																		"dataInput",
																	fields: [
																		{
																			type: "text",
																			id: "objective_value",
																			// Temp solution for disabling
																			name: "sales_units",
																			inputType:
																				"number",
																			placeholder:
																				"Units",
																			variant:
																				"outlined",
																			// isMandatory: true,
																			label: "Units",
																			showLabel: true,
																			calculateTo:
																				"st_percent",
																		},
																		{
																			id: "md_objective_target_btn_icon",
																			type: "iconButton",
																			staticProps:
																				{
																					variant:
																						"contained",
																					icon: "multiple_stop",
																					className:
																						"icon-button",
																					customStyle:
																						{
																							borderRadius:
																								"50%",
																							boxShadow:
																								"0px 0px 8px #0000001F",
																							background:
																								"#FFFFFF",
																							marginRight:
																								"15px",
																						},
																					iconClass:
																						"color-blue",
																				},
																		},
																		{
																			type: "text",
																			inputType:
																				"number",
																			id: "st_percent",
																			// Temp solution for disabling
																			name: "st_percent",
																			placeholder:
																				"ST%",
																			variant:
																				"outlined",
																			// isMandatory: true,
																			label: "ST%",
																			showLabel: true,
																			calculate: true,
																			calculateTo:
																				"sales_units",
																			max: 100,
																		},
																	],
																},
																dataProps: {
																	store_oh: {
																		type: "derived",
																		dataKey:
																			"step_2_chart",
																		subjectId:
																			"store_oh",
																	},
																	store_in_transit:
																		{
																			type: "derived",
																			dataKey:
																				"step_2_chart",
																			subjectId:
																				"store_in_transit",
																		},
																	disabledFields:
																		{
																			type: "derived",
																			dataKey:
																				"disabledFields",
																		},
																	data: {
																		type: "derived",
																		dataKey:
																			"step_2_objectives",
																		subjectId:
																			"sales_units",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													componentProps: {
														content: [
															{
																id: "step_2",
																type: "form",
																staticProps: {
																	inputType:
																		"dataInput",
																	className:
																		"box-container",
																	customStyle:
																		{
																			width: "400px",
																			padding:
																				"15px 15px 5px",
																			marginRight:
																				"20px",
																		},
																	label: "Gross Margin $",
																	fields: [
																		{
																			type: "text",
																			id: "objective_value",
																			inputType:
																				"number",
																			placeholder:
																				"Target",
																			variant:
																				"outlined",
																			// isMandatory: true,
																			label: "Target",
																			showLabel: true,
																		},
																	],
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"step_2_objectives",
																		subjectId:
																			"gm_dollar",
																	},
																},
															},
														],
													},
												},
												{
													id: "gm-percent",
													type: "div",
													staticProps: {
														display: {
															type: "controlled",
															default: {
																show: false,
																enabled: false,
															},
														},
													},
													componentProps: {
														content: [
															{
																id: "step_2",
																type: "form",
																staticProps: {
																	inputType:
																		"dataInput",
																	className:
																		"box-container",
																	customStyle:
																		{
																			width: "400px",
																			marginRight:
																				"20px",
																			padding:
																				"15px 15px 5px",
																		},
																	label: "GM %",
																	fields: [
																		{
																			type: "text",
																			id: "objective_value",
																			inputType:
																				"number",
																			placeholder:
																				"Target",
																			variant:
																				"outlined",
																			// isMandatory: true,
																			label: "Target",
																			showLabel: true,
																			max: 100,
																		},
																	],
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"step_2_objectives",
																		subjectId:
																			"gm_percent",
																	},
																},
															},
														],
													},
												},
												{
													id: "revenue",
													type: "div",
													staticProps: {
														display: {
															type: "controlled",
															default: {
																show: false,
																enabled: false,
															},
														},
													},
													componentProps: {
														content: [
															{
																id: "step_2",
																type: "form",
																staticProps: {
																	inputType:
																		"dataInput",
																	className:
																		"box-container",
																	customStyle:
																		{
																			width: "400px",
																			marginRight:
																				"20px",
																			padding:
																				"15px 15px 5px",
																		},
																	label: "Revenue",
																	fields: [
																		{
																			type: "text",
																			id: "objective_value",
																			inputType:
																				"number",
																			placeholder:
																				"Target",
																			variant:
																				"outlined",
																			// isMandatory: true,
																			label: "Target",
																			showLabel: true,
																		},
																	],
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"step_2_objectives",
																		subjectId:
																			"revenue",
																	},
																},
															},
														],
													},
												},
											],
										},
									},
								],
							},
						},
						{
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							staticProps: {
								customStyle: {
									marginTop: "24px",
								},
							},
							componentProps: {
								content: [
									{
										type: "text",
										pathSrc: "components",
										componentPath:
											"ui/typography/Typography",
										staticProps: {
											className: "common-page-title",
											variant: "h2",
											customStyle: {
												marginBottom: "15px",
											},
											content: "MD Recommendation Level",
										},
									},
									// TODO: try a JSON way to build the dataInput
									{
										type: "div",
										pathSrc: "components",
										componentPath:
											"ui/wrapperDiv/WrapperDiv",
										staticProps: {
											className: "flex-container",
										},
										componentProps: {
											content: [
												{
													id: "step_2_dropdown",
													type: "form",
													staticProps: {
														inputType: "dataInput",
														// for time been
														isSelect: true,
														className:
															"box-container",
														customStyle: {
															width: "400px",
															marginRight: "20px",
															padding:
																"15px 15px 5px 15px",
															marginBottom: "0",
														},
														label: "Product MD Level",
														fields: [
															{
																type: "select",
																isMulti: false,
																id: "product_level",
																placeholder:
																	"select",
																variant:
																	"outlined",
																className:
																	"width-250",
																// isMandatory: true,
																label: "Product MD Level",
																showLabel: true,
															},
														],
													},
													dataProps: {
														options: {
															type: "derived",
															dataKey:
																"step_2_dropdown_data",
														},
													},
												},
												{
													id: "step_2_dropdown",
													type: "form",
													staticProps: {
														inputType: "dataInput",
														className:
															"box-container",
														customStyle: {
															width: "400px",
															padding:
																"15px 15px 5px 15px",
															marginBottom: "0",
														},
														// for time been
														isSelect: true,
														label: "Store MD Level",
														fields: [
															{
																type: "select",
																id: "store_level",
																isMulti: false,
																placeholder:
																	"select",
																variant:
																	"outlined",
																// isMandatory: true,
																label: "Store MD Level",
																showLabel: true,
																hideSearch: true,
																className:
																	"width-250",
															},
														],
													},
													dataProps: {
														options: {
															type: "derived",
															dataKey:
																"step_2_dropdown_data",
														},
													},
												},
											],
										},
									},
								],
							},
						},
						{
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							staticProps: {
								className: "flex-container",
								customStyle: {
									marginTop: "20px",
								},
							},
							componentProps: {
								content: [
									{
										type: "button",
										pathSrc: "components",
										componentPath: "ui/button/Button",
										staticProps: {
											customStyle: {
												marginRight: "10px",
											},
											variant: "primary",
											label: "Next",
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														confirmation: {
															conditions: [
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"step_2_objectives",
																	subjectId:
																		"sales_units",
																	handleStPercent: true,
																	checkValue:
																		{
																			source: "reducer",
																			sourceId:
																				"step_2_objectives_copy",
																			subjectId:
																				"sales_units",
																		},
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"step_2_objectives",
																	subjectId:
																		"st_percent",
																	handleStPercent: true,
																	checkValue:
																		{
																			source: "reducer",
																			sourceId:
																				"step_2_objectives_copy",
																			subjectId:
																				"st_percent",
																		},
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"step_2_objectives",
																	subjectId:
																		"revenue",
																	checkValue:
																		{
																			source: "reducer",
																			sourceId:
																				"step_2_objectives_copy",
																			subjectId:
																				"revenue",
																		},
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"step_2_objectives",
																	subjectId:
																		"gm_dollar",
																	checkValue:
																		{
																			source: "reducer",
																			sourceId:
																				"step_2_objectives_copy",
																			subjectId:
																				"gm_dollar",
																		},
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"step_2_objectives",
																	subjectId:
																		"gm_percent",
																	checkValue:
																		{
																			source: "reducer",
																			sourceId:
																				"step_2_objectives_copy",
																			subjectId:
																				"gm_percent",
																		},
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"step_2_dropdown",
																	subjectId:
																		"store_level",
																	checkValue:
																		{
																			source: "reducer",
																			sourceId:
																				"step_2_dropdown_copy",
																			subjectId:
																				"store_level",
																		},
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"step_2_dropdown",
																	subjectId:
																		"product_level",
																	checkValue:
																		{
																			source: "reducer",
																			sourceId:
																				"step_2_dropdown_copy",
																			subjectId:
																				"product_level",
																		},
																},
															],
														},
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"createStrategyChangeFlag",
																dataType:
																	"basic",
																value: true,
																overwrite: true,
															},
														],
													},
													// reducer to chnage changeFlag to true

													//  Call Api if changeFlag is false and maxStepCount is 0 along with Objective Conditions
													{
														type: "api_function",
														apiEndPoint: "model",
														apiMethod: "post",
														confirmation: {
															showErrorMessage: true,
															conditionOperator:
																"and",
															conditions: [
																{
																	comparison:
																		"multiple",
																	conditionOperator:
																		"or",
																	message:
																		"Atleast one objective should be present",
																	conditions:
																		[
																			{
																				comparison:
																					"exists",
																				source: "reducer",
																				sourceId:
																					"step_2_objectives",
																				subjectId:
																					"revenue",
																				subjectId1:
																					"objective_value",
																			},
																			{
																				comparison:
																					"exists",
																				source: "reducer",
																				sourceId:
																					"step_2_objectives",
																				subjectId:
																					"sales_units",
																				subjectId1:
																					"objective_value",
																			},
																			{
																				comparison:
																					"exists",
																				source: "reducer",
																				sourceId:
																					"step_2_objectives",
																				subjectId:
																					"gm_dollar",
																				subjectId1:
																					"objective_value",
																			},
																			{
																				comparison:
																					"exists",
																				source: "reducer",
																				sourceId:
																					"step_2_objectives",
																				subjectId:
																					"gm_percent",
																				subjectId1:
																					"objective_value",
																			},
																			{
																				comparison:
																					"exists",
																				source: "reducer",
																				sourceId:
																					"step_2_objectives",
																				subjectId:
																					"st_percent",
																				subjectId1:
																					"objective_value",
																			},
																		],
																},
																{
																	comparison:
																		"multiple",
																	conditionOperator:
																		"or",
																	conditions:
																		[
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"createStrategyChangeFlag",
																				checkValue: true,
																			},
																			{
																				comparison:
																					"multiple",
																				conditionOperator:
																					"and",
																				conditions:
																					[
																						{
																							comparison:
																								"equals",
																							source: "reducer",
																							sourceId:
																								"createStrategyChangeFlag",
																							checkValue: false,
																						},
																						{
																							comparison:
																								"equals",
																							source: "reducer",
																							sourceId:
																								"maxStepCount",
																							checkValue: 0,
																						},
																					],
																			},
																		],
																},
															],
														},
														params: [
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"id",
																value: 3081,
															},
															{
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"strategy_id",
															},
															{
																source: "reducer",
																sourceId:
																	"step_2_dropdown",
																subjectId:
																	"product_level",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"product_recommendation_level",
																selectEntireRow: true,
															},
															{
																source: "reducer",
																sourceId:
																	"step_2_dropdown",
																subjectId:
																	"store_level",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"store_recommendation_level",
																selectEntireRow: true,
															},
															{
																source: "reducer",
																sourceId:
																	"step_2_objectives",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"objectives",
																selectEntireRow: true,
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																apiResponseKey:
																	"strategy_id",
																dataType:
																	"object",
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"maxStepCount",
																apiResponseKey:
																	"step_count",
																dataType:
																	"object",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"step_1_A",
																				subjectId:
																					"currentStep",
																				dataType:
																					"basic",
																				value: 2,
																			},
																		],
																},
															],
														},
													},
													{
														type: "reducer_function",
														confirmation: {
															conditionOperator:
																"and",
															errorMessage:
																"Atleast one objective should be present",
															showErrorMessage: true,
															conditions: [
																{
																	comparison:
																		"equals",
																	source: "reducer",
																	sourceId:
																		"createStrategyChangeFlag",
																	checkValue: false,
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"maxStepCount",
																	checkValue: 0,
																},
															],
														},
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"currentStep",
																dataType:
																	"basic",
																value: 2,
															},
														],
													},
												],
											},
										],
									},
									{
										type: "button",
										pathSrc: "components",
										componentPath: "ui/button/Button",
										staticProps: {
											customStyle: {
												marginRight: "10px",
											},
											variant: "secondary",
											label: "Back",
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														confirmation:
														{
															conditions: [
																	{
																		comparison: "equals",
																		source: "reducer",
																		sourceId: "unsavedChangeFlag",
																		checkValue: true,
																	},
															],
														},
														responseFormatter: [
															{
																destination: "reducer",
																dataKey: "goBackOnConfirm",
																value: true
															}
														]
													},
													{
														type: "reducer_function",
														confirmation: {
															conditions: [
																{
																	comparison: "notEquals",
																	source: "reducer",
																	sourceId: "unsavedChangeFlag",
																	checkValue: true
																}
															]
														},
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"currentStep",
																dataType:
																	"basic",
																value: 0,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
					],
				},
			},
			// Panel
			{
				type: "panel",
				staticProps: {
					size: "medium",
					primaryButton: true,
				},
				dataProps: {
					isOpen: {
						type: "derived",
						dataKey: "addObjectivePanel",
					},
				},
				componentProps: {
					content: [
						{
							type: "text",
							staticProps: {
								content: "Objectives",
								variant: "h3",
							},
						},
						{
							type: "div",
							staticProps: {
								className: "box-container",
							},
							componentProps: {
								content: [
									{
										type: "text",
										staticProps: {
											content: "Revenue",
											variant: "h3",
										},
									},
									{
										type: "text",
										staticProps: {
											content:
												"Money retailer wants to realise from the sales over the promotion period",
										},
									},
									{
										id: "revenue-add-btn",
										type: "button",
										staticProps: {
											display: {
												type: "controlled",
												default: {
													show: true,
													enabled: true,
												},
											},
											label: "Add",
											variant: "primary",
											customWrapperStyle: {
												width: "74px",
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue-remove-btn",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue-add-btn",
																value: {
																	show: false,
																	enabled: false,
																},
															},
														],
													},
												],
											},
										],
									},
									{
										id: "revenue-remove-btn",
										type: "button",
										staticProps: {
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: false,
												},
											},
											label: "Remove",
											variant: "secondary",
											customWrapperStyle: {
												width: "108px",
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"step_2_objectives",
																subjectId:
																	"revenue",
																subjectIdNested:
																	"objective_value",
																value: null,
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue",
																value: {
																	show: false,
																	enabled: false,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue-remove-btn",
																value: {
																	show: false,
																	enabled: false,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"revenue-add-btn",
																value: {
																	show: true,
																	enabled: true,
																},
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
						{
							type: "div",
							staticProps: {
								className: "box-container",
							},
							componentProps: {
								content: [
									{
										type: "text",
										staticProps: {
											content: "GM%",
											variant: "h3",
										},
									},
									{
										type: "text",
										staticProps: {
											content:
												"GM% is the minimum GM% the retailer wants to maintain during the promotion period.",
										},
									},
									{
										id: "gm-add-btn",
										type: "button",
										staticProps: {
											display: {
												type: "controlled",
												default: {
													show: true,
													enabled: true,
												},
											},
											label: "Add",
											variant: "primary",
											customWrapperStyle: {
												width: "74px",
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-percent",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-remove-btn",
																value: {
																	show: true,
																	enabled: true,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-add-btn",
																value: {
																	show: false,
																	enabled: false,
																},
															},
														],
													},
												],
											},
										],
									},
									{
										id: "gm-remove-btn",
										type: "button",
										staticProps: {
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: false,
												},
											},
											label: "Remove",
											variant: "secondary",
											customWrapperStyle: {
												width: "108px",
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"step_2_objectives",
																subjectId:
																	"gm_percent",
																subjectIdNested:
																	"objective_value",
																value: null,
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-percent",
																value: {
																	show: false,
																	enabled: false,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-remove-btn",
																value: {
																	show: false,
																	enabled: false,
																},
															},
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"gm-add-btn",
																value: {
																	show: true,
																	enabled: true,
																},
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
					],
				},
				functionProps: [
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "addObjectivePanel",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "addObjectivePanel",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			// PO/DC Overlay
			{
				type: "fullScreenOverlay",
				componentProps: {
					content: [
						{
							id: "mdObjective_po_dc_inventory_overlay",
							type: "div",
							staticProps: {
								className:
									"flex-container justify-space-between",
							},
							componentProps: {
								content: [
									{
										type: "div",
									},
									{
										id: "return-to-mdObjective-button",
										type: "button",
										staticProps: {
											icon: "return",
											iconClass: "",
											className: "",
											customStyle: {
												fontSize: "14px",
												margin: 0,
												fontWeight: 500,
												background: "transparent",
											},
											variant: "secondary",
											label: "Return to Create MD Strategy",
											iconType: "svg",
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"po_dc_inventory_table_overlay_flag",
																dataType:
																	"basic",
																value: false,
																overwrite: true,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
						{
							type: "aggrid",
							staticProps: {
								height: "400px",
								tableId:
									"mdObjective_po_dc_high_inventory_table",
								uniqueKey: "product_h5_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "Item",
											key: "product_h5_id",
											action: "",
											hidden: false,
											aggregate: null,
											sorting: false,
											filter: true,
											pinned: false,
											checkbox_selection: false,
											row_group: false,
											suppressMenu: false,
											minWidth: 130,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Store OH",
											key: "store_oh",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											minWidth: 120,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Store in Transit",
											key: "store_in_transit",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											minWidth: 160,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "DC OH",
											key: "dc_oh",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											minWidth: 120,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "DC on Order",
											key: "",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											minWidth: 160,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "% Store Inventory Contribution",
											key: "store_inv_contribution",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											minWidth: 150,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "% DC/PO Contribution",
											key: "po_dc_inv_contribution",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											minWidth: 150,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"mdObjectives_po_dc_inventory_table",
								},
							},
						},
					],
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "po_dc_inventory_table_overlay_flag",
					},
				},
			},
			// Modal
			{
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Warning!",
					modalContent:
						"You have unsaved changes. Are you sure you want to leave without saving?",
					primaryButtonText: "Proceed",
					secondaryButtonText: "Cancel",
					modalSize: "medium",
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_1_A",
										subjectId: "currentStep",
										dataType: "basic",
										value: 0,
									},
									{
										destination: "reducer",
										dataKey: "goBackOnConfirm",
										dataType: "basic",
										value: false,
									},
								],
							},
						],
					},
					{
						functionName: "onDeny",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "goBackOnConfirm",
										dataType: "basic",
										value: false,
									},
								],
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"goBackOnConfirm",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "goBackOnConfirm",
					},
				},
			},		
		],
	},
};

//Next button: onClick api_funtcion logic
// if (
//  step_2_objectives?.revenue ||
//  step_2_objectives?.sales_units ||
//  step_2_objectives?.gm_dollar ||
//  step_2_objectives?.gm_percent ||
//  step_2_objectives?.st_percent ||
//  createStrategyChangeFlag === true ||
//  (createStrategyChangeFlag === false && maxStepCount === 0)
// ) {
//  //make api call
// }
