import * as authReducer from "../../store/features/auth/auth";
import * as filterReducer from "../../store/features/filters/filters";
import * as globalReducer from "../../store/features/global/global";
import * as appReducer from "../../store/features/appReducer/appReducer";
import store from "../../store/index";

const reducers = {
	auth: authReducer,
	filters: filterReducer,
	global: globalReducer,
	appReducer: appReducer,
};

export const getStateFromRedux = (dataKey) => {
	// if (store.getState()?.app?.[dataKey] === undefined) {
	// 	createReducerState(dataKey);
	// }
	if (store) return store?.getState()?.app?.[dataKey];
};

export const getDispatchFromRedux = (dispatch, endpoint) => {
	return (data) => dispatch(appReducer[endpoint](data));
};

export const createReducerState = (dataKey) => {
	store.dispatch(appReducer.createReducerState(dataKey));
};
