import { rule_flexibility_type_id } from "../../constants/Constants";

export const rules_column_config = {
	column_headers: [
		{
			type: "static_column",
			mapping: "data",
			header: "Rule Name",
			key: "rule_name",
			action: "",
			hidden: false,
			sorting: false,
			filter: true,
			pinned: false,
			checkbox_selection: false,
			row_group: false,
			// rowDrag: true,
			rowDrag: function (params) {
				if (params.data.priority > 0) {
				  return true;
				}
				return false;
			},
			is_edit: false,
			minWidth: 260,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Target",
			key: "target_value",
			hidden: false,
			sorting: false,
			filter: true,
			pinned: false,
			checkbox_selection: false,
			row_group: false,
			data_type: "number",
			cellRenderer: "customTextEditor",
			cellRendererFramework: "customTextEditor",
			is_edit: false,
			valueFormatter: "toDollar",
			suppressMenu: true,
			width: 150,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Min Values",
			key: "min_value",
			hidden: false,
			pinned: false,
			row_group: false,
			cellRenderer: "customInputTypeEditor",
			cellRendererFramework: "customInputTypeEditor", // Use the custom dropdown editor
			extra: {
				MultipleOf: 5,
				range: [1, 100],
				enableDropDown: true,
				checkContraintType: true,
				inputMinVal: 1,
				inputMaxVal: "max_allowed_weeks",
				condtionKey: "max_value",
			},
			data_type: "number",
			is_edit: function (params) {
				if (params.data.priority !== -1) {
				  return false;
				}
				return true;
			},
			suppressMenu: true,
			width: 130,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Max Values",
			key: "max_value",
			hidden: false,
			pinned: false,
			row_group: false,
			cellRenderer: "customInputTypeEditor",
			cellRendererFramework: "customInputTypeEditor",
			extra: {
				MultipleOf: 5,
				range: [1, 100],
				enableDropDown: true,
				checkContraintType: true,
				inputMinVal: 1,
				inputMaxVal: "max_allowed_weeks",
				condtionKey: "min_value",
			},
			data_type: "number",
			is_edit: function (params) {
				if (params.data.priority === -1) {
				  return false;
				}
				return true;
			},
			suppressMenu: true,
			width: 130,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Applicable values",
			key: "applicable_value",
			action: null,
			hidden: false,
			pinned: false,
			row_group: false,
			editable: false,
			cellRenderer: "cellWithIconAndLabel",
			cellRendererFramework: "cellWithIconAndLabel",
			is_edit: function (params) {
				if (params.data.priority !== -1) {
				  return false;
				}
				return true;
			},
			suppressMenu: true,
			width: 180,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Type",
			key: "rule_flexibility_type_id",
			action: ["dropdown"],
			hidden: false,
			pinned: false,
			row_group: false,
			cellRenderer: "customDropdownEditor",
			cellRendererFramework: "customDropdownEditor",
			extra: {
				dropdownOptions: rule_flexibility_type_id,
				enableDropDown: true,
				conditions: [
					{
						subjectId: "enable_flexibility_type",
						value: true,
					},
				],
			},
			data_type: "number",
			is_edit: false,
			suppressMenu: true,
			width: 150,
		},
		{
			type: "static_column",
			mapping: "data",
			header: "Priority",
			data_type: "number",
			key: "priority",
			action: null,
			hidden: false,
			pinned: false,
			row_group: false,
			suppressMenu: true,
			width: 120,
		},
		{
			type: "action",
			mapping: "data",
			header: "Action",
			key: "action",
			hidden: false,
			pinned: false,
			row_group: false,
			// action_items: ["edit", "disable"],
			action_items: function (params) {
				if (params.data.priority > 0) {
				  return ["disable"];
				}
				return ["edit", "disable"];
			},
			apiID: 9012,
			icon: { edit: "edit" },
			cellRenderer: "iconRenderer",
			cellRendererFramework: "iconRenderer",
			suppressMenu: true,
			minWidth: 120,
			flex: 1,
		},
	],
};
