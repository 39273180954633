import moment from "moment";

export const STRATEGY_WORKBENCH = {
	screen_composition: {
		components: [
			{
				id: "strategy-workbench-filter",
				type: "filter",
				staticProps: {
					showPageHeader: true,
					// showFilterSelection: true,
					showSaveFilter: true,
					pageHeader: "Markdown Workbench",
					filterHeader: "Workbench Filters",
					showFilterButtons: true,
					filter_config: [
						{
							type: "product_h1",
							is_mandatory: true,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							filter_group_label: "Product Selection",
							hideSearch: false,
							onLoad: true,
							selected: "All",
						},
						{
							type: "product_h2",
							is_mandatory: true,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
							onLoad: true,
							selected: "All",
						},
						{
							type: "product_h3",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "product_h4",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "brand",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "store_h1",
							is_mandatory: true,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							filter_group_label: "Store Selection",
							hideSearch: false,
							onLoad: true,
							selected: "All",
						},
						{
							type: "store_h2",
							is_mandatory: true,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
							onLoad: true,
							selected: "All",
						},
						{
							type: "store_h3",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h4",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h5",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "dateRange",
							is_mandatory: true,
							filter_endpoint: "",
							filter_type: "dateRange",
							default_type: "past",
							filter_group: 3,
							filter_group_label: "Select Strategy and Date",
							use_default: false, // will take from constants config if set to true
							start_date: moment().subtract(1, "weeks"),
							end_date: moment().add(5, "weeks"),
						},
						{
							type: "strategy_status",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "model",
							filter_model: 3094,
							filter_type: "strategy_status",
							filter_group: 3,
							filter_group_label: "Select Strategy and Date",
							hideSearch: false,
							onLoad: true,
							selected: "Default",
						},
						{
							type: "strategy_id",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "model",
							filter_model: 3087,
							filter_type: "strategy_id",
							filter_group: 3,
							filter_group_label: "Select Strategy and Date",
							hideSearch: false,
						},
					],
					showFilterChips: true,
					requiredOnLoadFilters: [
						"product_h1",
						"product_h2",
						"store_h1",
						"store_h2",
						"strategy_status"
					],
				},
				functionProps: [
					{
						functionName: "onFilter",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 3000,
										dataType: "basic",
									},
									{
										source: "filters",
										dataType: "object",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "strategyWorkbenchTableData",
										dataType: "array",
										overwrite: true,
									},
									{
										destination: "reducer",
										dataKey:
											"strategyWorkbenchTableDataFiltered",
										dataType: "array",
										overwrite: true,
										filters: {
											params: [
												{
													filterInput: {
														source:
															"filters",
														subjectId:
															"strategy_status",
														fromFiltersData: true,
														subjectId1:
															"selectedItemsArray",
														dataType: "array",
														apiRequestKey: "view_by"
													},
													filterKeyInData: "status_id",
												},
											],
											filterOperator: "or", // and
										},
									},
								],
								onComplete: {
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: 
														"filters",
													subjectId: 
														"strategy_status",
													fromFiltersData: true,
													subjectId1: 
														"selectedItems",
													apiRequestKey: 
														"selected",
												},
												{
													source: 
														"filters",
													subjectId: 
														"strategy_status",
													fromFiltersData: true,
													subjectId1: 
														"selectedItemsArray",
													apiRequestKey: 
														"selected_filter",
												},
											],
											responseFormatter: [
												{
													destination:
														"reducer",
													apiResponseKey: 
														"selected",
													dataKey:
														"strategy_status_selected",
													dataType:
														"object",
													overwrite: true,
												},
												{
													destination:
														"reducer",
													apiResponseKey: 
														"selected",
													dataKey:
														"workbench_strategy_status_filter_selected",
													dataType:
														"object",
													overwrite: true,
												},
												{
													destination:
														"reducer",
													apiResponseKey: 
														"selected_filter",
													dataKey:
														"workbench_strategy_status_filter",
													dataType:
														"object",
													overwrite: true,
												},
											],
										},
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
												{
													comparison: "exists",
													source: "reducer",
													sourceId:
														"strategyWorkbenchTableDataFiltered",
												},
												{
													type: "validation",
													conditions: [
														{
															comparison:
																"exists",
															source: "response",
														},
														{
															comparison:
																"exists",
															source: "reducer",
															sourceId:
																"strategyWorkbenchTableData",
														},
													],
													conditionOperator: "and",
													onValidationSuccess: {
														actions: [
															{
																type: "reducer_function",
																responseFormatter:
																	[
																		{
																			destination:
																				"reducer",
																			dataKey:
																				"componentDisplayControls",
																			subjectId:
																				"strategy-workbench-status-filter-container",
																			dataType:
																				"object",
																			value: {
																				show: true,
																			},
																			overwrite: false,
																		},
																	],
															},
														],
													},
												},
											],
											conditionOperator: "and",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"strategy-workbench-download-button",
																dataType:
																	"object",
																value: {
																	show: true,
																},
																overwrite: false,
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"strategy-workbench-status-filter-container",
																dataType:
																	"object",
																value: {
																	show: true,
																},
																overwrite: false,
															},
														],
													},
												],
											},
										},
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
												{
													comparison: "exists",
													source: "reducer",
													sourceId:
														"strategyWorkbenchTableData",
												},
											],
											conditionOperator: "and",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"workbench_strategy_status_filter_selected",
																value: [],
																dataType:
																	"array",
																overwrite: true,
															},
														],
													},
													{
														type: "reducer_function",
														params: [
															{
																source:
																	"filters",
																subjectId:
																	"strategy_status",
																fromFiltersData: true,
																subjectId1:
																	"selectedItems",
																apiRequestKey: "selected" 
															}
														],
														responseFormatter:
															[
																{
																	destination:
																		"reducer",
																	dataKey:
																		"workbench_strategy_status_filter_selected",
																	apiResponseKey: 
																		"selected",
																	dataType:
																		"array",
																	overwrite: true,
																},
															],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"workbench_strategy_status_filter",
																value: null,
																dataType:
																	"array",
																overwrite: true,
															},
														],
													},
												],
											},
										},
									],
								},
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 3006,
										dataType: "basic",
									},
									{
										source: "filters",
										dataType: "object",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"strategyWorkbenchAggregateMetrics",
										dataType: "array",
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"workbench-aggregate-metrics",
																dataType:
																	"object",
																value: {
																	show: true,
																},
																overwrite: false,
															},
														],
													},
												],
											},
										},
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filterModalOpen",
													value: false,
												},
											],
										},
									],
								},
							},
						],
					},
				],
				dataProps: {
					filterModalOpen: {
						type: "derived",
						dataKey: "filterModalOpen",
					},
				},
			},
			{
				type: "div",
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_2_objectives_copy",
										dataType: "basic",
										value: null,
										overwrite: true,
									},
								],
							},
							{
								type: "reducer_function",
								confirmation: {
									conditions: [
										{
											comparison: "notExists",
											source: "reducer",
											sourceId:
												"strategyWorkbenchAggregateMetrics",
										},
									],
								},
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "componentDisplayControls",
										subjectId:
											"workbench-aggregate-metrics",
										dataType: "object",
										value: {
											show: false,
										},
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			{
				type: "carousel",
				id: "workbench-aggregate-metrics",
				staticProps: {
					className: "common-content-container",
					customStyle: {
						margin: "20px",
						boxShadow: "0px 0px 6px #00000029",
						padding: "15px 10px",
						border: "1px solid #C8CED7",
						borderRadius: "3px",
					},
					display: {
						type: "controlled",
						default: {
							show: false,
						},
					},
				},
				componentProps: {
					content: [
						{
							type: "card",
							staticProps: {
								itemIcon: "published_with_changes",
								mainHeading: "Sales Units",
								customStyle: {
									margin: "2px 10px",
									width: "310px",
									height: "110px",
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"strategyWorkbenchAggregateMetrics",
									subjectId: "sales_units",
								},
							},
						},
						{
							type: "card",
							staticProps: {
								itemIcon: "emoji_events",
								mainHeading: "Revenue $",
								customStyle: {
									margin: "2px 10px",
									width: "310px",
									height: "110px",
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"strategyWorkbenchAggregateMetrics",
									subjectId: "revenue",
								},
							},
						},
						{
							type: "card",
							staticProps: {
								itemIcon: "track_changes",
								mainHeading: "Margin $",
								customStyle: {
									margin: "2px 10px",
									width: "310px",
									height: "110px",
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"strategyWorkbenchAggregateMetrics",
									subjectId: "margin",
								},
							},
						},
						{
							type: "card",
							staticProps: {
								itemIcon: "emoji_events",
								mainHeading: "Markdown $",
								customStyle: {
									margin: "2px 10px",
									width: "310px",
									height: "110px",
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"strategyWorkbenchAggregateMetrics",
									subjectId: "markdown_dollar",
								},
							},
						},
						{
							type: "card",
							staticProps: {
								itemIcon: "emoji_events",
								mainHeading: "ST %",
								customStyle: {
									margin: "2px 10px",
									width: "310px",
									height: "110px",
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"strategyWorkbenchAggregateMetrics",
									subjectId: "sell_through",
								},
							},
						},
					],
				},
			},
			{
				type: "div",
				id: "workbench-metrics-table",
				staticProps: {
					className: "common-content-container",
					display: {
						type: "controlled",
						default: {
							show: true,
						},
					},
				},
				componentProps: {
					content: [
						{
							id: "strategy-workbench-table",
							type: "aggrid",
							staticProps: {
								height: "400px",
								tableId: "strategy_workbench_table",
								rowSelection: "multiple",
								showQuickFilter: true,
								selectAll: false,
								tableTitle: "All Markdown Strategy",
								uniqueKey: "strategy_id",
								hideHeaderCheckbox: true,
								updateParent: true,
								getRowStyle: (params) => {
									return params?.data?.is_child
										? {
												background: "#F7F7F7",
										  }
										: {};
								},
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "",
											key: "",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: false,
											filter: false,
											pinned: true,
											lockPinned: true,
											checkbox_selection: true,
											row_group: false,
											suppressMenu: true,
											maxWidth: 50,
											cellStyle: {
												background: "#f7f7f7",
											},
										},
										{
											type: "static_column",
											mapping: "data",
											header: "MD Strategy Name",
											key: "strategy_name",
											action: "",
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: true,
											checkbox_selection: false,
											suppressRowTransform: true,
											row_group: false,
											minWidth: 320,
											cellStyle: {
												background: "#f7f7f7",
											},
											// cellRenderer:"customLoaderWorkbench",
											cellRenderer:
												"customLoaderWorkbench",
											cellRendererParams: {
												actions: [
													{
														type: "api_function",
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 3088,
																dataType:
																	"basic",
															},
															{
																source: "self",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"strategy_id",
																subjectId:
																	"strategy_id",
																dataType:
																	"selfArray",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"notExists",
																				source: "response",
																			},
																		],
																	conditionOperator:
																		"or",
																	onValidationSuccess:
																		{
																			actions:
																				[
																					{
																						type: "reducer_function",
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"step4ScreenMount",
																									value: false,
																									overwrite: true,
																								},
																							],
																					},
																					{
																						type: "reducer_function",
																						params: [
																							{
																								source: "self",
																								// sourceId:
																								// 	"read_only",
																								apiRequestKey:
																									"read_only",
																								subjectId:
																									"read_only",
																								dataType:
																									"basic",
																							},
																						],
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"editReadOnlyFlag",
																									apiResponseKey:
																										"read_only",
																									overwrite: true,
																									dataType:
																										"basic",
																								},
																							],
																					},
																					{
																						type: "reducer_function",
																						params: [
																							{
																								source: "self",
																								subjectId:
																									"strategy_id",
																								apiRequestKey:
																									"selectedStrategy",
																								dataType:
																									"basic",
																							},
																						],
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"step_1_A",
																									apiResponseKey:
																										"selectedStrategy",
																									subjectId:
																										"strategy_id",
																									dataType:
																										"basic",
																								},
																							],
																						onComplete:
																							{
																								actions:
																									[
																										{
																											type: "reducer_function",
																											params: [
																												{
																													source: "self",
																													subjectId:
																														"step_count",
																													apiRequestKey:
																														"selectedStrategy",
																													dataType:
																														"basic",
																												},
																											],
																											responseFormatter:
																												[
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"step_1_A",
																														subjectId:
																															"step_count",
																														apiResponseKey:
																															"step_count",
																														overwrite: false,
																														dataType:
																															"object",
																													},
																												],
																										},
																										{
																											type: "redirect",
																											link: "workbench/create-strategy",
																										},
																									],
																							},
																					},
																				],
																		},
																},
															],
														},
													},
												],
											},
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Status",
											key: "status",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: true,
											row_group: false,
											cellStyle: {
												background: "#f7f7f7",
											},
											// valueFormatter:
											// 	"strategyStatusFormatter",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Created On",
											key: "created_at",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Start Date",
											key: "start_date",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "End Date",
											key: "end_date",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Products",
											key: "products",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Store",
											key: "stores",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Recommendation Level-Products",
											key: "product_recommendation_level",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											width: 320,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Recommendation Level-Stores",
											key: "store_recommendation_level",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											width: 320,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "ST Target",
											key: "sell_through_target",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Margin Target",
											key: "gm_dollar_target",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Units Target",
											key: "sales_units_target",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Revenue Target",
											key: "revenue_target",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "ST Priority",
											key: "sell_through_priority",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Margin Priority",
											key: "gm_dollar_priority",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Units Priority",
											key: "sales_units_priority",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Revenue Priority",
											key: "revenue_priority",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Sales Units",
											key: "sales_units",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Revenue $",
											key: "revenue",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Margin $",
											key: "margin",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Markdown $",
											key: "markdown_spend",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											comparator: "number",
										},
									],
								},
							},
							functionProps: [
								{
									functionName: "onCellValueChanged",
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: "self",
													apiRequestKey:
														"strategyWorkbenchTableDataFiltered",
													dataType: "array",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"strategyWorkbenchTableDataFiltered",
													apiResponseKey:
														"strategyWorkbenchTableDataFiltered",
													overwrite: true,
													// dataType: "array",
												},
											],
										},
									],
								},
							],
							dataProps: {
								data: {
									type: "derived",
									dataKey:
										"strategyWorkbenchTableDataFiltered",
								},
							},
							// componentProps: {},
							dependantControls: [
								{
									action: "rowSelection",
									controls: [
										{
											id: "strategy-workbench-edit-button",
											selection: "single",
											switchKey: "show",
											value: true,
										},
										{
											id: "strategy-workbench-delete-button",
											selection: "multiple",
											switchKey: "show",
											value: true,
										},
										{
											id: "strategy-workbench-copy-button",
											selection: "single",
											switchKey: "show",
											value: true,
										},
										{
											id: "strategy-workbench-download-button",
											selection: "none-and-data-exists",
											switchKey: "show",
											value: true,
										},
										{
											id: "strategy-workbench-create-button",
											selection: "none",
											switchKey: "show",
											value: true,
										},
										{
											id: "strategy-workbench-pause-button",
											selection: "multiple",
											switchKey: "show",
											value: true,
										},
										{
											id: "strategy-workbench-show-previous-button",
											selection: "single",
											switchKey: "show",
											value: true,
										},
									],
								},
							],
							componentProps: {
								content: [
									{
										alignment: "right",
										type: "div",
										id: "strategy-workbench-status-filter-container",
										staticProps: {
											className: "flex-container",
											display: {
												type: "controlled",
												default: {
													show: false,
												},
											},
										},
										componentProps: {
											content: [
												{
													isDefaultExport: false,
													type: "text",
													staticProps: {
														className:
															"common-page-title",
														variant: "h4",
														content: "View By",
														customStyle: {
															color: "#758490",
															fontSize: "12px",
															fontWeight: "100",
															padding: "0 10px",
														},
													},
												},
												{
													type: "select",
													staticProps: {
														customStyle: {
															width: "200px",
														},
														isMultipleSelection: true,
														hideClearSelection: true,
														hideSearch: true,
													},
													functionProps: [
														{
															functionName:
																"onSelect",
															actions: [
																{
																	type: "reducer_function",
																	params: [
																		{
																			source: "self",
																			apiRequestKey:
																				"selectedData",
																			dataType:
																				"basic",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"workbench_strategy_status_filter",
																				apiResponseKey:
																					"selectedData",
																				dataType:
																					"array",
																				overwrite: true,
																			},
																		],
																},
																{
																	type: "reducer_function",
																	params: [
																		{
																			source: "reducer",
																			sourceId:
																				"strategyWorkbenchTableData",
																			dataType:
																				"array",
																			apiRequestKey:
																				"tableData",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"strategyWorkbenchTableDataFiltered",
																				dataType:
																					"array",
																				apiResponseKey:
																					"tableData",
																				overwrite: true,
																				filters:
																					{
																						params: [
																							{
																								filterInput:
																									{
																										source: "reducer",
																										dataType:
																											"basic",
																										sourceId:
																											"workbench_strategy_status_filter",
																										apiRequestKey:
																											"view_by",
																										fromFiltersData: true,
																									},
																								filterKeyInData:
																									"status_id",
																							},
																						],
																						filterOperator:
																							"or", // and
																					},
																			},
																		],
																},
															],
														},
													],
													dataProps: {
														selectedOptions: {
															type: "derived",
															dataKey:
																"workbench_strategy_status_filter_selected",
															dataType: "array",
														},
														options: {
															type: "derived",
															dataKey:
																"strategy_status_selected",
															dataType: "array",
														},
													},
												},
											],
										},
									},
									{
										alignment: "right",
										id: "strategy-workbench-create-button",
										type: "button",
										staticProps: {
											variant: "primary",
											label: "Create New MD Strategy",
											display: {
												type: "controlled",
												default: {
													show: true,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "redirect",
														link: "workbench/create-strategy",
													},
												],
											},
										],
									},
									// {
									// 	alignment: "right",
									// 	id: "strategy-workbench-pause-button",
									// 	type: "button",
									// 	staticProps: {
									// 		variant: "secondary",
									// 		label: "Pause",
									// 		display: {
									// 			type: "controlled",
									// 			default: {
									// 				show: false,
									// 				enabled: true,
									// 			},
									// 		},
									// 	},
									// 	functionProps: [
									// 		{
									// 			functionName: "onClick",
									// 			actionType: "api",
									// 			actionName: "pauseStrategy",
									// 		},
									// 	],
									// },
									// {
									// 	alignment: "right",
									// 	id: "strategy-workbench-show-previous-button",
									// 	type: "button",
									// 	staticProps: {
									// 		variant: "secondary",
									// 		label: "Show Previous Version",
									// 		display: {
									// 			type: "controlled",
									// 			default: {
									// 				show: false,
									// 				enabled: true,
									// 			},
									// 		},
									// 	},
									// 	functionProps: [
									// 		{
									// 			functionName: "onClick",
									// 			actionType: "function",
									// 			actionName:
									// 				"showPreviousVersion",
									// 		},
									// 	],
									// },
									{
										alignment: "right",
										id: "strategy-workbench-download-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											icon: "file_download",
											iconClass: "colorWhite",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "api_function",
														apiEndPoint:
															"report-download",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 1,
																isDownload: true,
																dataType:
																	"basic",
															},
															{
																source: "filters",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"report_name",
																value: "strategy_workbench_report",
															},
														],
														responseFormatter: [],
													},
												],
											},
										],
									},
									{
										alignment: "right",
										id: "strategy-workbench-delete-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											icon: "delete",
											iconClass: "colorWhite",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "api_function",
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 3109,
																dataType:
																	"basic",
															},
															{
																source: "table",
																sourceId:
																	"strategy_workbench_table",
																subjectId:
																	"strategy_id",
																dataType:
																	"array",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"strategy_ids",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"affected_strategies_if_deleted",
																overwrite: true,
															},
														],
														onComplete: {
															actions: [
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"exists",
																				source: "response",
																			},
																		],
																	conditionOperator:
																		"or",
																	onValidationSuccess:
																		{
																			actions:
																				[
																					{
																						type: "reducer_function",
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"showValidationModalForDelete",
																									dataType:
																										"basic",
																									value: true,
																									overwrite: true,
																								},
																							],
																					},
																				],
																		},
																},
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"notExists",
																				source: "response",
																			},
																		],
																	conditionOperator:
																		"or",
																	onValidationSuccess:
																		{
																			actions:
																				[
																					{
																						type: "api_function",
																						apiEndPoint:
																							"model",
																						apiMethod:
																							"post",
																						params: [
																							{
																								source: "fixed",
																								apiRequestKey:
																									"id",
																								value: 3108,
																								dataType:
																									"basic",
																							},
																							{
																								source: "table",
																								sourceId:
																									"strategy_workbench_table",
																								subjectId:
																									"strategy_id",
																								dataType:
																									"array",
																								apiRequestKey:
																									"parameters",
																								apiRequestKeyNested:
																									"strategy_ids",
																							},
																						],
																						onComplete:
																							{
																								actions:
																									[
																										{
																											type: "reducer_function",
																											responseFormatter:
																												[
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"tableData",
																														subjectId:
																															"strategy_workbench_table",
																														dataType:
																															"array",
																														value: [],
																														overwrite: true,
																													},
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"strategyWorkbenchTableData",
																														dataType:
																															"array",
																														value: [],
																														overwrite: true,
																													},
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"strategyWorkbenchTableDataFiltered",
																														dataType:
																															"array",
																														value: [],
																														overwrite: true,
																													},
																												],
																										},
																										{
																											type: "api_function",
																											apiEndPoint:
																												"model",
																											apiMethod:
																												"post",
																											params: [
																												{
																													source: "fixed",
																													apiRequestKey:
																														"id",
																													value: 3000,
																													dataType:
																														"basic",
																												},
																												{
																													source: "filters",
																													dataType:
																														"object",
																													apiRequestKey:
																														"parameters",
																												},
																											],
																											responseFormatter:
																												[
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"strategyWorkbenchTableData",
																														dataType:
																															"array",
																														overwrite: true,
																													},
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"strategyWorkbenchTableDataFiltered",
																														dataType:
																															"array",
																														overwrite: true,
																														filters:
																															{
																																params: [
																																	{
																																		filterInput:
																																		{
																																			source:
																																				"filters",
																																			subjectId:
																																				"strategy_status",
																																			subjectId1:
																																				"selectedItemsArray",
																																			dataType: "array",
																																			apiRequestKey: "view_by",
																																			fromFiltersData: true,
																																		},
																																		filterKeyInData:
																																			"status_id",
																																	},
																																],
																																filterOperator:
																																	"or", // and
																															},
																													},
																												],
																										},
																									],
																							},
																					},
																				],
																		},
																},
															],
														},
													},
												],
											},
										],
									},
									{
										alignment: "right",
										id: "strategy-workbench-copy-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											icon: "content_copy",
											iconClass: "colorWhite",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "api_function",
														apiEndPoint: "model",
														apiMethod: "post",
														confirmation: {
															type: "check",
															showErrorMessage: true,
															conditions: [
																{
																	comparison:
																		"equals",
																	source: "reducer",
																	sourceId:
																		"tableData",
																	subjectId:
																		"strategy_workbench_table",
																	subjectId1:
																		"0",
																	subjectId2:
																		"is_child",
																	dataType:
																		"basic",
																	checkValue: false,
																	message:
																		"Child strategies cannot be copied",
																},
																{
																	comparison:
																		"greaterthan",
																	source: "reducer",
																	sourceId:
																		"tableData",
																	subjectId:
																		"strategy_workbench_table",
																	subjectId1:
																		"0",
																	subjectId2:
																		"start_date",
																	data_type:
																		"date",
																	checkValue:
																		null,
																	message:
																		"Only future strategies can be copied",
																},
															],
															conditionOperator:
																"and",
														},
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 3105,
																dataType:
																	"basic",
															},
															{
																source: "table",
																sourceId:
																	"strategy_workbench_table",
																subjectId:
																	"strategy_id",
																dataType:
																	"basic",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"strategy_id",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"exists",
																				source: "response",
																			},
																		],
																	conditionOperator:
																		"or",
																	onValidationSuccess:
																		{
																			actions:
																				[
																					{
																						type: "reducer_function",
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"tableData",
																									subjectId:
																										"strategy_workbench_table",
																									dataType:
																										"array",
																									value: [],
																									overwrite: true,
																								},
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"strategyWorkbenchTableData",
																									dataType:
																										"array",
																									value: [],
																									overwrite: true,
																								},
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"strategyWorkbenchTableDataFiltered",
																									dataType:
																										"array",
																									value: [],
																									overwrite: true,
																								},
																							],
																					},
																					{
																						type: "reducer_function",
																						params: [
																							{
																								source:
																									"filters",
																								subjectId:
																									"strategy_status",
																								fromFiltersData: true,
																								subjectId1:
																									"selectedItems",
																								apiRequestKey: "selected" 
																							}
																						],
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"workbench_strategy_status_filter_selected",
																									apiResponseKey: 
																										"selected",
																									dataType:
																										"array",
																									overwrite: true,
																								},
																							],
																					},
																					{
																						type: "api_function",
																						apiEndPoint:
																							"model",
																						apiMethod:
																							"post",
																						params: [
																							{
																								source: "fixed",
																								apiRequestKey:
																									"id",
																								value: 3000,
																								dataType:
																									"basic",
																							},
																							{
																								source: "filters",
																								dataType:
																									"object",
																								apiRequestKey:
																									"parameters",
																							},
																						],
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"strategyWorkbenchTableData",
																									dataType:
																										"array",
																									overwrite: true,
																								},
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"strategyWorkbenchTableDataFiltered",
																									dataType:
																										"array",
																									overwrite: true,
																									filters:
																										{
																											params: [
																												{
																													filterInput:
																													{
																														source:
																															"filters",
																														subjectId:
																															"strategy_status",
																														subjectId1:
																															"selectedItemsArray",
																														dataType: "array",
																														apiRequestKey: "view_by",
																														fromFiltersData: true,
																														},
																													filterKeyInData:
																														"status_id",
																												},
																											],
																											filterOperator:
																												"or", // and
																										},
																								},
																							],
																					},
																				],
																		},
																},
															],
														},
													},
												],
											},
										],
									},
									{
										alignment: "right",
										id: "strategy-workbench-edit-button",
										type: "iconButton",
										staticProps: {
											variant: "primary",
											icon: "edit",
											iconClass: "colorWhite",
											className: "icon-button",
											display: {
												type: "controlled",
												default: {
													show: false,
													enabled: true,
												},
											},
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step4ScreenMount",
																value: false,
																overwrite: true,
															},
														],
													},
													{
														type: "api_function",
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 3088,
																dataType:
																	"basic",
															},
															{
																source: "table",
																sourceId:
																	"strategy_workbench_table",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"strategy_id",
																subjectId:
																	"strategy_id",
																dataType:
																	"array",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "validation",
																	conditions:
																		[
																			{
																				comparison:
																					"notExists",
																				source: "response",
																			},
																		],
																	conditionOperator:
																		"or",
																	onValidationSuccess:
																		{
																			actions:
																				[
																					{
																						type: "reducer_function",
																						params: [
																							{
																								source: "table",
																								sourceId:
																									"strategy_workbench_table",
																								apiRequestKey:
																									"read_only",
																								dataType:
																									"basic",
																							},
																						],
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"editReadOnlyFlag",
																									apiResponseKey:
																										"read_only",
																									overwrite: true,
																									dataType:
																										"basic",
																								},
																							],
																					},
																					{
																						type: "reducer_function",
																						params: [
																							{
																								source: "table",
																								sourceId:
																									"strategy_workbench_table",
																								apiRequestKey:
																									"strategy_id",
																								dataType:
																									"basic",
																							},
																						],
																						responseFormatter:
																							[
																								{
																									destination:
																										"reducer",
																									dataKey:
																										"step_1_A",
																									// dataKey:
																									// 	"strategy_id",
																									// apiResponseKey:
																									// 	"strategy_id",
																									overwrite: true,
																									dataType:
																										"object",
																								},
																							],
																						onComplete:
																							{
																								actions:
																									[
																										{
																											type: "reducer_function",
																											params: [
																												{
																													source: "table",
																													sourceId:
																														"strategy_workbench_table",
																													subjectId:
																														"step_count",
																													apiRequestKey:
																														"step_count",
																													dataType:
																														"basic",
																												},
																											],
																											responseFormatter:
																												[
																													{
																														destination:
																															"reducer",
																														dataKey:
																															"step_1_A",
																														subjectId:
																															"step_count",
																														apiResponseKey:
																															"step_count",
																														overwrite: false,
																														dataType:
																															"object",
																													},
																												],
																										},
																										{
																											type: "redirect",
																											link: "workbench/create-strategy",
																										},
																									],
																							},
																					},
																				],
																		},
																},
															],
														},
													},
												],
											},
										],
									},
								],
							},
						},
					],
				},
			},
			//  Modal
			{
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Delete Strategy",
					primaryButtonText: "Proceed with Deleting",
					secondaryButtonText: "Cancel",
					modalSize: "large",
				},
				componentProps: {
					content: [
						{
							id: "",
							isDefaultExport: false,
							type: "text",
							staticProps: {
								className: "",
								variant: "paragraph",
								content:
									"Based on the data feed received, these are the affected strategies with this deletion",
							},
						},
						{
							id: "strategy_delete_validation",
							type: "aggrid",
							staticProps: {
								height: "300px",
								tableId: "strategy_delete_validation",
								tableTitle: "",
								uniqueKey: "strategy_id",
								tableConfig: {
									column_headers: [
										{
											type: "static_column",
											mapping: "data",
											header: "Strategy",
											key: "strategy_id",
											action: null,
											hidden: true,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 1,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Strategy Name",
											key: "strategy_name",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 1,
										},
										{
											type: "static_column",
											mapping: "data",
											header: "Action",
											key: "action",
											action: null,
											hidden: false,
											aggregate: null,
											sorting: true,
											filter: true,
											pinned: false,
											row_group: false,
											flex: 2,
										},
									],
								},
							},
							dataProps: {
								data: {
									type: "derived",
									dataKey: "affected_strategies_if_deleted",
									dataType: "array",
								},
							},
							componentProps: {},
						},
					],
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 3108,
										dataType: "basic",
									},
									{
										source: "table",
										sourceId: "strategy_workbench_table",
										subjectId: "strategy_id",
										dataType: "array",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "strategy_ids",
									},
								],
								onComplete: {
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "tableData",
													subjectId:
														"strategy_workbench_table",
													dataType: "array",
													value: [],
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey:
														"strategyWorkbenchTableData",
													dataType: "array",
													value: [],
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey:
														"strategyWorkbenchTableDataFiltered",
													dataType: "array",
													value: [],
													overwrite: true,
												},
											],
										},
										{
											type: "reducer_function",
											params: [
												{
													source:
														"filters",
													subjectId:
														"strategy_status",
													fromFiltersData: true,
													subjectId1:
														"selectedItems",
													apiRequestKey: "selected" 
												}
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"workbench_strategy_status_filter_selected",
													apiResponseKey:
														"selected",
													dataType: "array",
													overwrite: true,
												},
											],
										},
										{
											type: "api_function",
											apiEndPoint: "model",
											apiMethod: "post",
											params: [
												{
													source: "fixed",
													apiRequestKey: "id",
													value: 3000,
													dataType: "basic",
												},
												{
													source: "filters",
													dataType: "object",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"strategyWorkbenchTableData",
													dataType: "array",
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey:
														"strategyWorkbenchTableDataFiltered",
													dataType: "array",
													overwrite: true,
													filters: {
														params: [
															{
																filterInput: {
																	source:
																		"filters",
																	subjectId:
																		"strategy_status",
																	subjectId1:
																		"selectedItemsArray",
																	dataType: "array",
																	apiRequestKey: "view_by",
																	fromFiltersData: true,
																},
																filterKeyInData:
																	"status_id",
															},
														],
														filterOperator: "or", // and
													},
												},
											],
										},
									],
								},
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showValidationModalForDelete",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onDeny",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"affected_strategies_if_deleted",
										overwrite: true,
										dataType: "array",
										value: null,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showValidationModalForDelete",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showValidationModalForDelete",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "showValidationModalForDelete",
					},
				},
			},
		],
	},
	on_load_functions: [
		{
			type: "reducer_function",
			responseFormatter: [
				{
					destination: "reducer",
					dataKey: "step_1_A",
					value: null,
				},
			],
		},
		{
			type: "reducer_function",
			responseFormatter: [
				{
					destination: "reducer",
					dataKey: "step_1_A_copy",
					value: null,
				},
			],
		},
	],
};
