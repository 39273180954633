import { rules_column_config } from "./../../../components/createStrategy/demoJsons.js";

export const Rules = {
	screen_composition: {
		components: [
			{
				type: "strategySummary",
				staticProps: {
					extraContent: [
						{
							type: "div",
							staticProps: {
								customStyle: {
									paddingLeft: "20px",
								},
							},
							componentProps: {
								content: [
									{
										type: "div",
										staticProps: {
											className: "flex-container",
											customStyle: {
												background: "#0055af",
												padding: "20px",
												borderRadius: "3px",
												marginBottom: "10px",
												justifyContent: "space-between",
											},
										},
										componentProps: {
											content: [
												{
													type: "div",
													componentProps: {
														content: [
															{
																type: "text",
																staticProps: {
																	className:
																		"common-page-title",
																	variant:
																		"h5",
																	content:
																		"Store Selected",
																	customStyle:
																	{
																		color: "white",
																	},
																},
															},
															{
																type: "div",
																staticProps: {
																	className:
																		"flex-container",
																},
																componentProps:
																{
																	content:
																		[
																			{
																				type: "text",
																				staticProps:
																				{
																					className:
																						"common-page-title",
																					variant:
																						"h4",
																					customStyle:
																					{
																						color: "white",
																					},
																				},
																				dataProps:
																				{
																					dynamicContent:
																					{
																						type: "derived",
																						dataKey:
																							"step_3_chart",
																						textContent:
																							[
																								{
																									content:
																										"selected_stores_count",
																									type: "dynamic",
																									className:
																										"bold",
																								},
																								{
																									content:
																										"/",
																									type: "static",
																								},
																								{
																									content:
																										"total_stores_count",
																									type: "dynamic",
																									className:
																										"bold",
																								},
																							],
																					},
																				},
																			},
																		],
																},
															},
														],
													},
												},
												{
													type: "icon",
													staticProps: {
														icon: "store",
														customStyle: {
															fontSize: "35px",
															marginLeft: "20px",
														},
													},
												},
											],
										},
									},
									{
										type: "div",
										staticProps: {
											className: "flex-container",
											customStyle: {
												background: "#0055af",
												padding: "20px",
												borderRadius: "3px",
												justifyContent: "space-between",
											},
										},
										componentProps: {
											content: [
												{
													type: "div",
													componentProps: {
														content: [
															{
																type: "text",
																staticProps: {
																	className:
																		"common-page-title",
																	variant:
																		"h5",
																	content:
																		"Last Week Discount",
																	customStyle:
																	{
																		color: "white",
																	},
																},
															},
															{
																type: "text",
																staticProps: {
																	className:
																		"common-page-title",
																	variant:
																		"h4",
																	customStyle:
																	{
																		color: "white",
																	},
																},
																dataProps: {
																	dynamicContent:
																	{
																		type: "derived",
																		dataKey:
																			"step_3_chart",
																		textContent:
																			[
																				{
																					content:
																						"lw_recommended_offer_percentage",
																					type: "dynamic",
																					className:
																						"bold",
																					formatter:
																						"toPercentage",
																				},
																			],
																	},
																},
															},
														],
													},
												},
												{
													type: "icon",
													staticProps: {
														icon: "calendar_month",
														customStyle: {
															fontSize: "35px",
															marginLeft: "20px",
														},
													},
												},
											],
										},
									},
								],
							},
						},
					],
				},
				dataProps: {
					data: {
						type: "derived",
						dataKey: "step_3_chart",
					},
				},
			},
			{
				type: "div",
				staticProps: {
					className: "stepper-container",
					customStyle: {
						alignItems: "center",
						paddingTop: "10px",
					},
				},
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3003,
									},
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "parameters",
										apiRequestKeyNested:
											"include_store_lw_metrics",
										value: true,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										// sourceId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_3_chart",
										dataType: "object",
										apiRequestKey: "data",
									},
								],
								onComplete: {
									actions: [
										// fetch product and store reccomendation level
										{
											type: "api_function",
											runOnLoad: true,
											apiEndPoint: "model",
											apiMethod: "post",
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3001,
												},
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "parameters",
													value: "objective",
													apiRequestKeyNested:
														"section",
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"product_recommendation_level",
													apiResponseKey:
														"product_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"store_recommendation_level",
													apiResponseKey:
														"store_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"filtersForPayloadStep4",
													subjectId: "product_level",
													apiResponseKey:
														"product_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"filtersForPayloadStep4",
													subjectId: "store_level",
													apiResponseKey:
														"store_recommendation_level",
												},
												{
													destination: "reducer",
													dataKey:
														"product_recommendation_label",
													apiResponseKey:
														"product_recommendation_level_name",
												},
												{
													destination: "reducer",
													dataKey:
														"store_recommendation_label",
													apiResponseKey:
														"store_recommendation_level_name",
												},
												{
													destination: "reducer",
													dataKey:
														"selected_filters_for_step4",
													subjectId: "Product",
													apiResponseKey:
														"product_recommendation_level_name",
													subjectIdNested: "data",
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey:
														"selected_filters_for_step4",
													subjectId: "Store",
													subjectIdNested: "data",
													apiResponseKey:
														"store_recommendation_level_name",
													dataType: "object",
												},
												// {
												// 	type: "reducer_function",
												// 	responseFormatter: [
												// 		{
												// 			destination:
												// 				"reducer",
												// 			dataKey:
												// 				"show_chips_for_ia_filter",
												// 			overwrite: true,
												// 			value: true,
												// 		},
												// 	],
												// },
											],
										},
									],
								},
							},

							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "stepper_breadCrumb",
										value: "Create MD strategy / Select Rules",
										dataType: "object",
									},
								],
							},

							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "parameters",
										value: "rule",
										apiRequestKeyNested: "section",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_3_strategy_data",
										dataType: "object",
										overwrite: true,
									},
									{
										destination: "reducer",
										dataKey: "maxStepCount",
										apiResponseKey: "step_count",
										dataType: "object",
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataType: "object",
										dataKey: "enable_next_for_optimization",
										value: false,
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "rulesValidationFlag",
										value: true,
										overwrite: true,
									},
								],
							},
							// Setting unsavedchange flag to false
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "unsavedChangeFlag",
										value: false,
									},
								],
							},
						],
					},
				],

				componentProps: {
					content: [
						{
							type: "div",
							staticProps: {
								className: "flex-container",
							},
							componentProps: {
								content: [
									{
										type: "text",
										staticProps: {
											className: "common-page-title",
											variant: "h5",
											content:
												"Please add the rules that are applicable for this MD Strategy.",
											customStyle: {
												padding: "0px !important",
												fontSize: "14px",
												marginBottom: "0",
											},
										},
									},
									// {
									// 	id: "add-applicable-rules-button",
									// 	type: "button",
									// 	staticProps: {
									// 		className: "common-page-title",
									// 		customStyle: {
									// 			padding: "0px !important",
									// 			fontSize: "14px",
									// 			marginBottom: "0",
									// 		},
									// 		variant: "url",
									// 		label: "Add applicable rules",
									// 	},
									// 	functionProps: [
									// 		{
									// 			functionName: "onClick",
									// 			actions: [
									// 				{
									// 					type: "reducer_function",
									// 					params: [
									// 						{
									// 							source: "reducer",
									// 							sourceId:
									// 								"step_1_A",
									// 							subjectId:
									// 								"strategy_id",
									// 							apiRequestKey:
									// 								"strategy_id",
									// 						},
									// 					],
									// 					responseFormatter: [
									// 						{
									// 							destination:
									// 								"reducer",
									// 							dataKey:
									// 								"rule_strategy_id_temperory",
									// 							apiResponseKey:
									// 								"strategy_id",
									// 							dataType:
									// 								"basic",
									// 							overwrite: true,
									// 						},
									// 						{
									// 							source: "fixed",
									// 							dataKey:
									// 								"is_rule_strategy_id_temperory",
									// 							value: true,
									// 							overwrite: true,
									// 						},
									// 					],

									// 					// onComplete: {
									// 					// 	actions: [
									// 					// 		{
									// 					// 			type: "redirect",
									// 					// 			link: "pricing-calendar-configuration",
									// 					// 		},
									// 					// 	],
									// 					// },
									// 				},

									// 				{
									// 					type: "redirect",
									// 					link: "rules",
									// 				},
									// 			],
									// 		},
									// 	],
									// },
								],
							},
						},
						{
							type: "text",
							staticProps: {
								className: "disclaimer",
								variant: "h5",
								content:
									"Drag the rules in the order of priority you need",
								customStyle: {
									marginBottom: "0",
									fontSize: "14px",
								},
							},
						},
					],
				},
			},
			// rules table
			{
				id: "strategy_rules",
				type: "aggrid",
				rowSelection: "single",
				staticProps: {
					height: "450px",
					borderBottom: "1px solid #EBECF0",
					paddingBottom: "20px",
					disableDomLayout: true,
					rowDraggable: true,
					tableId: "strategy_rules",
					uniqueKey: "row_num",
					tableConfig: { ...rules_column_config },
					className: "rules_strategy",
					stopEditingWhenCellsLoseFocus: true,
					rowStyleClass: "rowstyle_rules_strategy",
					enableDisableRowFunc: true,
					orderKey: "priority",
					sortByKeys: ["priority", "row_num"],
					disabled_key: "is_disabled",
					domLayout: true,
					updateParent: true,
				},
				dataProps: {
					data: {
						type: "derived",
						dataKey: "step_3_strategy_data",
						subjectId: "strategy_rules",
					},
				},
				functionProps: [
					{
						functionName: "onCellValueChanged",
						actions: [
							{
								type: "reducer_function",
								params: [
									{
										source: "self",
										apiRequestKey: "strategy_rules",
										dataType: "array",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_3_strategy_data",
										sourceId: "strategy_rules",
										dataType: "array",
									},
									{
										destination: "reducer",
										dataKey: "changeFlagForRules",
										value: true,
									},
									{
										destination: "reducer",
										dataKey: "unsavedChangeFlag",
										value: true,
									},
									{
										destination: "reducer",
										dataKey: "rulesValidationFlag",
										value: true,
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			// CTA buttons
			{
				type: "div",
				pathSrc: "components",
				componentPath: "ui/wrapperDiv/WrapperDiv",
				staticProps: {
					className: "flex-container",
					customStyle: {
						marginTop: "25px",
					},
				},
				componentProps: {
					content: [
						// Next button
						{
							type: "button",
							pathSrc: "components",
							componentPath: "ui/button/Button",
							id: "rules_modal_next",
							staticProps: {
								customStyle: {
									marginRight: "10px",
								},
								variant: "primary",
								label: "Next",
								display: {
									type: "controlled",
									default: {
										show: true,
										enabled: true,
									},
								},
							},
							functionProps: [
								// on click of next
								{
									functionName: "onClick",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"rulesValidationFlag",
													value: true,
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey: "step4ScreenMount",
													value: false,
													overwrite: true,
												},
											],
										},
										// This is for step count is one or if any rules are edited, trigger save api
										{
											type: "api_function",
											apiEndPoint: "model",
											apiMethod: "post",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "multiple",
														conditionOperator: "or",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"changeFlagForRules",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_3_strategy_data",
																subjectId:
																	"step_count",
																checkValue: 1,
															},
														],
													},
													{
														comparison:
															"rulesValidation",
													},
												],
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 3103,
												},
												{
													source: "reducer",
													sourceId:
														"step_3_strategy_data",
													subjectId: "strategy_rules",
													value: "strategy_rules",
													dataType: "object",
													apiRequestKeyNested:
														"rules",
													apiRequestKey: "parameters",
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "step_1_A",
													subjectId: "strategy_id",
													apiResponseKey:
														"strategy_id",
													dataType: "object",
												},
												{
													destination: "reducer",
													dataKey: "maxStepCount",
													apiResponseKey:
														"step_count",
													dataType: "object",
												},
											],
											onComplete: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"showOptimizationModal",
																value: true,
															},
														],
													},
													{
														type: "validation",
														conditions: [
															{
																comparison:
																	"exists",
																source: "response",
																sourceId:
																	"strategy_id",
															},
														],
														conditionOperator: "or",
														onValidationSuccess: {
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"changeFlagForRules",
																				dataType:
																					"basic",
																				value: false,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
										// show modal
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "multiple",
														conditionOperator: "or",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"step_3_strategy_data",
																subjectId:
																	"step_count",
																checkValue: 2,
															},
															// {
															// 	comparison:
															// 		"notEquals",
															// 	source: "reducer",
															// 	sourceId:
															// 		"editReadOnlyFlag",
															// 	checkValue: true,
															// },
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"and",
																conditions: [
																	{
																		comparison:
																			"exists",
																		source: "reducer",
																		sourceId:
																			"editReadOnlyFlag",
																	},
																	{
																		comparison:
																			"notEquals",
																		source: "reducer",
																		sourceId:
																			"editReadOnlyFlag",
																		checkValue: true,
																	},
																],
															},
														],
													},
													{
														comparison:
															"rulesValidation",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showOptimizationModal",
													value: true,
												},
											],
										},
										// redirect from stepo 3 to step 4
										{
											type: "reducer_function",
											confirmation: {
												type: "check",
												conditionOperator: "and",
												conditions: [
													{
														comparison: "equals",
														source: "reducer",
														sourceId:
															"step_3_strategy_data",
														subjectId: "step_count",
														checkValue: 3,
													},
													{
														comparison: "notEquals",
														source: "reducer",
														sourceId:
															"changeFlagForRules",
														checkValue: true,
													},
													{
														comparison:
															"rulesValidation",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "step_1_A",
													subjectId: "currentStep",
													dataType: "basic",
													value: 3,
												},
											],
										},
									],
								},
								// on click of back
								{
									functionName: "onDeny",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"showOptimizationModal",
													value: false,
												},
												// {
												// 	source: "fixed",
												// 	dataKey:
												// 		"rules_optimization_modal_details",
												// 	value: null,
												// 	overwrite: true,
												// },
											],
										},
									],
								},
							],
						},
						// Back button
						{
							type: "button",
							pathSrc: "components",
							componentPath: "ui/button/Button",
							staticProps: {
								variant: "secondary",
								label: "Back",
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "reducer_function",
											confirmation: {
												conditions: [
													{
														comparison: "equals",
														source: "reducer",
														sourceId:
															"unsavedChangeFlag",
														checkValue: true,
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "goBackOnConfirm",
													value: true,
												},
											],
										},
										{
											type: "reducer_function",
											confirmation: {
												conditions: [
													{
														comparison: "notEquals",
														source: "reducer",
														sourceId:
															"unsavedChangeFlag",
														checkValue: true,
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "step_1_A",
													subjectId: "currentStep",
													dataType: "basic",
													value: 1,
												},
											],
										},
									],
								},
							],
						},
					],
				},
			},
			//Optimization modal
			{
				id: "rules_optimization_modal",
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Select Optimization type",
					primaryButtonText: "Next",
					secondaryButtonText: "Cancel",
					modalSize: "medium",
				},
				componentProps: {
					content: [
						{
							type: "div",
							pathSrc: "components",
							componentPath: "ui/wrapperDiv/WrapperDiv",
							staticProps: {
								customStyle: {
									display: "flex",
									flexDirection: "column",
									height: "100%",
								},
							},
							componentProps: {
								content: [
									{
										type: "text",
										pathSrc: "components",
										componentPath:
											"ui/typography/Typography",
										staticProps: {
											className: "common-page-title",
											variant: "h4",
											content: "Optimisation Type",
											customStyle: {
												padding: "0px !important",
												float: "left",
											},
										},
									},
									{
										type: "form",
										inputType: "dataInput",
										id: "rules_optimization_modal_details",
										pathSrc: "components",
										componentPath: "ui/form/Form",
										direction: "column",
										staticProps: {
											fields: [
												{
													type: "radio",
													id: 0,
													placeholder:
														"IA_optimization",
													variant: "outlined",
													isMandatory: true,
													label: "Impact Analytics Optimised (Recommended)",
													radioGroupId:
														"optimisation_type",
												},
												{
													inputType: "dataInput",
													type: "radio",
													id: 1,
													placeholder: "Description",
													variant: "outlined",
													isMandatory: true,
													label: "Partial Optimisation",
													radioGroupId:
														"optimisation_type",
												},
												{
													inputType: "dataInput",
													type: "radio",
													id: 2,
													placeholder: "Description",
													variant: "outlined",
													isMandatory: true,
													label: "No Optimisation",
													radioGroupId:
														"optimisation_type",
												},
											],
											customStyle: {
												display: "flex",
												flexDirection: "column",
												flexWrap: "no-wrap",
											},
										},
										functionProps: [
											{
												functionName: "onRadioClick",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataType:
																	"object",
																dataKey:
																	"enable_next_for_optimization",
																value: true,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						},
					],
				},
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "showOptimizationModal",
					},
					primaryButtonEnabled: {
						type: "derived",
						dataKey: "enable_next_for_optimization",
					},
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									type: "check",
									showErrorMessage: true,
									conditions: [
										{
											comparison: "exists",
											source: "reducer",
											sourceId:
												"rules_optimization_modal_details",
											subjectId: "optimisation_type",
											dataType: "object",
											message:
												"Please select an optimization type",
										},
									],
									conditionOperator: "and",
								},
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3009,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "reducer",
										sourceId:
											"rules_optimization_modal_details",
										subjectId: "optimisation_type",
										dataType: "object",
										apiRequestKeyNested:
											"optimisation_type",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_1_A",
										subjectId: "strategy_id",
										apiResponseKey: "strategy_id",
										dataType: "object",
									},
									{
										destination: "reducer",
										dataKey: "maxStepCount",
										apiResponseKey: "step_count",
										dataType: "object",
									},
								],
								onComplete: {
									actions: [
										// redirect to step 4 if partial or no optimiazation condiiotn is matched
										{
											type: "validation",
											conditions: [
												{
													comparison: "equals",
													source: "reducer",
													sourceId:
														"rules_optimization_modal_details",
													subjectId:
														"optimisation_type",
													value: 1,
												},
												{
													comparison: "equals",
													source: "reducer",
													sourceId:
														"rules_optimization_modal_details",
													subjectId:
														"optimisation_type",
													value: 2,
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"showOptimizationModal",
																value: false,
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"currentStep",
																dataType:
																	"basic",
																value: 3,
															},
														],
													},
													// reset the optimization_type
													{
														type: "reducer_function",
														responseFormatter: [
															{
																source: "fixed",
																dataKey:
																	"rules_optimization_modal_details",
																value: null,
																overwrite: true,
															},
														],
													},
												],
											},
										},
										//call the optimize api and redirect to workbench screen if optimization type is IA-Reco
										{
											type: "validation",
											conditions: [
												{
													comparison: "equals",
													source: "reducer",
													sourceId:
														"rules_optimization_modal_details",
													subjectId:
														"optimisation_type",
													value: 0,
												},
											],
											conditionOperator: "and",
											onValidationSuccess: {
												actions: [
													{
														type: "api_function",
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																apiRequestKey:
																	"id",
																value: 3014,
																dataType:
																	"basic",
															},
															{
																source: "fixed",
																sourceId:
																	"ia_reco_updated_data",
																apiRequestKeyNested:
																	"discount_values",
																apiRequestKey:
																	"parameters",
																dataType:
																	"array",
																value: [],
															},
															{
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																// sourceId: "strategy_id",
																apiRequestKeyNested:
																	"strategy_id",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "reducer",
																sourceId:
																	"step_3_chart",
																subjectId:
																	"strategy_name",
																apiRequestKeyNested:
																	"strategy_name",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "fixed",
																apiRequestKeyNested:
																	"optimisation_type",
																apiRequestKey:
																	"parameters",
																value: 0,
															},
															{
																source: "fixed",
																apiRequestKeyNested:
																	"step_count",
																apiRequestKey:
																	"parameters",
																value: 2,
															},
															{
																source: "session_storage",
																sourceId:
																	"UNIQ_SSE_KEY",
																apiRequestKeyNested:
																	"guid",
																apiRequestKey:
																	"parameters",
															},
														],
														onComplete: {
															actions: [
																{
																	type: "api_function",
																	runOnLoad: true,
																	apiEndPoint:
																		"model",
																	apiMethod:
																		"post",
																	params: [
																		{
																			source: "fixed",
																			dataType:
																				"object",
																			apiRequestKey:
																				"id",
																			value: 3111,
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"step_1_A",
																			subjectId:
																				"strategy_id",
																			apiRequestKeyNested:
																				"strategy_id",
																			apiRequestKey:
																				"parameters",
																		},
																		{
																			source: "fixed",
																			dataType:
																				"object",
																			value: "optimize",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"action_type",
																		},
																		{
																			source: "session_storage",
																			sourceId:
																				"UNIQ_SSE_KEY",
																			apiRequestKeyNested:
																				"guid",
																			apiRequestKey:
																				"parameters",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"waiting_screen_data",
																				dataType:
																					"object",
																			},
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showOptimizeWaitingGIF",
																				value: true,
																			},
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"step_1_A",
																				subjectId:
																					"currentStep",
																				dataType:
																					"basic",
																				value: 3,
																			},
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"step4_waiting_footerText",
																				value: "Don't worry. You'll get notification once optimisation is completed.",
																			},
																		],
																},
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"showOptimizationModal",
																				value: false,
																				overwrite: true,
																			},
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"step3IAOptimization",
																				value: true,
																				overwrite: true,
																			},
																		],
																},
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				source: "fixed",
																				dataKey:
																					"rules_optimization_modal_details",
																				value: null,
																				overwrite: true,
																			},
																		],
																},
															],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "showOptimizationModal",
										value: false,
										overwrite: true,
									},
									{
										source: "fixed",
										dataKey: "enable_next_for_optimization",
										value: null,
									},
									{
										source: "fixed",
										dataKey:
											"rules_optimization_modal_details",
										value: null,
										overwrite: true,
									},
								],
							},
							{
								type: "api_function",
								runOnLoad: true,
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 3001,
									},
									{
										source: "reducer",
										sourceId: "step_1_A",
										subjectId: "strategy_id",
										apiRequestKeyNested: "strategy_id",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										dataType: "object",
										value: "rule",
										apiRequestKeyNested: "section",
										apiRequestKey: "parameters",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_3_strategy_data",
										dataType: "object",
										overwrite: true,
									},
								],
							},
						],
					},
				],
			},
			// Rule Edit Panel
			{
				type: "div",
				staticProps: {
					customStyle: {
						zIndex: 2,
						position: "absolute",
					},
				},
				dataProps: {
					is_visible: {
						type: "derived",
						dataKey: "editRuleForStrategyPanel",
					},
				},

				componentProps: {
					content: [
						{
							type: "panel",
							staticProps: {
								size: "large",
								primaryButton: true,
								primaryButtonText: "Update Rule",
							},
							dataProps: {
								isOpen: {
									type: "derived",
									dataKey: "editRuleForStrategyPanel",
								},
							},
							componentProps: {
								content: [
									{
										type: "text",
										staticProps: {
											content: "Edit Rule for Strategy",
											variant: "h3",
										},
									},
									{
										type: "div",
										staticProps: {
											customStyle: {
												alignItems: "end",
												display: "flex",
											},
										},
										componentProps: {
											content: [
												{
													type: "form",
													id: "rule_det",
													staticProps: {
														// customStyle: {
														// 	width: "300px"
														// },
														inputType: "dataInput",
														label: "Select Product Level",
														fields: [
															{
																type: "select",
																id: "rule_product_level",
																isMulti: false,
																placeholder:
																	"select",
																variant:
																	"outlined",
																isMandatory: true,
																label: "Select Product Level",
																showLabel: true,
																hideSearch: true,
															},
														],
													},
													dataProps: {
														options: {
															type: "derived",
															dataKey:
																"strategy_rules_selection_options",
														},
													},
												},
												{
													type: "form",
													id: "rule_det",
													staticProps: {
														// customStyle: {
														// 	width: "300px"
														// },
														inputType: "dataInput",
														label: "Select Store Level",
														fields: [
															{
																type: "select",
																id: "rule_store_level",
																isMulti: false,
																placeholder:
																	"select",
																variant:
																	"outlined",
																isMandatory: true,
																label: "Select Store Level",
																showLabel: true,
																hideSearch: true,
															},
														],
													},
													dataProps: {
														options: {
															type: "derived",
															dataKey:
																"strategy_rules_selection_options",
														},
													},
												},
											],
										},
									},
									{
										type: "div",
										staticProps: {
											customStyle: {
												// height: "90px",
												display: "flex",
											},
										},
										componentProps: {
											content: [
												{
													type: "button",
													staticProps: {
														variant: "primary",
														label: "Submit",
													},
													functionProps: [
														{
															functionName:
																"onClick",
															actions: [
																{
																	type: "api_function",
																	apiEndPoint:
																		"model",
																	apiMethod:
																		"post",
																	confirmation:
																	{
																		type: "check",
																		showErrorMessage: true,
																		conditions:
																			[
																				{
																					comparison:
																						"exists",
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					dataType:
																						"basic",
																				},
																				{
																					comparison:
																						"exists",
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					dataType:
																						"basic",
																				},
																			],
																		conditionOperator:
																			"and",
																	},
																	params: [
																		{
																			source: "fixed",
																			apiRequestKey:
																				"id",
																			value: 9006,
																			dataType:
																				"basic",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"rule_det",
																			subjectId:
																				"rule_id",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"rule_id",
																			dataType:
																				"basic",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"rule_det",
																			subjectId:
																				"rule_type",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"rule_type",
																			dataType:
																				"basic",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"rule_det",
																			subjectId:
																				"rule_product_level",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"rule_product_level",
																			dataType:
																				"basic",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"rule_det",
																			subjectId:
																				"rule_store_level",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"rule_store_level",
																			dataType:
																				"basic",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"rule_det",
																				subjectId:
																					"table_data",
																				dataType:
																					"basic",
																			},
																		],
																},
																// Call to get rules col_config
																{
																	type: "api_function",
																	apiEndPoint:
																		"model",
																	apiMethod:
																		"post",
																	confirmation:
																	{
																		type: "check",
																		showErrorMessage: true,
																		conditionOperator:
																			"and",
																		conditions:
																			[
																				{
																					comparison:
																						"exists",
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					dataType:
																						"basic",
																					message:
																						"Select a Product Level",
																				},
																				{
																					comparison:
																						"exists",
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					dataType:
																						"basic",
																					message:
																						"Select a Store Level",
																				},
																			],
																	},
																	params: [
																		{
																			source: "fixed",
																			apiRequestKey:
																				"id",
																			value: 9005,
																			dataType:
																				"basic",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"rule_det",
																			subjectId:
																				"rule_type",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"rule_type",
																			dataType:
																				"basic",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"rule_det",
																			subjectId:
																				"rule_product_level",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"rule_product_level",
																			dataType:
																				"basic",
																		},
																		{
																			source: "reducer",
																			sourceId:
																				"rule_det",
																			subjectId:
																				"rule_store_level",
																			apiRequestKey:
																				"parameters",
																			apiRequestKeyNested:
																				"rule_store_level",
																			dataType:
																				"basic",
																		},
																	],
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"rule_det",
																				subjectId:
																					"column_headers",
																				dataType:
																					"basic",
																			},
																		],
																},
																// Make no_updated_rows as true, since reco_lvl change the whole table is to be passed that is
																// Depended on this "no_updated_rows" key
																{
																	type: "reducer_function",
																	confirmation:
																	{
																		conditions:
																			[
																				{
																					comparison:
																						"notEquals",
																					source: "reducer",
																					sourceId:
																						"rule_det_copy",
																					subjectId:
																						"rule_store_level",
																					checkValue:
																					{
																						source: "reducer",
																						sourceId:
																							"rule_det",
																						subjectId:
																							"rule_store_level",
																					},
																				},
																				{
																					comparison:
																						"notEquals",
																					source: "reducer",
																					sourceId:
																						"rule_det_copy",
																					subjectId:
																						"rule_product_level",
																					checkValue:
																					{
																						source: "reducer",
																						sourceId:
																							"rule_det",
																						subjectId:
																							"rule_product_level",
																					},
																				},
																			],
																	},
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"no_updated_rows",
																				value: true,
																			},
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"rule_det",
																				subjectId:
																					"no_updateds_on_table_data",
																				value: false,
																			},
																		],
																},
																{
																	type: "reducer_function",
																	params: [
																		{
																			source: "reducer",
																			sourceId: "rule_det",
																			subjectId: "rule_store_level",
																			apiRequestKey: "rule_store_level"
																		},
																		{
																			source: "reducer",
																			sourceId: "rule_det",
																			subjectId: "rule_product_level",
																			apiRequestKey: "rule_product_level"
																		}
																	],
																	responseFormatter: [
																		{
																			destination: "reducer",
																			dataKey: "rule_store_level",
																			apiResponseKey: "rule_store_level"
																		},
																		{
																			destination: "reducer",
																			dataKey: "rule_product_level",
																			apiResponseKey: "rule_product_level"
																		},
																	]
																},
															]
														}
													]
												},
												{
													type: "button",
													staticProps: {
														variant: "secondary",
														label: "Clear",
													},
													functionProps: [
														{
															functionName:
																"onClick",
															actions: [
																{
																	type: "reducer_function",
																	responseFormatter:
																		[
																			{
																				destination:
																					"reducer",
																				dataKey:
																					"strategy_rule_edit",
																				value: null,
																				dataType:
																					"basic",
																			},
																		],
																},
															],
														},
													],
												},
											],
										},
									},
									{
										id: "edit_strategy_rule_divider",
										type: "divider",
										staticProps: {
											margin: "20px 0px",
										},
									},
									{
										id: "edit_strategy_rule_table",
										type: "aggrid",
										staticProps: {
											height: "300px",
											tableId: "edit_strategy_rule_table",
											tableTitle: "",
											sizeColumnsToFit: true,
											uniqueKey: "row_id",
											rowSelection: "multiple",
											updateParent: true,
											sortByKeys: ["row_id"],
											domLayout: true,
										},
										functionProps: [
											{
												functionName:
													"onCellValueChanged",
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "self",
																apiRequestKey:
																	"table_data",
																dataType:
																	"array",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"rule_det",
																dataType:
																	"array",
															},
														],
													},
												],
											},
											{
												functionName: "onUpdateRow",
												actions: [
													{
														type: "reducer_function",
														confirmation: {
															conditionOperator:
																"and",
															conditions: [
																{
																	comparison:
																		"exists",
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_id",
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"no_updated_rows",
																	checkValue: true,
																},
															],
														},
														params: [
															{
																source: "self",
																apiRequestKey:
																	"table_data",
																dataType:
																	"array",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"rule_det",
																subjectId:
																	"updated_rows",
																apiResponseKey:
																	"table_data",
																dataType:
																	"array",
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"rule_det",
																subjectId:
																	"no_updateds_on_table_data",
																dataType:
																	"basic",
																value: false,
															},
														],
													},
													{
														type: "reducer_function",
														confirmation: {
															conditionOperator:
																"and",
															conditions: [
																{
																	comparison:
																		"exists",
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_id",
																},
																{
																	comparison:
																		"notEquals",
																	source: "reducer",
																	sourceId:
																		"no_updated_rows",
																	checkValue: true,
																},
															],
														},
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																dataType:
																	"basic",
																value: true,
															},
														],
													},
												],
											},
										],
										dataProps: {
											column: {
												type: "derived",
												dataKey: "rule_det",
												subjectId: "column_headers",
											},
											data: {
												type: "derived",
												dataKey: "rule_det",
												subjectId: "table_data",
											},
											updatedData: {
												type: "derived",
												dataKey: "rule_det",
												subjectId: "updated_rows",
											},
										},
										componentProps: {},
									},
								],
							},
							functionProps: [
								{
									functionName: "onLoad",
									actions: [
										{
											type: "api_function",
											runOnLoad: true,
											apiEndPoint: "model",
											apiMethod: "post",
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 9014,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"strategy_rules_selection_options",
													dataType: "object",
													apiRequestKey: "data",
												},
											],
										},
										{
											type: "api_function",
											apiEndPoint: "model",
											apiMethod: "post",
											confirmation: {
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"strategy_rule_id",
														// subjectId: "rule_id",
														checkValue: true,
													},
												],
											},
											params: [
												{
													source: "fixed",
													dataType: "object",
													apiRequestKey: "id",
													value: 9011,
												},
												{
													source: "reducer",
													sourceId:
														"strategy_rule_id",
													// subjectId: "rule_id",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"rule_id",
													dataType: "basic",
												},
											],
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "rule_det",
													dataType: "object",
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey: "rule_det_copy",
													dataType: "basic",
												},
												{
													destination: "reducer",
													dataKey:
														"rule_product_level",
													apiResponseKey:
														"rule_product_level",
												},
												{
													destination: "reducer",
													dataKey: "rule_store_level",
													apiResponseKey:
														"rule_store_level",
												},
											],
										},
									],
								},
								{
									functionName: "onClose",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "rule_det",
													subjectId: "table_data",
													value: null,
												},
												{
													destination: "reducer",
													dataKey: "rule_det",
													subjectId:
														"rule_store_level",
													value: null,
												},
												{
													destination: "reducer",
													dataKey: "rule_det",
													subjectId:
														"rule_product_level",
													value: null,
												},
												{
													destination: "reducer",
													dataKey: "rule_det",
													subjectId: "column_headers",
													value: null,
												},
												{
													destination: "reducer",
													dataKey: "no_updated_rows",
													value: true,
												},
												{
													destination: "reducer",
													dataKey: "rule_det",
													subjectId:
														"no_updateds_on_table_data",
													value: false,
												},
												{
													destination: "reducer",
													dataKey:
														"editRuleForStrategyPanel",
													dataType: "basic",
													value: false,
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey: "rule_store_level",
													value: null,
												},
												{
													destination: "reducer",
													dataKey:
														"rule_product_level",
													value: null,
												},
											],
										},
									],
								},
								{
									functionName: "onConfirm",
									actions: [
										// if updated flag is true
										{
											type: "api_function",
											apiEndPoint: "model",
											apiMethod: "post",
											confirmation: {
												type: "check",
												showErrorMessage: true,
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "rule_det",
														subjectId: "rule_id",
														dataType: "basic",
													},
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "rule_det",
														subjectId:
															"rule_product_level",
														dataType: "basic",
														message:
															"Select a Product Level",
													},
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "rule_det",
														subjectId:
															"rule_store_level",
														dataType: "basic",
														message:
															"Select a Store Level",
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId: "rule_det",
														subjectId:
															"updated_rows_flag",
														checkValue: true,
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId:
															"rule_store_level",
														checkValue: {
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"rule_store_level",
														},
														message:
															"Reco Level Changed, Click on Submit To Continue With Selected Reco Level",
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId:
															"rule_product_level",
														checkValue: {
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"rule_product_level",
														},
														message:
															"Reco Level Changed, Click on Submit To Continue With Selected Reco Level",
													},
												],
												conditionOperator: "and",
											},
											params: [
												{
													source: "fixed",
													apiRequestKey: "id",
													value: 9015,
													dataType: "basic",
												},
												{
													source: "reducer",
													sourceId: "rule_det",
													apiRequestKey: "parameters",
													dataType: "basic",
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [],
											onComplete: {
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																apiRequestKey:
																	"strategy_id",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																apiResponseKey:
																	"strategy_id",
																overwrite: true,
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"currentStep",
																value: 2,
																overwrite: false,
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"editRuleForStrategyPanel",
																dataType:
																	"basic",
																value: false,
																overwrite: true,
															},
														],
													},
													{
														type: "redirect",
														link: "workbench/create-strategy",
													},
												],
											},
										},
										// if updated flag is false
										{
											type: "api_function",
											apiEndPoint: "model",
											apiMethod: "post",
											confirmation: {
												type: "check",
												showErrorMessage: true,
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "rule_det",
														subjectId: "rule_id",
														dataType: "basic",
													},
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "rule_det",
														subjectId:
															"rule_product_level",
														dataType: "basic",
														message:
															"Select a Product Level",
													},
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "rule_det",
														subjectId:
															"rule_store_level",
														dataType: "basic",
														message:
															"Select a Store Level",
													},
													{
														comparison: "notEquals",
														source: "reducer",
														sourceId: "rule_det",
														subjectId:
															"updated_rows_flag",
														checkValue: true,
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId:
															"rule_store_level",
														checkValue: {
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"rule_store_level",
														},
														message:
															"Reco Level Changed, Click on Submit To Continue With Selected Reco Level",
													},
													{
														comparison: "equals",
														source: "reducer",
														sourceId:
															"rule_product_level",
														checkValue: {
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"rule_product_level",
														},
														message:
															"Reco Level Changed, Click on Submit To Continue With Selected Reco Level",
													},
												],
												conditionOperator: "and",
											},
											params: [
												{
													source: "fixed",
													apiRequestKey: "id",
													value: 9015,
													dataType: "basic",
												},
												{
													source: "reducer",
													sourceId: "rule_det",
													apiRequestKey: "parameters",
													dataType: "basic",
												},
												{
													source: "reducer",
													sourceId: "rule_det",
													subjectId: "table_data",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"rule_values",
													dataType: "array",
												},
												{
													source: "fixed",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"table_data",
													value: null,
												},
												{
													source: "fixed",
													apiRequestKey: "parameters",
													apiRequestKeyNested:
														"updated_rows",
													value: null,
												},
												{
													source: "reducer",
													sourceId: "step_1_A",
													subjectId: "strategy_id",
													// sourceId: "strategy_id",
													apiRequestKeyNested:
														"strategy_id",
													apiRequestKey: "parameters",
												},
											],
											responseFormatter: [],
											onComplete: {
												actions: [
													{
														type: "reducer_function",
														params: [
															{
																source: "reducer",
																sourceId:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																apiRequestKey:
																	"strategy_id",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"strategy_id",
																apiResponseKey:
																	"strategy_id",
																overwrite: true,
															},
															{
																destination:
																	"reducer",
																dataKey:
																	"step_1_A",
																subjectId:
																	"currentStep",
																value: 2,
																overwrite: false,
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"editRuleForStrategyPanel",
																dataType:
																	"basic",
																value: false,
																overwrite: true,
															},
														],
													},
													{
														type: "redirect",
														link: "workbench/create-strategy",
													},
												],
											},
										},
									],
								},
							],
						},
					],
				},
			},
			// Modal
			{
				type: "modal",
				staticProps: {
					className: "",
					modalTitle: "Warning!",
					modalContent:
						"You have unsaved changes. Are you sure you want to leave without saving?",
					primaryButtonText: "Proceed",
					secondaryButtonText: "Cancel",
					modalSize: "medium",
				},
				functionProps: [
					{
						functionName: "onConfirm",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "step_1_A",
										subjectId: "currentStep",
										dataType: "basic",
										value: 1,
									},
									{
										destination: "reducer",
										dataKey: "goBackOnConfirm",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
									{
										destination: "reducer",
										dataKey: "unsavedChangeFlag",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onDeny",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "goBackOnConfirm",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
					{
						functionName: "onClose",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "goBackOnConfirm",
										dataType: "basic",
										value: false,
										overwrite: true,
									},
								],
							},
						],
					},
				],
				dataProps: {
					showModal: {
						type: "derived",
						dataKey: "goBackOnConfirm",
					},
				},
			},
		],
	},
};
