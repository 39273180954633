import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";
import _ from "lodash";
import { Drawer, Tooltip, ListItem, List, styled } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import logo from "../../../assets/images/iaLogoFull.svg";
import logo_no_text from "../../../assets/images/logo_no_text.svg";
import { signOut } from "../../../store/features/auth/auth";
import "./SideBar.scss";

const sidebarIcon = (itemIcon, itemTitle) => {
	return <span class="material-symbols-outlined">{itemIcon}</span>;
};

const StyledListItem = styled(ListItem)({
	"&:hover": {
		color: "white",
		backgroundcolor: "grey",
	},
	"&.Mui-selected": {
		color: "white",
		backgroundColor: "rgb(189, 189, 189, 0.3)",
	},
});

const iconStyle = {
	color: "#fff",
	fontSize: 28,
	position: "relative",
	display: "flex",
};

const SideBar = ({ options, pathPrefix, match, location, theme, ...props }) => {
	const wrapperRef = useRef();
	const locationParams = useLocation();
	const [isActive, setisActive] = useState(false);
	const [selectedIndex, setSelectedIndex] = React.useState(
		options.findIndex(
			(option) =>
				option.path === `/${window.location.pathname.split("/")[1]}`
		)
	);

	const toggleSideBarExpansionHandler = (expand) => {
		setisActive((isActive) => !isActive);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick, false);
		return () => {
			document.removeEventListener("mousedown", handleClick, false);
		};
	}, []);

	useEffect(() => {
		let updatedMenuItem = options?.find((option) =>
			option.path.includes(
				`/${window.location.pathname.split("/")[
				window.location.pathname.split("/").length - 1
				]
				}`
			)
		);
		let updatedIndex = 0;
		if (updatedMenuItem?.parent) {
			updatedIndex = options.findIndex(
				(op) => updatedMenuItem.parent === op.key
			);
		} else {
			updatedIndex = options.findIndex(
				(option) =>
					option.path === `/${window.location.pathname.split("/")[1]}`
			);
		}
		setSelectedIndex(updatedIndex);
	}, [options, locationParams]);

	const handleClick = (e) => {
		if (wrapperRef.current.contains(e.target)) {
			return;
		}
		setisActive(false);
	};

	const handleListItemClick = (event, index, hasChildren) => {
		if (!hasChildren) {
			setSelectedIndex(index);
			setisActive(false);
		} else {
			toggleSideBarExpansionHandler();
		}
	};

	const handleMojo = (event) => {
		window.open(
			"https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/70759",
			"_blank"
		);
	};

	const getMenuItem = (item, i, childItems = []) => {
		// If current path is one of child items paths
		const childLinks = childItems?.map((child) => child.path);
		let isParentActive = false;
		childLinks?.forEach((childLink) => {
			if (window.location.pathname.includes(childLink)) {
				isParentActive = true;
			}
		});
		return (
			<>
				<StyledListItem
					id={"sidebar-item-" + i}
					className={`menuItem ${item.hasChildren ? "no-click" : ""}`}
					button
					key={item.title}
					selected={selectedIndex === i}
					onClick={(event) =>
						handleListItemClick(event, i, item.hasChildren)
					}
				>
					<NavLink
						to={!item.hasChildren ? pathPrefix + item.path : ""}
						className={({ isActive, isPending, isTransitioning }) =>
							[
								isPending ? "pending" : "",
								isActive && isParentActive ? " active" : "",
								isTransitioning ? "transitioning" : "",
							].join(" sidebarMenu")
						}
						id={item.title}
					>
						<span className="listitemSidebar">
							<Tooltip title={item.title}>
								{item.icon && (
									<span>
										{sidebarIcon(item.icon, item.title)}
									</span>
								)}
							</Tooltip>
							<span className="sidebar-list-label">
								{item.title}
							</span>
						</span>
					</NavLink>
				</StyledListItem>
				{!_.isEmpty(childItems) && (
					<ul className="sidebar-submenu">
						{childItems.map((childItem, j) => {
							return getMenuItem(childItem, j);
						})}
					</ul>
				)}
			</>
		);
	};

	return (
		<Drawer
			variant="permanent"
			ref={wrapperRef}
			className={`navbar-drawer drawer ${isActive ? "drawerOpen" : "drawerClose"
				}`}
			classes={{
				paper: `${isActive ? "drawerOpen" : "drawerClose"}`,
			}}
		>
			<div className={`logo ${isActive ? "drawerOpen" : "drawerClose"}`}>
				{!isActive && (
					<img
						src={logo_no_text}
						className="logoImage"
						alt="PriceSmart Logo"
					/>
				)}
				{isActive && (
					<img
						src={logo}
						className="logoImage"
						alt="PriceSmart Logo"
					/>
				)}
			</div>
			<div className="sidebarMiddle">
				<List className="menuItems">
					{options.map((item, i) => {
						if (!item.parent) {
							const childItems = item.hasChildren
								? options.filter((op) => op.parent === item.key)
								: [];
							return getMenuItem(item, i, childItems);
						}
					})}
					<StyledListItem
						id={"sidebar-item-" + options.length}
						className="menuItem"
						button
						key={"Support"}
						selected={false}
						onClick={(event) => handleMojo(event)}
					>
						<span className="sidebarMenu" id={"Support"}>
							<span className="listitemSidebar">
								<Tooltip title={"Support"}>
									<span>
										{sidebarIcon(
											React.createElement(
												ContactSupportIcon
											),
											"Support"
										)}
									</span>
								</Tooltip>
								<span className="sidebar-list-label">
									Support
								</span>
							</span>
						</span>
					</StyledListItem>
				</List>
				<div
					className={
						isActive
							? "sidebarToggleIcon left"
							: "sidebarToggleIcon right"
					}
					onClick={() => toggleSideBarExpansionHandler()}
				>
					{!isActive && <ArrowForwardIcon sx={iconStyle} />}
					{isActive && <ArrowBackIcon sx={iconStyle} />}
				</div>

				<div className="sidebarFooter">
					<div onClick={() => props.signOut()} id="logout">
						<LogoutIcon />
					</div>
				</div>
			</div>
		</Drawer>
	);
};

SideBar.defaultProps = {
	options: [],
	pathPrefix: "",
	theme: {
		width: "var(--sidebar-width)",
		activeWidth: "var(--sidebar-active-width)",
		fontColor: "var(--sidebar-font-color)",
		fontSize: "var(--sidebar-font-size)",
		selectedColor: "var(--sidebar-selected-color)",
		hoverColor: "var(--sidebar-hover-color)",
		bgColor: "var(--sidebar-background-color)",
		padding: "var(--sidebar-padding)",
		slideTime: "var(--sidebar-slide-time)",
	},
};

SideBar.propTypes = {
	options: PropTypes.array,
	pathPrefix: PropTypes.string,
	theme: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return {
		signOut: () => {
			dispatch(signOut());
		},
	};
};

export default connect(null, mapDispatchToProps)(SideBar);
