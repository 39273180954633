import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import SideBar from "../sidebar/SideBar";
import Header from "../header/Header";
import LoadingOverlay from "../../../components/ui/loader/Loader";
import { LoadingOverlayBrew } from "../../../components/ui/loader/LoaderBrew";
import "./Layout.scss";

const Layout = (props) => {
	return (
		<>
			<div className="wrapper">
				<SideBar options={props.navOptions} />
				<div className="layout">
					<Header title="Price Smart" />
					<div className="main-content" id="content">
						{props.children}
					</div>
				</div>
				<LoadingOverlay showLoader={props.loading !== 0} />
				<LoadingOverlayBrew showLoader={props.lengthyOpLoader} />
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	loading: state.global.loading,
	lengthyOpLoader: state.global.lengthyOpLoader,
	error: state.global.error,
	message: state.global.message,
});

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
