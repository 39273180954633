import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import brewImage from "../../../assets/images/brewing.gif";
import "./LoaderBrew.scss";

export const LoadingOverlayBrew = (props) => {
	return (
		<Modal
			aria-labelledby="optimization-brewing"
			aria-describedby="optimization-brewing"
			className="optimization-loader"
			open={props.showLoader}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.showLoader}>
				<div className="overlayContainer">
					<img
						className="brewingGIF"
						src={brewImage}
						alt="Generate Cadence Brewing"
					/>
					<p className="imageText">
						Your optimization is brewing ...
					</p>
				</div>
			</Fade>
		</Modal>
	);
};
