export const CREATE_NEW_RULE = {
	screen_composition: {
		components: [
			{
				type: "div",
				staticProps: {
					className: "common-content-container",
				},
				componentProps: {
					content: [
						{
							type: "text",
							staticProps: {
								variant: "h2",
								className: "common-page-title",
							},
							dataProps: {
								content: {
									type: "derived",
									dataKey: "rule_label",
								},
							},
						},
						{
							id: "rule_det",
							type: "stepper",
							staticProps: {
								className: "",
								nonLinear: false,
								hideStepLabel: true,
								steps: [
									{
										id: 0,
										label: "Details & Product Selection",
									},
									{
										id: 1,
										label: "Rules Input",
									},
								],
							},
							componentProps: {
								content: [
									{
										type: "mapped_component",
										id: "Rule_Details",
										staticProps: {
											stepperContentId: 0,
										},
									},
									{
										type: "mapped_component",
										id: "Rules_Input",
										staticProps: {
											stepperContentId: 1,
										},
									},
								],
								extraContent: [
									{
										// id: "return-to-product-group-button",
										type: "button",
										staticProps: {
											icon: "return",
											customStyle: {
												fontSize: "14px",
												margin: 0,
												fontWeight: 500,
												background: "transparent",
											},
											variant: "secondary",
											label: "Return to All Rules",
											iconType: "svg",
										},
										functionProps: [
											{
												functionName: "onClick",
												actions: [
													{
														type: "redirect",
														link: "rules",
													},
												],
											},
										],
									},
								],
							},
							dataProps: {
								currentStep: {
									type: "derived",
									dataKey: "rule_det",
									subjectId: "currentStep",
								},
							},
						},
					],
				},
				functionProps: [
					{
						functionName: "onLoad",
						actions: [
							{
								type: "reducer_function",
								params: [
									{
										source: "reducer",
										sourceId: "rule_type",
										apiRequestKey: "rule_type",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "rule_det",
										subjectId: "rule_type",
										dataType: "basic",
										apiResponseKey: "rule_type",
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"create_strategy_store_selection_type",
										value: "store_hierarchy1",
									},
									{
										destination: "reducer",
										dataKey:
											"create_strategy_product_selection_type",
										value: "product_hierarchy1",
									},
									{
										destination: "reducer",
										dataKey: "unsavedChangeFlag",
										value: false,
									},
									{
										destination: "reducer",
										dataKey: "no_updated_rows",
										value: false,
									},
									{
										destination: "reducer",
										dataKey: "rule_det",
										subjectId: "no_updateds_on_table_data",
										value: true,
									},
									{
										destination: "reducer",
										dataKey: "rule_det",
										subjectId: "updated_rows_flag",
										value: false,
									},
									{
										destination: "reducer",
										dataKey: "productgroups_og",
										value: null,
									},
									{
										destination: "reducer",
										dataKey: "storegroups_og",
										value: null,
									},
									{
										destination: "reducer",
										dataKey: "filteredStores_og",
										value: null,
									},
									{
										destination: "reducer",
										dataKey: "filteredProducts_og",
										value: null,
									},
								],
							},
							{
								type: "redirect",
								link: "rules",
								confirmation: {
									conditions: [
										{
											comparison: "notEquals",
											source: "reducer",
											sourceId: "createEditRuleFlag",
											checkValue: true,
										},
									],
								},
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								confirmation: {
									conditions: [
										{
											comparison: "exists",
											source: "reducer",
											sourceId: "rule_det",
											subjectId: "rule_id",
											checkValue: true,
										},
									],
								},
								params: [
									{
										source: "fixed",
										dataType: "object",
										apiRequestKey: "id",
										value: 9011,
									},
									{
										source: "reducer",
										sourceId: "rule_det",
										subjectId: "rule_id",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "rule_id",
										dataType: "basic",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "rule_det",
										dataType: "basic",
									},
									// one approach
									{
										destination: "reducer",
										dataKey: "rule_product_level",
										apiResponseKey: "rule_product_level",
									},
									{
										destination: "reducer",
										dataKey: "rule_store_level",
										apiResponseKey: "rule_store_level",
									},
									{
										destination: "reducer",
										dataKey: "rule_det_copy",
										dataType: "basic",
									},
								],
								onComplete: {
									actions: [
										{
											type: "reducer_function",
											params: [
												{
													source: "reducer",
													sourceId: "rule_det",
												},
											],
											responseFormatter: [
												// Keeping for table use
												{
													destination: "reducer",
													dataKey: "filteredProducts",
													apiResponseKey:
														"product_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey: "filteredStores",
													apiResponseKey:
														"store_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "table",
													dataKey: "rule_store_list",
													apiResponseKey:
														"store_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true
												},
												{
													destination: "table",
													dataKey: "rule_product_list",
													apiResponseKey:
														"store_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true
												},
												{
													destination: "table",
													dataKey: "create_strategy_product_list",
													apiResponseKey:
														"product_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true
												},
												{
													destination: "table",
													dataKey: "create_strategy_store_list",
													apiResponseKey:
														"store_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true
												},
												{
													destination: "reducer",
													dataKey: "productgroups",
													apiResponseKey:
														"product_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "table",
													dataKey: "create_strategy_product_group_list",
													apiResponseKey:
														"product_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey: "storegroups",
													apiResponseKey:
														"store_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "table",
													dataKey: "create_strategy_store_group_list",
													apiResponseKey:
														"store_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "table",
													dataKey: "rule_store_group_list",
													apiResponseKey:
														"store_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true
												},
												{
													destination: "table",
													dataKey: "rule_store_group_list",
													apiResponseKey:
														"store_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true
												},
												// Kept for verifying a change in product/store selection
												{
													destination: "reducer",
													dataKey:
														"filteredProducts_og",
													apiResponseKey:
														"product_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey:
														"filteredStores_og",
													apiResponseKey:
														"store_selection",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey: "productgroups_og",
													apiResponseKey:
														"product_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
												{
													destination: "reducer",
													dataKey: "storegroups_og",
													apiResponseKey:
														"store_groups",
													dataType: "array",
													selectAll: true,
													overwrite: true,
												},
											],
										},
										// Depending on which data( store/store_group and product/product_group combo ) come from API for rule_detail fetch API using rule_id
										// change the selected tab
										// if product_list exists
										{
											type: "reducer_function",
											confirmation: {
												conditions: [
													{
														comparison: "exists",
														source: "reduce",
														sourceId:
															"filteredProducts",
														dataType: "basic",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"create_strategy_product_selection_type",
													value: "product_hierarchy1",
												},
											],
										},
										// if product_group_list exists
										{
											type: "reducer_function",
											confirmation: {
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"productgroups",
														dataType: "basic",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"create_strategy_product_selection_type",
													value: "product_group",
												},
											],
										},
										// if store_list exists
										{
											type: "reducer_function",
											confirmation: {
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId:
															"filteredStores",
														dataType: "basic",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"create_strategy_store_selection_type",
													value: "store_hierarchy1",
												},
											],
										},
										// if store_group_list exists
										{
											type: "reducer_function",
											confirmation: {
												conditions: [
													{
														comparison: "exists",
														source: "reducer",
														sourceId: "storegroups",
														dataType: "basic",
													},
												],
											},
											responseFormatter: [
												{
													destination: "reducer",
													dataKey:
														"create_strategy_store_selection_type",
													value: "store_group",
												},
											],
										},
									],
								},
							},
						],
					},
				],
			},
		],
	},
	mapped_components: {
		// Step 1
		Rule_Details: {
			screen_composition: {
				components: [
					{
						type: "div",
						staticProps: {
							className: "box-container",
						},
						componentProps: {
							content: [
								{
									type: "text",
									staticProps: {
										content: "Rule Detail",
										className: "common-page-title",
										variant: "h2",
									},
								},
								{
									type: "div",
									staticProps: {
										customStyle: {
											display: "flex",
										},
									},
									componentProps: {
										content: [
											{
												type: "form",
												id: "rule_det",
												staticProps: {
													customStyle: {
														width: "400px",
													},
													fields: [
														{
															type: "text",
															id: "rule_name",
															placeholder: "Name",
															variant: "outlined",
															isMandatory: true,
															showLabel: true,
															label: "Rule Name",
														},
													],
												},
											},
											{
												type: "form",
												id: "rule_det",
												staticProps: {
													customStyle: {
														width: "400px",
													},
													fields: [
														{
															type: "select",
															id: "rule_flexibility_type_id",
															isMulti: false,
															placeholder:
																"select",
															variant: "outlined",
															isMandatory: true,
															label: "Rule Type",
															showLabel: true,
															hideSearch: true,
															options: [
																{
																	label: "Flexible",
																	value: 1,
																},
																{
																	label: "Inflexible",
																	value: 0,
																},
															],
															selectedOptions: [
																{
																	label: "Flexible",
																	value: 1,
																},
															],
														},
													],
												},
											},
											{
												type: "form",
												id: "rule_det",
												staticProps: {
													customStyle: {
														width: "400px",
													},
													fields: [
														{
															type: "text",
															id: "rule_description",
															placeholder:
																"Description",
															variant: "outlined",
															showLabel: true,
															label: "Rule Description",
														},
													],
												},
											},
										],
									},
								},
							],
						},
						functionProps: [
							{
								functionName: "onLoad",
								actions: [
									//  Moving Selected product-store to filtered keys to populate table when coming back to step 1
									// Product List
									{
										type: "reducer_function",
										params: [
											{
												source: "table",
												sourceId: "rule_product_list",
												apiRequestKey: "product_list",
												dataType: "array",
												selectEntireRow: true,
											},
										],
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "filteredProducts",
												apiResponseKey: "product_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
											{
												destination: "reducer",
												dataKey: "filteredProducts_og",
												apiResponseKey: "product_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
											{
												destination: "table",
												dataKey: "create_strategy_product_list",
												apiResponseKey: "product_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
										],
									},
									// Product Group List
									{
										type: "reducer_function",
										params: [
											{
												source: "table",
												sourceId:
													"rule_product_group_list",
												apiRequestKey:
													"product_group_list",
												dataType: "array",
												selectEntireRow: true,
											},
										],
										responseFormatter: [
											{
												selectAll: true,
												destination: "reducer",
												dataKey: "productgroups",
												apiResponseKey:
													"product_group_list",
												dataType: "array",
												overwrite: true,
											},
											{
												selectAll: true,
												destination: "reducer",
												dataKey: "productgroups_og",
												apiResponseKey:
													"product_group_list",
												dataType: "array",
												overwrite: true,
											},
											{
												selectAll: true,
												destination: "table",
												dataKey: "create_strategy_store_group_list",
												apiResponseKey:
													"product_group_list",
												dataType: "array",
												overwrite: true,
											},
										],
									},
									// Store List
									{
										type: "reducer_function",
										params: [
											{
												source: "table",
												sourceId: "rule_store_list",
												apiRequestKey: "store_list",
												dataType: "array",
												selectEntireRow: true,
											},
										],
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "filteredStores",
												apiResponseKey: "store_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
											{
												destination: "reducer",
												dataKey: "filteredStores_og",
												apiResponseKey: "store_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
											{
												destination: "table",
												dataKey: "create_strategy_store_list",
												apiResponseKey: "store_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
										],
									},
									// Store Group List
									{
										type: "reducer_function",
										params: [
											{
												source: "table",
												sourceId:
													"rule_store_group_list",
												apiRequestKey:
													"store_group_list",
												dataType: "array",
												selectEntireRow: true,
											},
										],
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "storegroups",
												apiResponseKey:
													"store_group_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
											{
												destination: "reducer",
												dataKey: "storegroups_og",
												apiResponseKey:
													"store_group_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
											{
												destination: "table",
												dataKey: "create_strategy_store_group_list",
												apiResponseKey:
													"store_group_list",
												dataType: "array",
												selectAll: true,
												overwrite: true,
											},
										],
									},
									{
										type: "reducer_function",
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "productStoreChangeFlag",
												value: false,
											},
											{
												destination: "reducer",
												dataKey: "rules_selection_options",
												value: null,
												overwrite: true
											},
										]
									},
								]
							}
						]
					},

					// Store & Product Selection
					{
						type: "productStoreMappingType",
						staticProps: {
							disableTab: true,
						},
					},
					// CTAs
					{
						type: "div",
						staticProps: {
							className: "flex-container",
							customStyle: {
								marginTop: "40px",
							},
						},
						componentProps: {
							content: [
								{
									type: "button",
									staticProps: {
										iconClass: "",
										className: "",
										variant: "secondary",
										label: "Cancel",
									},
									functionProps: [
										{
											functionName: "onClick",
											actions: [
												{
													type: "redirect",
													link: "rules",
												},
											],
										},
									],
								},
								{
									type: "button",
									staticProps: {
										iconClass: "",
										className: "",
										variant: "primary",
										label: "Next",
									},
									functionProps: [
										{
											functionName: "onClick",
											actions: [
												//  Moving Selected product store to another key
												// Product List
												{
													type: "reducer_function",
													params: [
														{
															source: "table",
															sourceId: "create_strategy_product_list",
															apiRequestKey:
																"product_list",
															dataType: "array",
															selectEntireRow: true,
														},
													],
													responseFormatter: [
														{
															destination:
																"table",
															dataKey:
																"rule_product_list",
															apiResponseKey:
																"product_list",
															dataType: "array",
															selectAll: true,
															overwrite: true,
														},
													],
												},
												// Product Group List
												{
													type: "reducer_function",
													params: [
														{
															source: "table",
															sourceId: "create_strategy_product_group_list",
															apiRequestKey:
																"product_group_list",
															dataType: "array",
															selectEntireRow: true,
														},
													],
													responseFormatter: [
														{
															selectAll: true,
															destination:
																"table",
															dataKey:
																"rule_product_group_list",
															apiResponseKey:
																"product_group_list",
															dataType: "array",
															overwrite: true,
														},
													],
												},
												// Store List
												{
													type: "reducer_function",
													params: [
														{
															source: "table",
															sourceId:
																"create_strategy_store_list",
															apiRequestKey:
																"store_list",
															dataType: "array",
															selectEntireRow: true,
														},
													],
													responseFormatter: [
														{
															destination:
																"table",
															dataKey:
																"rule_store_list",
															apiResponseKey:
																"store_list",
															dataType: "array",
															selectAll: true,
															overwrite: true,
														},
													],
												},
												// Store Group List
												{
													type: "reducer_function",
													params: [
														{
															source: "table",
															sourceId: "create_strategy_store_group_list",
															apiRequestKey:
																"store_group_list",
															dataType: "array",
															selectEntireRow: true,
														},
													],
													responseFormatter: [
														{
															destination:
																"table",
															dataKey:
																"rule_store_group_list",
															apiResponseKey:
																"store_group_list",
															dataType: "array",
															selectAll: true,
															overwrite: true,
														},
													],
												},

												// ---- If a change in product/store selcetion change a flag --------
												// ---- Make the table_data, column_headers, rule_product_level, rule_store_level
												// PG
												{
													type: "reducer_function",
													confirmation: {
														conditionOperator:
															"and",
														conditions: [
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"or",
																conditions: [
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_store_group_list",
																	},
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_store_list",
																	},
																],
															},
															// {
															// 	comparison:
															// 		"exists",
															// 	source: "reducer",
															// 	sourceId: "rule_det",
															// 	subjectId: "table_data"
															// },
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_product_group_list",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId: "productStoreChangeFlag",
																checkValue: false,
															},
															{
																comparison:
																	"notEquals",
																source: "table",
																sourceId:
																	"rule_product_group_list",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"productgroups_og",
																},
															},
														],
													},
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"table_data",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_store_level",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_product_level",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"column_headers",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey:
																"no_updated_rows",
															value: true,
														},
														{
															destination: "reducer",
															dataKey: "productStoreChangeFlag",
															value: true,
														},
														{
															destination: "reducer",
															dataKey: "rule_det",
															subjectId:
																"no_updateds_on_table_data",
															value: false,
														},
													],
												},
												// SG
												{
													type: "reducer_function",
													confirmation: {
														conditionOperator:
															"and",
														conditions: [
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"or",
																conditions: [
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_product_group_list",
																	},
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_product_list",
																	},
																],
															},
															// {
															// 	comparison:
															// 		"exists",
															// 	source: "reducer",
															// 	sourceId: "rule_det",
															// 	subjectId: "table_data"
															// },
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_store_group_list",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId: "productStoreChangeFlag",
																checkValue: false,
															},
															{
																comparison:
																	"notEquals",
																source: "table",
																sourceId:
																	"rule_store_group_list",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"storegroups_og",
																},
															},
														],
													},
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"table_data",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_store_level",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_product_level",
															value: null,
														},
														{
															destination: "reducer",
															dataKey: "productStoreChangeFlag",
															value: true,
														},
														{
															destination: "reducer",
															dataKey: "rule_det",
															subjectId:
																"column_headers",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey:
																"no_updated_rows",
															value: true,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"no_updateds_on_table_data",
															value: false,
														},
													],
												},
												// ST
												{
													type: "reducer_function",
													confirmation: {
														conditionOperator:
															"and",
														conditions: [
															// {
															// 	comparison:
															// 		"exists",
															// 	source: "reducer",
															// 	sourceId: "rule_det",
															// 	subjectId: "table_data"
															// },
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"or",
																conditions: [
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_product_group_list",
																	},
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_product_list",
																	},
																],
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_store_list",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId: "productStoreChangeFlag",
																checkValue: false,
															},
															{
																comparison:
																	"notEquals",
																source: "table",
																sourceId:
																	"rule_store_list",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"filteredStores_og",
																},
															},
														],
													},
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"table_data",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_store_level",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_product_level",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"column_headers",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey:
																"no_updated_rows",
															value: true,
														},
														{
															destination: "reducer",
															dataKey: "productStoreChangeFlag",
															value: true,
														},
														{
															destination: "reducer",
															dataKey: "rule_det",
															subjectId:
																"no_updateds_on_table_data",
															value: false,
														},
													],
												},
												// PT
												{
													type: "reducer_function",
													confirmation: {
														conditionOperator:
															"and",
														conditions: [
															// {
															// 	comparison:
															// 		"exists",
															// 	source: "reducer",
															// 	sourceId: "rule_det",
															// 	subjectId: "table_data"
															// },
															{
																comparison:
																	"multiple",
																conditions: [
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_store_group_list",
																	},
																	{
																		comparison:
																			"exists",
																		source: "table",
																		sourceId:
																			"rule_store_list",
																	},
																],
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_product_list",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId: "productStoreChangeFlag",
																checkValue: false,
															},
															{
																comparison:
																	"notEquals",
																source: "table",
																sourceId:
																	"rule_product_list",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"filteredProducts_og",
																},
															},
														],
													},
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"table_data",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_store_level",
															value: null,
														},
														{
															destination: "reducer",
															dataKey: "productStoreChangeFlag",
															value: true,
														},
														{
															destination: "reducer",
															dataKey: "rule_det",
															subjectId:
																"rule_product_level",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"column_headers",
															value: null,
														},
														{
															destination:
																"reducer",
															dataKey:
																"no_updated_rows",
															value: true,
														},
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"no_updateds_on_table_data",
															value: false,
														},
													],
												},
												// Moving to Next Step
												// ------------------Product/Store Reco Level API Call------------
												// Call when product group selected
												{
													type: "api_function",
													runOnLoad: true,
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditionOperator:
															"and",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_name",
																dataType:
																	"basic",
																message:
																	"Please enter rule name",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_product_group_list",
																dataType:
																	"basic",
																message:
																	"Please select product group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_flexibility_type_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"or",
																conditions: [
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_store_group_list",
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_store_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"store_group",
																			},
																		]
																	},
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_store_list",
																			},
																			{
																				comparison:
																					"notEquals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_store_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"store_group",
																			},
																		]
																	},
																],
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															dataType: "object",
															apiRequestKey: "id",
															value: 9007,
														},
														{
															source: "table",
															sourceId:
																"create_strategy_product_group_list",
															subjectId:
																"product_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested: "product_groups",
															dataType: "array",
														},
													],
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey:
																"rules_selection_options",
															subjectId:
																"rule_product_level",
															apiRequestKey:
																"data",
														},
													],
													onComplete: {
														actions: [
															{
																type: "reducer_function",
																confirmation: {
																	conditions:
																		[
																			{
																				comparison:
																					"exists",
																				source:
																					"reducer",
																				sourceId:
																					"rules_selection_options",
																				subjectId:
																					"rule_store_level",
																			},
																		],
																},
																responseFormatter:
																	[
																		{
																			destination:
																				"reducer",
																			dataKey:
																				"rule_det",
																			subjectId:
																				"currentStep",
																			dataType:
																				"basic",
																			value: 1,
																		},
																	],
															},
														],
													},
												},
												// Call when product selected
												{
													type: "api_function",
													runOnLoad: true,
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditionOperator:
															"and",
														conditions: [
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_name",
																dataType:
																	"basic",
																message:
																	"Please enter rule name",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_product_list",
																dataType:
																	"basic",
																message:
																	"Please select products",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_flexibility_type_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"or",
																conditions: [
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_store_group_list",
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_store_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"store_group",
																			},
																		]
																	},
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_store_list",
																			},
																			{
																				comparison:
																					"notEquals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_store_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"store_group",
																			},
																		]
																	},
																],
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															dataType: "object",
															apiRequestKey: "id",
															value: 9007,
														},
													],
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey:
																"rules_selection_options",
															subjectId:
																"rule_product_level",
															apiRequestKey:
																"data",
														},
													],
													onComplete: {
														actions: [
															{
																type: "reducer_function",
																confirmation: {
																	conditions:
																		[
																			{
																				comparison:
																					"exists",
																				source:
																					"reducer",
																				sourceId:
																					"rules_selection_options",
																				subjectId:
																					"rule_store_level",
																			},
																		],
																},
																responseFormatter:
																	[
																		{
																			destination:
																				"reducer",
																			dataKey:
																				"rule_det",
																			subjectId:
																				"currentStep",
																			dataType:
																				"basic",
																			value: 1,
																		},
																	],
															},
														],
													},
												},
												// Call when Stores selected
												{
													type: "api_function",
													runOnLoad: true,
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditionOperator:
															"and",
														conditions: [
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_name",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_store_list",
																dataType:
																	"basic",
																message:
																	"Please select stores",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_flexibility_type_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"or",
																conditions: [
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_product_group_list",
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_product_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"product_group",
																			},
																		]
																	},
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_product_list",
																			},
																			{
																				comparison:
																					"notEquals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_product_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"product_group",
																			},
																		]
																	},
																],
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															dataType: "object",
															apiRequestKey: "id",
															value: 9009,
														},
													],
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey:
																"rules_selection_options",
															subjectId:
																"rule_store_level",
															apiRequestKey:
																"data",
														},
													],
													onComplete: {
														actions: [
															{
																type: "reducer_function",
																confirmation: {
																	conditions:
																		[
																			{
																				comparison:
																					"exists",
																				source:
																					"reducer",
																				sourceId:
																					"rules_selection_options",
																				subjectId:
																					"rule_product_level",
																			},
																		],
																},
																responseFormatter:
																	[
																		{
																			destination:
																				"reducer",
																			dataKey:
																				"rule_det",
																			subjectId:
																				"currentStep",
																			dataType:
																				"basic",
																			value: 1,
																		},
																	],
															},
														],
													},
												},
												// Call when Stores Group selected
												{
													type: "api_function",
													runOnLoad: true,
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditionOperator:
															"and",
														conditions: [
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_name",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"create_strategy_store_group_list",
																dataType:
																	"basic",
																message:
																	"Please select store group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_flexibility_type_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"multiple",
																conditionOperator:
																	"or",
																conditions: [
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_product_group_list",
																			},
																			{
																				comparison:
																					"equals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_product_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"product_group",
																			},
																		]
																	},
																	{
																		comparison:
																			"multiple",
																		conditionOperator:
																			"and",
																		conditions: [
																			{
																				comparison:
																					"exists",
																				source: "table",
																				sourceId:
																					"rule_product_list",
																			},
																			{
																				comparison:
																					"notEquals",
																				source: "reducer",
																				sourceId:
																					"create_strategy_product_selection_type",
																				dataType:
																					"basic",
																				checkValue:
																					"product_group",
																			},
																		]
																	},
																],
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															dataType: "object",
															apiRequestKey: "id",
															value: 9009,
														},
														{
															source: "table",
															sourceId:
																"create_strategy_store_group_list",
															subjectId:
																"store_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_groups",
															dataType: "array",
														},
													],
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey:
																"rules_selection_options",
															subjectId:
																"rule_store_level",
															apiRequestKey:
																"data",
														},
													],
													onComplete: {
														actions: [
															{
																type: "reducer_function",
																confirmation: {
																	conditions:
																		[
																			{
																				comparison:
																					"exists",
																				source:
																					"reducer",
																				sourceId:
																					"rules_selection_options",
																				subjectId:
																					"rule_product_level",
																			},
																		],
																},
																responseFormatter:
																	[
																		{
																			destination:
																				"reducer",
																			dataKey:
																				"rule_det",
																			subjectId:
																				"currentStep",
																			dataType:
																				"basic",
																			value: 1,
																		},
																	],
															},
														],
													},
												},
											],
										},
									],
								},
							],
						},
					},
				],
			},
		},
		// Step 2
		Rules_Input: {
			screen_composition: {
				components: [
					{
						type: "div",
						staticProps: {
							className: "box-container",
						},
						componentProps: {
							content: [
								{
									type: "text",
									staticProps: {
										content: "Rule Input",
										className: "common-page-title",
										variant: "h2",
									},
								},
								{
									type: "div",
									staticProps: {
										customStyle: {
											alignItems: "end",
											display: "flex",
										},
									},
									componentProps: {
										content: [
											{
												type: "form",
												id: "rule_det",
												staticProps: {
													customStyle: {
														width: "400px",
													},
													inputType: "dataInput",
													label: "Select Product Level",
													fields: [
														{
															type: "select",
															id: "rule_product_level",
															isMulti: false,
															placeholder:
																"select",
															variant: "outlined",
															isMandatory: true,
															label: "Select Product Level",
															showLabel: true,
															hideSearch: true,
														},
													],
												},
												dataProps: {
													options: {
														type: "derived",
														dataKey:
															"rules_selection_options",
													},
												},
											},
											{
												type: "form",
												id: "rule_det",
												staticProps: {
													customStyle: {
														width: "400px",
													},
													inputType: "dataInput",
													label: "Select Store Level",
													fields: [
														{
															type: "select",
															id: "rule_store_level",
															isMulti: false,
															placeholder:
																"select",
															variant: "outlined",
															isMandatory: true,
															label: "Select Store Level",
															showLabel: true,
															hideSearch: true,
														},
													],
												},
												dataProps: {
													options: {
														type: "derived",
														dataKey:
															"rules_selection_options",
													},
												},
											},
											{
												type: "div",
												staticProps: {
													customStyle: {
														height: "90px",
														display: "flex",
													},
												},
												componentProps: {
													content: [
														{
															type: "button",
															staticProps: {
																variant:
																	"primary",
																label: "Submit",
															},
															functionProps: [
																{
																	functionName:
																		"onClick",
																	actions: [
																		// Call if Product/Store Hierarchy Selected
																		{
																			type: "api_function",
																			apiEndPoint:
																				"model",
																			apiMethod:
																				"post",
																			confirmation:
																				{
																					type: "check",
																					showErrorMessage: true,
																					conditions:
																						[
																							{
																								comparison:
																									"notEquals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_product_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"product_group",
																							},
																							{
																								comparison:
																									"notEquals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_store_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"store_group",
																							},
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_product_level",
																								dataType:
																									"basic",
																							},
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_store_level",
																								dataType:
																									"basic",
																							},
																						],
																					conditionOperator:
																						"and",
																				},
																			params: [
																				{
																					source: "fixed",
																					apiRequestKey:
																						"id",
																					value: 9006,
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_id",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_type",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_type",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_product_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_store_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_product_list",
																					subjectId:
																						"product_h5_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"product_selection",
																					dataType:
																						"array",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_store_list",
																					subjectId:
																						"store_h6_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"store_selection",
																					dataType:
																						"array",
																				},
																			],
																			responseFormatter: [
																				{
																					destination: "reducer",
																					dataKey: "rule_det",
																					subjectId: "table_data",
																					dataType: "basic",
																				}
																			],
																			onComplete: {
																				actions: [
																					{
																						type: "reducer_function",
																						confirmation: {
																							conditions: [
																								{
																									comparison: "exists",
																									source: "reducer",
																									sourceId: "rule_det",
																									subjectId: "table_data"
																								}
																							]
																						},
																						responseFormatter: [
																							{
																								destination: "reducer",
																								dataKey: "disable_rule_subbmit_btn",
																								value: false
																							}
																						]
																					}
																				]
																			}
																		},
																		// Call if Product Group &&  store Group is selected
																		{
																			type: "api_function",
																			apiEndPoint:
																				"model",
																			apiMethod:
																				"post",
																			confirmation:
																				{
																					type: "check",
																					showErrorMessage: true,
																					conditions:
																						[
																							{
																								comparison:
																									"equals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_product_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"product_group",
																							},
																							{
																								comparison:
																									"equals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_store_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"store_group",
																							},
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_product_level",
																								dataType:
																									"basic",
																							},
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_store_level",
																								dataType:
																									"basic",
																							},
																						],
																					conditionOperator:
																						"and",
																				},
																			params: [
																				{
																					source: "fixed",
																					apiRequestKey:
																						"id",
																					value: 9006,
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_id",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_type",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_type",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_product_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_store_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_product_group_list",
																					subjectId:
																						"product_group_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"product_group",
																					dataType:
																						"array",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_store_group_list",
																					subjectId:
																						"store_group_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"store_group",
																					dataType:
																						"array",
																				},
																			],
																			responseFormatter: [
																				{
																					destination: "reducer",
																					dataKey: "rule_det",
																					subjectId: "table_data",
																					dataType: "basic",
																				}
																			],
																			onComplete: {
																				actions: [
																					{
																						type: "reducer_function",
																						confirmation: {
																							conditions: [
																								{
																									comparison: "exists",
																									source: "reducer",
																									sourceId: "rule_det",
																									subjectId: "table_data"
																								}
																							]
																						},
																						responseFormatter: [
																							{
																								destination: "reducer",
																								dataKey: "disable_rule_subbmit_btn",
																								value: false
																							}
																						]
																					}
																				]
																			}
																		},
																		// Call if Product Group &&  store  is selected
																		{
																			type: "api_function",
																			apiEndPoint:
																				"model",
																			apiMethod:
																				"post",
																			confirmation:
																				{
																					type: "check",
																					showErrorMessage: true,
																					conditions:
																						[
																							{
																								comparison:
																									"equals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_product_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"product_group",
																							},
																							{
																								comparison:
																									"notEquals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_store_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"store_group",
																							},
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_product_level",
																								dataType:
																									"basic",
																							},
																						],
																					conditionOperator:
																						"and",
																				},
																			params: [
																				{
																					source: "fixed",
																					apiRequestKey:
																						"id",
																					value: 9006,
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_id",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_type",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_type",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_product_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_store_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_product_group_list",
																					subjectId:
																						"product_group_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"product_group",
																					dataType:
																						"array",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_store_list",
																					subjectId:
																						"store_h6_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"store_selection",
																					dataType:
																						"array",
																				},
																			],
																			responseFormatter: [
																				{
																					destination: "reducer",
																					dataKey: "rule_det",
																					subjectId: "table_data",
																					dataType: "basic",
																				}
																			],
																			onComplete: {
																				actions: [
																					{
																						type: "reducer_function",
																						confirmation: {
																							conditions: [
																								{
																									comparison: "exists",
																									source: "reducer",
																									sourceId: "rule_det",
																									subjectId: "table_data"
																								}
																							]
																						},
																						responseFormatter: [
																							{
																								destination: "reducer",
																								dataKey: "disable_rule_subbmit_btn",
																								value: false
																							}
																						]
																					}
																				]
																			}
																		},
																		// Call if Product  &&  store Group  is selected
																		{
																			type: "api_function",
																			apiEndPoint:
																				"model",
																			apiMethod:
																				"post",
																			confirmation:
																				{
																					type: "check",
																					showErrorMessage: true,
																					conditions:
																						[
																							{
																								comparison:
																									"notEquals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_product_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"product_group",
																							},
																							{
																								comparison:
																									"equals",
																								source: "reducer",
																								sourceId:
																									"create_strategy_store_selection_type",
																								dataType:
																									"basic",
																								checkValue:
																									"store_group",
																							},
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_product_level",
																								dataType:
																									"basic",
																							},
																						],
																					conditionOperator:
																						"and",
																				},
																			params: [
																				{
																					source: "fixed",
																					apiRequestKey:
																						"id",
																					value: 9006,
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_id",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_type",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_type",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_product_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_store_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_product_list",
																					subjectId:
																						"product_h5_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"product_selection",
																					dataType:
																						"array",
																				},
																				{
																					source: "table",
																					sourceId:
																						"rule_store_group_list",
																					subjectId:
																						"store_group_id",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"store_group",
																					dataType:
																						"array",
																				},
																			],
																			responseFormatter: [
																				{
																					destination: "reducer",
																					dataKey: "rule_det",
																					subjectId: "table_data",
																					dataType: "basic",
																				}
																			],
																			onComplete: {
																				actions: [
																					{
																						type: "reducer_function",
																						confirmation: {
																							conditions: [
																								{
																									comparison: "exists",
																									source: "reducer",
																									sourceId: "rule_det",
																									subjectId: "table_data"
																								}
																							]
																						},
																						responseFormatter: [
																							{
																								destination: "reducer",
																								dataKey: "disable_rule_subbmit_btn",
																								value: false
																							}
																						]
																					}
																				]
																			}
																		},
																		// Call to get rules col_config
																		{
																			type: "api_function",
																			apiEndPoint:
																				"model",
																			apiMethod:
																				"post",
																			confirmation:
																				{
																					type: "check",
																					showErrorMessage: true,
																					conditionOperator:
																						"and",
																					conditions:
																						[
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_product_level",
																								dataType:
																									"basic",
																								message:
																									"Select a product level",
																							},
																							{
																								comparison:
																									"exists",
																								source: "reducer",
																								sourceId:
																									"rule_det",
																								subjectId:
																									"rule_store_level",
																								dataType:
																									"basic",
																								message:
																									"Select a store level",
																							},
																						],
																				},
																			params: [
																				{
																					source: "fixed",
																					apiRequestKey:
																						"id",
																					value: 9005,
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_type",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_type",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_product_level",
																					dataType:
																						"basic",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					apiRequestKey:
																						"parameters",
																					apiRequestKeyNested:
																						"rule_store_level",
																					dataType:
																						"basic",
																				},
																			],
																			responseFormatter:
																				[
																					{
																						destination:
																							"reducer",
																						dataKey:
																							"rule_det",
																						subjectId:
																							"column_headers",
																						dataType:
																							"basic",
																					},
																				],
																		},
																		// Make no_updated_rows as true, since reco_lvl change the whole table is to be passed that is
																		// Depended on this "no_updated_rows" key
																		{
																			type: "reducer_function",
																			confirmation: {
																				conditions: [
																					{
																						comparison: "notEquals",
																						source: "reducer",
																						sourceId: "rule_det_copy",
																						subjectId: "rule_store_level",
																						checkValue: {
																							source: "reducer",
																							sourceId: "rule_det",
																							subjectId: "rule_store_level",
																						}
																					},
																					{
																						comparison: "notEquals",
																						source: "reducer",
																						sourceId: "rule_det_copy",
																						subjectId: "rule_product_level",
																						checkValue: {
																							source: "reducer",
																							sourceId: "rule_det",
																							subjectId: "rule_product_level",
																						}
																					},
																				]
																			},
																			responseFormatter: [
																				{
																					destination: "reducer",
																					dataKey: "no_updated_rows",
																					value: true,
																				},
																			]
																		},
																		{
																			type: "reducer_function",
																			params: [
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_store_level",
																					apiRequestKey:
																						"rule_store_level",
																				},
																				{
																					source: "reducer",
																					sourceId:
																						"rule_det",
																					subjectId:
																						"rule_product_level",
																					apiRequestKey:
																						"rule_product_level",
																				},
																			],
																			responseFormatter:
																				[
																					{
																						destination:
																							"reducer",
																						dataKey:
																							"rule_store_level",
																						apiResponseKey:
																							"rule_store_level",
																					},
																					{
																						destination:
																							"reducer",
																						dataKey:
																							"rule_product_level",
																						apiResponseKey:
																							"rule_product_level",
																					},
																				],
																		},
																	],
																},
															],
														},
													],
												},
											},
										],
									},
								},
							],
						},
						functionProps: [
							{
								functionName: "onLoad",
								actions: [
									{
										type: "reducer_function",
										confirmation: {
											conditions: [
												{
													comparison: "notExists",
													source: "reducer",
													sourceId: "rule_det",
													subjectId: "table_data",
												},
											]
										},
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "disable_rule_subbmit_btn",
												value: true
											}
										]
									},
									{
										type: "reducer_function",
										confirmation: {
											conditions: [
												{
													comparison: "exists",
													source: "reducer",
													sourceId: "rule_det",
													subjectId: "table_data",
												},
											]
										},
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "disable_rule_subbmit_btn",
												value: false
											}
										]
									},
								]
							}
						],
					},
					{
						id: "rule_det_table",
						type: "aggrid",
						staticProps: {
							height: "300px",
							tableId: "rule_det_table",
							tableTitle: "",
							sizeColumnsToFit: true,
							uniqueKey: "row_id",
							rowSelection: "multiple",
							updateParent: true,
							sortByKeys: ["row_id"],
							domLayout: true,
						},
						functionProps: [
							{
								functionName: "onCellValueChanged",
								actions: [
									{
										type: "reducer_function",
										params: [
											{
												source: "self",
												apiRequestKey: "table_data",
												dataType: "array",
											},
										],
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "rule_det",
												dataType: "array",
											},
										],
									},
								],
							},
							{
								functionName: "onUpdateRow",
								actions: [
									{
										type: "reducer_function",
										confirmation: {
											conditionOperator: "and",
											conditions: [
												{
													comparison: "exists",
													source: "reducer",
													sourceId: "rule_det",
													subjectId: "rule_id",
												},
												{
													comparison: "notEquals",
													source: "reducer",
													sourceId: "no_updated_rows",
													checkValue: true,
												},
											],
										},
										params: [
											{
												source: "self",
												apiRequestKey: "table_data",
												dataType: "array",
											},
										],
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "rule_det",
												subjectId: "updated_rows",
												apiResponseKey: "table_data",
												dataType: "array",
											},
											{
												destination: "reducer",
												dataKey: "rule_det",
												subjectId:
													"no_updateds_on_table_data",
												dataType: "basic",
												value: false,
											},
										],
									},
									{
										type: "reducer_function",
										confirmation: {
											conditionOperator: "and",
											conditions: [
												{
													comparison: "exists",
													source: "reducer",
													sourceId: "rule_det",
													subjectId: "rule_id",
												},
												{
													comparison: "notEquals",
													source: "reducer",
													sourceId: "no_updated_rows",
													checkValue: true,
												},
											],
										},
										responseFormatter: [
											{
												destination: "reducer",
												dataKey: "rule_det",
												subjectId: "updated_rows_flag",
												dataType: "basic",
												value: true,
											},
										],
									},
								],
							},
						],
						dataProps: {
							column: {
								type: "derived",
								dataKey: "rule_det",
								subjectId: "column_headers",
							},
							data: {
								type: "derived",
								dataKey: "rule_det",
								subjectId: "table_data",
							},
							updatedData: {
								type: "derived",
								dataKey: "rule_det",
								subjectId: "updated_rows",
							},
						},
						componentProps: {},
					},
					{
						type: "div",
						staticProps: {
							className: "flex-container",
						},
						componentProps: {
							content: [
								{
									type: "button",
									staticProps: {
										iconClass: "",
										className: "",
										variant: "secondary",
										label: "Cancel",
									},
									functionProps: [
										{
											functionName: "onClick",
											actions: [
												{
													type: "reducer_function",
													responseFormatter: [
														{
															destination:
																"reducer",
															dataKey: "rule_det",
															subjectId:
																"currentStep",
															dataType: "basic",
															value: 0,
														},
													],
												},
											],
										},
									],
								},
								{
									type: "button",
									staticProps: {
										iconClass: "",
										className: "",
										variant: "primary",
										label: "Submit Rule",
									},
									dataProps: {
										isDisabled:{
											type: "derived",
											dataKey: "disable_rule_subbmit_btn",
										}
									},
									functionProps: [
										{
											functionName: "onClick",
											actions: [
												// ---------- If change in product/store combos or product/store reco level --------
												// Then make updated_rows_flag as false so BE perform delete & insert
												// ---------- For Create Rule Only ---------------
												// PG & SG
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"notExists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId: "rule_det",
																subjectId: "table_data",
																dataType: "basic",
																message: "Table data not present"
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9000,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"rule_product_level",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_product_level",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_group_list",
															subjectId:
																"product_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															dataType: "array",
														},
														{
															source: "table",
															sourceId:
																"rule_store_group_list",
															subjectId:
																"store_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															dataType: "array",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PT & ST
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"notExists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"table_data",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9000,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_list",
															subjectId:
																"product_h5_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															dataType: "array",
														},
														{
															source: "table",
															sourceId:
																"rule_store_list",
															subjectId:
																"store_h6_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															dataType: "array",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PT & SG
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"notExists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"table_data",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9000,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_list",
															subjectId:
																"product_h5_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															dataType: "array",
														},
														{
															source: "table",
															sourceId:
																"rule_store_group_list",
															subjectId:
																"store_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															dataType: "array",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PG & ST
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"notExists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"table_data",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9000,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_group_list",
															subjectId:
																"product_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															dataType: "array",
														},
														{
															source: "table",
															sourceId:
																"rule_store_list",
															subjectId:
																"store_h6_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															dataType: "array",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// ---------- For Edit Rule Only ---------------
												// if Updated rows flag true
												// PG & SG
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															// one approach
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"rule_product_level",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_product_level",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_group_list",
															subjectId:
																"product_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															dataType: "array",
														},
														{
															source: "table",
															sourceId:
																"rule_store_group_list",
															subjectId:
																"store_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PT & ST
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level Changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_list",
															subjectId:
																"product_h5_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															dataType: "array",
														},
														{
															source: "table",
															sourceId:
																"rule_store_list",
															subjectId:
																"store_h6_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PT & SG
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_list",
															subjectId:
																"product_h5_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															value: null,
														},
														{
															source: "table",
															sourceId:
																"rule_store_group_list",
															subjectId:
																"store_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PG & ST
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_group_list",
															subjectId:
																"product_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															value: null,
														},
														{
															source: "table",
															sourceId:
																"rule_store_list",
															subjectId:
																"store_h6_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// ------------------------ if Updated rows flag false -----------------------------
												// PG & SG
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"rule_product_level",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_product_level",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_group_list",
															subjectId:
																"product_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															value: null,
														},
														{
															source: "table",
															sourceId:
																"rule_store_group_list",
															subjectId:
																"store_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															value: null,
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"updated_rows",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PT & ST
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_list",
															subjectId:
																"product_h5_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															value: null,
														},
														{
															source: "table",
															sourceId:
																"rule_store_list",
															subjectId:
																"store_h6_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															value: null,
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"updated_rows",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PT & SG
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_list",
															subjectId:
																"product_h5_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															value: null,
														},
														{
															source: "table",
															sourceId:
																"rule_store_group_list",
															subjectId:
																"store_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															value: null,
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"updated_rows",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
												// PG & ST
												{
													type: "api_function",
													apiEndPoint: "model",
													apiMethod: "post",
													confirmation: {
														type: "check",
														showErrorMessage: true,
														conditions: [
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_id",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"create_strategy_store_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"store_group",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"create_strategy_product_selection_type",
																dataType:
																	"basic",
																checkValue:
																	"product_group",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_product_level",
																dataType:
																	"basic",
																message:
																	"Select a product level",
															},
															{
																comparison:
																	"exists",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"rule_store_level",
																dataType:
																	"basic",
																message:
																	"Select a store level",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_product_group_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"exists",
																source: "table",
																sourceId:
																	"rule_store_list",
																dataType:
																	"basic",
															},
															{
																comparison:
																	"notEquals",
																source: "reducer",
																sourceId:
																	"rule_det",
																subjectId:
																	"updated_rows_flag",
																checkValue: true,
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_store_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_store_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
															{
																comparison:
																	"equals",
																source: "reducer",
																sourceId:
																	"rule_product_level",
																checkValue: {
																	source: "reducer",
																	sourceId:
																		"rule_det",
																	subjectId:
																		"rule_product_level",
																},
																message:
																	"Reco level changed, click on submit to continue with selected reco level",
															},
														],
														conditionOperator:
															"and",
													},
													params: [
														{
															source: "fixed",
															apiRequestKey: "id",
															value: 9016,
															dataType: "basic",
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															apiRequestKey:
																"parameters",
															dataType: "basic",
														},
														{
															source: "table",
															sourceId:
																"rule_product_group_list",
															subjectId:
																"product_group_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_group",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"product_selection",
															value: null,
														},
														{
															source: "table",
															sourceId:
																"rule_store_list",
															subjectId:
																"store_h6_id",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_selection",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"store_group",
															value: null,
														},
														{
															source: "reducer",
															sourceId:
																"rule_det",
															subjectId:
																"table_data",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"rule_values",
															dataType: "array",
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"table_data",
															value: null,
														},
														{
															source: "fixed",
															apiRequestKey:
																"parameters",
															apiRequestKeyNested:
																"updated_rows",
															value: null,
														},
													],
													responseFormatter: [],
													onComplete: {
														actions: [
															{
																type: "redirect",
																link: "rules",
															},
														],
													},
												},
											],
										},
									],
								},
							],
						},
					},
				],
			},
		},
	},
};
