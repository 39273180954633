import { PRODUCT_GROUP_CONFIG_VIEW } from "./screenConfig/ProductGroups";
import { CREATE_PRODUCT_GROUP_CONFIG } from "./screenConfig/CreateProductGroup";
import { STORE_GROUP_CONFIG_VIEW } from "./screenConfig/StoreGroups";
import { CREATE_STORE_GROUP_CONFIG } from "./screenConfig/CreateStoreGroup";
import { DECISION_DASHBOARD } from "./screenConfig/DecisionDashboard";
import { STRATEGY_WORKBENCH } from "./screenConfig/StrategyWorkbench";
import { CREATE_STRATEGY } from "./screenConfig/CreateStrategy";
import { PCD_CONFIG_VIEW } from "./screenConfig/PcdConfig";
import { CREATE_PCD_CONFIG } from "./screenConfig/CreatePcdConfig";
import { REPORTING } from "./screenConfig/ReportingConfig";
import { RULES_PAGE } from "./screenConfig/RulesPage";
import { CREATE_NEW_RULE } from "./screenConfig/CreateNewRule";

export const protectedRoutes = [
	{
		path: "/decision-dashboard",
		component: "decisionDashboard/DecisionDashboard",
		icon: "widgets",
		exact: true,
		isPublic: true,
		title: "Decision Dashboard",
		key: "decisionDashboard",
		breadcrumbPath: "decision-dashboard",
		order: 1,
		isClickable: true,
		isActive: true,
		showOnMenu: true,
		subTitle: false,
	},
	{
		path: "/workbench",
		component: "workbench/Workbench",
		icon: "sell",
		exact: true,
		isPublic: true,
		title: "Markdown Workbench",
		key: "workbench",
		breadcrumbPath: "workbench",
		order: 2,
		isClickable: true,
		isActive: true,
		showOnMenu: true,
		subTitle: false,
	},
	// {
	// 	path: "/rules",
	// 	component: "rules/Rules",
	// 	icon: "my_location",
	// 	exact: true,
	// 	isPublic: true,
	// 	title: "All Rules",
	// 	key: "rules",
	// 	breadcrumbPath: "rules",
	// 	order: 3,
	// 	isClickable: true,
	// 	isActive: true,
	// 	showOnMenu: true,
	// },
	{
		path: "/markdown-configuration",
		key: "markdownConfiguration",
		icon: "settings",
		title: "Configuration",
		order: 4,
		isClickable: false,
		hasChildren: true,
		isActive: true,
		showOnMenu: true,
		subTitle: false,
	},
	{
		path: "/reporting",
		component: "reporting/Reporting",
		icon: "assessment",
		exact: true,
		isPublic: true,
		title: "Reporting",
		key: "reporting",
		breadcrumbPath: "reporting",
		order: 5,
		isClickable: true,
		isActive: true,
		showOnMenu: true,
		subTitle: false,
	},
	{
		path: "/product-group-configuration",
		component: "configuration/productGroupConfig/ProductGroupConfig",
		exact: true,
		isPublic: true,
		title: "Product Group Config",
		key: "productGroupConfiguration",
		breadcrumbPath: "product-group-configuration",
		order: 1,
		parent: "markdownConfiguration",
		isClickable: true,
		isActive: true,
		showOnMenu: true,
		subTitle: false,
	},
	{
		path: "/store-group-configuration",
		component: "configuration/storeGroupConfig/StoreGroupConfig",
		exact: true,
		isPublic: true,
		title: "Store Group Config",
		key: "storeGroupConfiguration",
		breadcrumbPath: "store-group-configuration",
		order: 2,
		parent: "markdownConfiguration",
		isClickable: true,
		isActive: true,
		showOnMenu: true,
		subTitle: false,
	},
	{
		path: "/pricing-calendar-configuration",
		component: "configuration/pcdConfig/PcdConfig",
		exact: true,
		isPublic: true,
		title: "Pricing Calendar Config",
		key: "pcdConfiguration",
		breadcrumbPath: "pricing-calendar-configuration",
		order: 3,
		parent: "markdownConfiguration",
		isClickable: true,
		isActive: true,
		showOnMenu: true,
		subTitle: false,
	},
	{
		path: "/product-group-configuration/create-product-group",
		component:
			"configuration/productGroupConfig/createProductGroup/CreateProductGroup",
		exact: true,
		isPublic: true,
		breadcrumbPath: "create-product-group",
		showOnMenu: false,
		title: "Create Product Group",
		subTitle: false,
	},
	{
		path: "workbench/create-strategy",
		component: "createStrategy/CreateStrategy",
		exact: true,
		isPublic: true,
		breadcrumbPath: "create-strategy",
		showOnMenu: false,
		title: "",
		subTitle: true,
	},
	{
		path: "/store-group-configuration/create-store-group",
		component:
			"configuration/storeGroupConfig/createStoreGroup/CreateStoreGroup",
		exact: true,
		isPublic: true,
		breadcrumbPath: "create-store-group",
		showOnMenu: false,
		title: "Create Store Group",
		subTitle: false,
	},
	{
		path: "/pricing-calendar-configuration/create-pcd-config",
		component: "configuration/pcdConfig/createPcdConfig/CreatePcdConfig",
		exact: true,
		isPublic: true,
		breadcrumbPath: "create-pcd-config",
		showOnMenu: false,
		title: "Create Pricing Calendar Configuration",
		subTitle: false,
	},
	// {
	// 	path: "rules/create-rule",
	// 	component: "createRule/CreateRule",
	// 	exact: true,
	// 	isPublic: true,
	// 	breadcrumbPath: "create-rule",
	// 	showOnMenu: false,
	// 	title: "Create Rule",
	// },
];

export const screen_config = {
	PRODUCT_GROUP_CONFIG_VIEW: PRODUCT_GROUP_CONFIG_VIEW,
	CREATE_PRODUCT_GROUP_CONFIG: CREATE_PRODUCT_GROUP_CONFIG,
	STORE_GROUP_CONFIG_VIEW: STORE_GROUP_CONFIG_VIEW,
	CREATE_STORE_GROUP_CONFIG: CREATE_STORE_GROUP_CONFIG,
	PCD_CONFIG_VIEW: PCD_CONFIG_VIEW,
	CREATE_PCD_CONFIG: CREATE_PCD_CONFIG,
	DECISION_DASHBOARD: DECISION_DASHBOARD,
	STRATEGY_WORKBENCH: STRATEGY_WORKBENCH,
	CREATE_STRATEGY: CREATE_STRATEGY,
	REPORTING: REPORTING,
	RULES_PAGE: RULES_PAGE,
	CREATE_RULE: CREATE_NEW_RULE
};

export const global_attributes = {
	labels: {
		brand: "Brand",
		product_h1: "Division",
		product_h2: "Department",
		product_h3: "Class",
		product_h4: "Sub Class",
		product_h5: "SKU",
		store_h0: "Country",
		store_h1: "DC",
		store_h2: "Region",
		store_h3: "State",
		store_h4: "District",
		store_h5: "City",
		store_h6: "Location",
		dateRange: "Date Range",
		store_launch_date: "Store Launch Date",
		ecomm_launch_date: "Ecomm Launch Date",
		color: "Color",
		tier: "Store Tier",
		date_range: "Date Range",
		country: "Country",
		store_type: "Store Type",
		international_store: "International Store",
		store_status: "Active Stores",
		store_tier: "Store Tier",
		store_id: "Store ID",
		store_code: "Store ID",
		store_name: "Store Name",
		region: "Region",
		state: "State",
		product_code: "Product ID",
		product_name: "Product Name",
		department: "Department",
		product_class: "Class",
		product_subclass: "Sub-Class",
		item_group: "Item Group",
		bin: "Tier",
		events: "Events",
		promos: "Promotions",
		overall: "Overall",
		finalized_markdown: "Markdown $",
		projected_markdown: "Projected Markdown $",
		actuals_markdown: "Actualized Markdown $",
		gross_margin_target: "Target GM",
		target_gross_margin: "Target GM",
		target_gm_dollar: "Target GM",
		ia_projected_gm_dollar: "IA Projected GM",
		sales_units: "Sales Unit",
		revenue: "Revenue",
		bl_over_ride_gm_dollar: "BL Override GM",
		markdown_dollar: "Markdown $",
		finalized_margin: "Finalized GM",
		finalized_gross_margin: "Finalized GM",
		finalized_baseline_margin: "Baseline GM",
		finalized_baseline_gross_margin: "Baseline GM",
		finalized_incremental_margin: "Incremental GM",
		finalized_incremental_gross_margin: "Incremental GM",
		projected_margin: "IA Projected GM",
		projected_gross_margin: "IA Projected GM",
		revenue_target: "Target Revenue",
		target_revenue: "Target Revenue",
		finalized_revenue: "Finalized Revenue",
		finalized_baseline_revenue: "Baseline Revenue",
		finalized_incremental_revenue: "Incremental Revenue",
		projected_revenue: "IA Projected Revenue",
		sales_units_target: "Target Sales Units",
		target_sales_units: "Target Sales Units",
		finalized_sales_units: "Finalized Sales Units",
		finalized_baseline_sales_units: "Baseline Sales Units",
		finalized_incremental_sales_units: "Incremental Sales Units",
		actuals_sales_units: "Actualized Sales Units",
		actuals_revenue: "Actualized Revenue",
		actuals_gross_margin: "Actualized GM",
		projected_sales_units: "IA Projected Sales Units",
		scenario_1: "Scenario 1",
		scenario_2: "Scenario 2",
		affinity_margin: "Margin Affinity",
		affinity_sales: "Sales Affinity",
		aum: "AUM $",
		aur: "AUR $",
		baseline_margin: "Baseline Margin",
		baseline_sales: "Baseline Revenue",
		baseline_sales_units: "Baseline Sales Units",
		cannibalization_margin: "Cannibalization",
		cannibalization_sales: "Cannibalization",
		incremental_margin: "Incremental Margin",
		incremental_sales: "Incremental Revenue",
		incremental_sales_units: "Incremental Sales Units",
		gross_margin: "GM $",
		margin: "GM $",
		baseline: "Baseline",
		sales: "Sales",
		incremental: "Incremental",
		pull_forward_margin: "Pull Forward",
		pull_forward_sales: "Pull Forward",
		revenue: "Revenue",
		sales_units: "Sales Units",
		gm_percent: "GM %",
		affinity: "Affinity",
		cannibalization: "Cannibalization",
		pull_forward: "Pull Forward",
		total: "Total",
		bxgy: "BxGy",
		percent_off: "% Off",
		extra_amount_off: "$ Off",
		fixed_price: "PP",
		projected: "IA Projected",
		target: "Target",
		finalized: "Finalized",
		actual: "Actual",
		lift: "Lift",
		EVENTS: "Event",
		net_incremental: "Lift",
		promo_spend: "Markdown Spend ($)",
		baseline_margin: "Baseline Margin",
		margin_positive: "Margin Positive Promotions",
		margin_negative: "Margin Negative Promotions",
		baseline_revenue: "Baseline Revenue",
		revenue_positive: "Revenue Positive Promotions",
		revenue_negative: "Revenue Negative Promotions",
		channelOptions: "Channel Type",
		adTypeOptions: "Ad Type",
		strategy_id: "Strategy",
		calendar_config_ids: "Calendar Configuration",
		rule_type: "Rule Type",
		rule_flexibility_type_id: "Flexibility",
		rule_id: "Rule Name",
		//chart labels 2nd step create strategy
		store_oh: "Store OH",
		dc_oh: "DC OH",
		dc_on_order: "DC on Order",
		store_in_transit: "Store in Transit",
		strategy_status: "Strategy Status",
	},
	landing_screen: "DECISION_DASHBOARD",
};
