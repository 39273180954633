import moment from "moment";

export const REPORTING = {
	screen_composition: {
		components: [
			{
				type: "filter",
				staticProps: {
					showPageHeader: true,
					showSaveFilter: true,
					pageHeader: "Reporting Dashboard",
					filterHeader: "Reporting Filters",
					showFilterButtons: true,
					apiEndpoint: "getProductConfig",
					showFilterChips: true,
					filter_config: [
						{
							type: "product_h1",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							filter_group_label: "Select Hierarchy",
							hideSearch: false,
						},
						{
							type: "product_h2",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "product_h3",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "product_h4",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "brand",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "product_hierarchy",
							filter_group: 1,
							hideSearch: false,
						},
						{
							type: "store_h1",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							filter_group_label: "Select Stores",
							hideSearch: false,
						},
						{
							type: "store_h2",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h3",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h4",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "store_h5",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "filters",
							filter_type: "store_hierarchy",
							filter_group: 2,
							hideSearch: false,
						},
						{
							type: "dateRange",
							is_mandatory: true,
							filter_endpoint: "",
							filter_type: "dateRange",
							filter_group: 3,
							filter_group_label: "Select Strategy and Date",
							range_limitation: "limited_period",
							max_selection_period: {
								num: 13,
								type: "weeks",
							},
							use_default: false, // will take from constants config if set to true
							start_date: moment()
								.subtract(4, "weeks")
								.add(1, "day"),
							end_date: moment(),
							date_picker_position: "down",
						},
						{
							type: "strategy_id",
							is_mandatory: false,
							is_multiple_selection: true,
							filter_endpoint: "model",
							filter_model: 4003,
							filter_type: "strategy_id",
							filter_group: 3,
							filter_group_label: "Select Strategy and Date",
							hideSearch: false,
						},
					],
				},
				functionProps: [
					{
						functionName: "onFilter",
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "reportingTab",
										dataType: "basic",
										value: "clearance_execution",
									},
								],
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "reportingFilterAPICallStatus",
										dataType: "basic",
										value: true,
									},
								],
							},
							{
								type: "api_function",
								apiEndPoint: "model",
								apiMethod: "post",
								params: [
									{
										source: "fixed",
										apiRequestKey: "id",
										value: 8000,
										dataType: "basic",
									},
									{
										source: "filters",
										dataType: "object",
										apiRequestKey: "parameters",
									},
									{
										source: "fixed",
										apiRequestKey: "parameters",
										apiRequestKeyNested: "view",
										value: "s1",
										dataType: "basic",
									},
								],
								responseFormatter: [
									{
										destination: "reducer",
										dataKey:
											"reportingClearanceExecutionTableData",
										dataType: "array",
										overwrite: true,
									},
								],
								onComplete: {
									actions: [
										{
											type: "validation",
											conditions: [
												{
													comparison: "exists",
													source: "response",
												},
											],
											conditionOperator: "or",
											onValidationSuccess: {
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"clearance_execution_view_by",
																dataType:
																	"basic",
																value: "s1",
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"reporting-tab-content",
																dataType:
																	"object",
																value: {
																	show: true,
																},
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"componentDisplayControls",
																subjectId:
																	"reporting-screen-emptyState",
																dataType:
																	"object",
																value: {
																	show: false,
																},
															},
														],
													},
												],
											},
										},
									],
								},
							},
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination: "reducer",
										dataKey: "filterModalOpen",
										value: false,
									},
								],
							},
						],
					},
				],
				dataProps: {
					filterModalOpen: {
						type: "derived",
						dataKey: "filterModalOpen",
					},
				},
			},
			{
				type: "div",
				id: "reporting-screen-emptyState",
				staticProps: {
					className: "common-content-container",
				},
				componentProps: {
					content: [
						{
							type: "emptyState",
							staticProps: {
								variant: "noPlan",
								layout: "horizontal",
								heading:
									"This is where you can see Reporting Data at different Levels",
								info: "Select the filters to view the Reporting Data",
								primaryButtonProps: {
									label: "Select Filters",
								},
							},
							functionProps: [
								{
									functionName: "onClick",
									actions: [
										{
											type: "reducer_function",
											responseFormatter: [
												{
													destination: "reducer",
													dataKey: "filterModalOpen",
													value: false,
													dataType: "basic",
												},
											],
										},
									],
								},
							],
						},
					],
				},
			},
			{
				type: "div",
				id: "reporting-tab-content",
				staticProps: {
					className: "box-container",
					customStyle: {
						margin: "20px",
						padding: "5px 15px 15px 15px",
					},
					display: {
						type: "controlled",
						default: {
							show: false,
						},
					},
				},
				componentProps: {
					content: [
						{
							type: "reportingTab",
							id: "reporting-tabs",
							staticProps: {
								width: "100%",
								tabs: [
									{
										id: "clearance_execution",
										label: "Clearance Execution",
									},
									{
										id: "clearance_planning",
										label: "Clearance Planning",
									},
									{
										id: "strategy_report",
										label: "Strategy Report",
									},
									{
										id: "md_heatMap",
										label: "Markdown Tree-map",
									},
								],
							},
							componentProps: {
								content: [
									{
										type: "div",
										tabContentId: "clearance_execution",
										componentProps: {
											content: [
												{
													type: "div",
													staticProps: {
														customStyle: {
															display: "flex",
															justifyContent:
																"flex-end",
															gap: "10px",
															marginTop: "10px",
														},
													},
													componentProps: {
														content: [
															{
																type: "select",
																id: "clearance_execution_view_by",
																staticProps: {
																	customStyle:
																	{
																		width: "200px",
																	},
																	isMultipleSelection: false,
																	hideClearSelection: true,
																	hideSearch: true,
																	updateSelectedOnEachSelection: true,
																	options: [
																		{
																			value: "s1",
																			label: "Store Week",
																		},
																		{
																			value: "s2",
																			label: "Temp Subclass Store",
																		},
																		{
																			value: "s3",
																			label: "Perm Subclass Store",
																		},
																	],
																	selectedOptions:
																		[
																			{
																				value: "s1",
																				label: "Store Week",
																			},
																		],
																},
																functionProps: [
																	{
																		functionName:
																			"onSelect",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "self",
																							apiRequestKey:
																								"selectedData",
																							dataType:
																								"array",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"clearance_execution_view_by",
																								apiResponseKey:
																									"selectedData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8000,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"clearance_execution_view_by",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"reportingClearanceExecutionTableData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															{
																alignment:
																	"right",
																id: "clearance-execution-download-button",
																type: "iconButton",
																staticProps: {
																	variant:
																		"primary",
																	iconClass:
																		"colorWhite",
																	icon: "file_download",
																	className:
																		"icon-button",
																	customStyle:
																	{
																		height: "35px",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report-download",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8000,
																							isDownload: true,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"clearance_execution_view_by",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "clearance-execution-report",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																],
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_execution_view_by",
															checkValue: "s1",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	tableId:
																		"clearance_execution_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store ID",
																					key: "Store ID",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store Name",
																					key: "Store Name",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Labor Cost ($)",
																					key: "Labor Cost ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearanceExecutionTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_execution_view_by",
															checkValue: "s2",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	tableId:
																		"clearance_execution_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Department",
																					key: "Department",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Class",
																					key: "Class",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Subclass",
																					key: "Subclass",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store ID",
																					key: "Store ID",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store Name",
																					key: "Store Name",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Labor Cost ($)",
																					key: "Labor Cost ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearanceExecutionTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_execution_view_by",
															checkValue: "s3",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	tableId:
																		"clearance_execution_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Department",
																					key: "Department",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Class",
																					key: "Class",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Subclass",
																					key: "Subclass",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store ID",
																					key: "Store ID",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store Name",
																					key: "Store Name",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Labor Cost ($)",
																					key: "Labor Cost ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearanceExecutionTableData",
																	},
																},
															},
														],
													},
												},
											],
										},
										functionProps: [
											{
												functionName: "onLoad",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"clearance_execution_view_by",
																dataType:
																	"basic",
																value: "s1",
															},
														],
													},
													{
														type: "api_function",
														runOnLoad: true,
														apiEndPoint: "model",
														apiMethod: "post",
														confirmation: {
															type: "check",
															conditions: [
																{
																	comparison:
																		"equals",
																	source: "reducer",
																	sourceId:
																		"reportingFilterAPICallStatus",
																	checkValue: false,
																},
															],
															conditionOperator:
																"or",
														},
														params: [
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"id",
																value: 8000,
															},
															{
																source: "filters",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "fixed",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"view",
																value: "s1",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"reportingClearanceExecutionTableData",
																dataType:
																	"array",
																overwrite: true,
															},
														],
													},
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"reportingFilterAPICallStatus",
																value: false,
															},
														],
													},
												],
											},
										],
									},
									{
										type: "div",
										tabContentId: "clearance_planning",
										componentProps: {
											content: [
												{
													type: "div",
													staticProps: {
														customStyle: {
															display: "flex",
															justifyContent:
																"flex-end",
															gap: "10px",
															marginTop: "10px",
														},
													},
													componentProps: {
														content: [
															{
																type: "select",
																id: "clearance_planning_view_by",
																staticProps: {
																	customStyle:
																	{
																		width: "200px",
																	},
																	isMultipleSelection: false,
																	hideClearSelection: true,
																	hideSearch: true,
																	updateSelectedOnEachSelection: true,
																	options: [
																		{
																			value: "s1",
																			label: "Item",
																		},
																		{
																			value: "s2",
																			label: "Subclass",
																		},
																		{
																			value: "s3",
																			label: "Class",
																		},
																		{
																			value: "s4",
																			label: "Department",
																		},
																		{
																			value: "s5",
																			label: "Division",
																		},
																		{
																			value: "s6",
																			label: "Division Store",
																		},
																		{
																			value: "s7",
																			label: "Inventory Distribution",
																		},
																	],
																	selectedOptions:
																		[
																			{
																				value: "s1",
																				label: "Item",
																			},
																		],
																},
																functionProps: [
																	{
																		functionName:
																			"onSelect",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "self",
																							apiRequestKey:
																								"selectedData",
																							dataType:
																								"array",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"clearance_planning_view_by",
																								apiResponseKey:
																									"selectedData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8001,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"clearance_planning_view_by",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"reportingClearancePlanningTableData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															{
																alignment:
																	"right",
																id: "clearance-planning-download-button",
																type: "iconButton",
																staticProps: {
																	variant:
																		"primary",
																	iconClass:
																		"colorWhite",
																	icon: "file_download",
																	className:
																		"icon-button",
																	customStyle:
																	{
																		height: "35px",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report-download",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8001,
																							isDownload: true,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"clearance_planning_view_by",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "clearance-planning-report",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																],
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_planning_view_by",
															checkValue: "s1",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	displayFooter: true,
																	tableId:
																		"clearance_planning_s1_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Department",
																					key: "Department",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Class",
																					key: "Class",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Subclass",
																					key: "Subclass",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Item ID",
																					key: "Item ID",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Item Description",
																					key: "Item Description",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Markdown Type",
																					key: "Markdown Type",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin %",
																					key: "Finalised Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Markdown Spend ($)",
																					key: "Finalised Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																					width: 220,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Inventory",
																					key: "Finalised Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Sellthrough (%)",
																					key: "Finalised Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised WOS",
																					key: "Finalised WOS",
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Discount (%)",
																					key: "Actual Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Units on Markdown",
																					key: "Actual Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Revenue ($)",
																					key: "Actual Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "MSRP ($)",
																					key: "MSRP ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "COST ($)",
																					key: "COST ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin ($)",
																					key: "Actual Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin %",
																					key: "Actual Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Markdown Spend ($)",
																					key: "Actual Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Inventory",
																					key: "Actual Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Sellthrough (%)",
																					key: "Actual Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual WOS",
																					key: "Actual WOS",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Units on Markdown",
																					key: "Deviation% Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Revenue",
																					key: "Deviation% Revenue",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Margin",
																					key: "Deviation% Margin",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Inventory",
																					key: "Deviation% Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "BPS Margin %",
																					key: "BPS Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearancePlanningTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_planning_view_by",
															checkValue: "s2",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	displayFooter: true,
																	tableId:
																		"clearance_planning_s2_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Department",
																					key: "Department",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Class",
																					key: "Class",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Subclass",
																					key: "Subclass",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Markdown Type",
																					key: "Markdown Type",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin %",
																					key: "Finalised Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Markdown Spend ($)",
																					key: "Finalised Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																					width: 220,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Inventory",
																					key: "Finalised Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Sellthrough (%)",
																					key: "Finalised Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised WOS",
																					key: "Finalised WOS",
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Discount (%)",
																					key: "Actual Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Units on Markdown",
																					key: "Actual Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Revenue ($)",
																					key: "Actual Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "MSRP ($)",
																					key: "MSRP ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "COST ($)",
																					key: "COST ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin ($)",
																					key: "Actual Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin %",
																					key: "Actual Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Markdown Spend ($)",
																					key: "Actual Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Inventory",
																					key: "Actual Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Sellthrough (%)",
																					key: "Actual Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual WOS",
																					key: "Actual WOS",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Units on Markdown",
																					key: "Deviation% Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Revenue",
																					key: "Deviation% Revenue",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Margin",
																					key: "Deviation% Margin",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Inventory",
																					key: "Deviation% Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "BPS Margin %",
																					key: "BPS Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearancePlanningTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_planning_view_by",
															checkValue: "s3",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	displayFooter: true,
																	tableId:
																		"clearance_planning_s3_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Department",
																					key: "Department",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Class",
																					key: "Class",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Markdown Type",
																					key: "Markdown Type",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin %",
																					key: "Finalised Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Markdown Spend ($)",
																					key: "Finalised Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																					width: 220,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Inventory",
																					key: "Finalised Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Sellthrough (%)",
																					key: "Finalised Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised WOS",
																					key: "Finalised WOS",
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Discount (%)",
																					key: "Actual Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Units on Markdown",
																					key: "Actual Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Revenue ($)",
																					key: "Actual Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "MSRP ($)",
																					key: "MSRP ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "COST ($)",
																					key: "COST ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin ($)",
																					key: "Actual Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin %",
																					key: "Actual Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Markdown Spend ($)",
																					key: "Actual Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Inventory",
																					key: "Actual Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Sellthrough (%)",
																					key: "Actual Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual WOS",
																					key: "Actual WOS",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Units on Markdown",
																					key: "Deviation% Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Revenue",
																					key: "Deviation% Revenue",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Margin",
																					key: "Deviation% Margin",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Inventory",
																					key: "Deviation% Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "BPS Margin %",
																					key: "BPS Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearancePlanningTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_planning_view_by",
															checkValue: "s4",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	displayFooter: true,
																	tableId:
																		"clearance_planning_s4_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Department",
																					key: "Department",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Markdown Type",
																					key: "Markdown Type",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin %",
																					key: "Finalised Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Markdown Spend ($)",
																					key: "Finalised Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																					width: 220,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Inventory",
																					key: "Finalised Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Sellthrough (%)",
																					key: "Finalised Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised WOS",
																					key: "Finalised WOS",
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Discount (%)",
																					key: "Actual Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Units on Markdown",
																					key: "Actual Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Revenue ($)",
																					key: "Actual Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "MSRP ($)",
																					key: "MSRP ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "COST ($)",
																					key: "COST ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin ($)",
																					key: "Actual Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin %",
																					key: "Actual Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Markdown Spend ($)",
																					key: "Actual Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Inventory",
																					key: "Actual Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Sellthrough (%)",
																					key: "Actual Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual WOS",
																					key: "Actual WOS",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Units on Markdown",
																					key: "Deviation% Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Revenue",
																					key: "Deviation% Revenue",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Margin",
																					key: "Deviation% Margin",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Inventory",
																					key: "Deviation% Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "BPS Margin %",
																					key: "BPS Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearancePlanningTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_planning_view_by",
															checkValue: "s5",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	displayFooter: true,
																	tableId:
																		"clearance_planning_s5_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Markdown Type",
																					key: "Markdown Type",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin %",
																					key: "Finalised Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Markdown Spend ($)",
																					key: "Finalised Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																					width: 220,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Inventory",
																					key: "Finalised Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Sellthrough (%)",
																					key: "Finalised Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised WOS",
																					key: "Finalised WOS",
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Discount (%)",
																					key: "Actual Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Units on Markdown",
																					key: "Actual Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Revenue ($)",
																					key: "Actual Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "MSRP ($)",
																					key: "MSRP ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "COST ($)",
																					key: "COST ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin ($)",
																					key: "Actual Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin %",
																					key: "Actual Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Markdown Spend ($)",
																					key: "Actual Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Inventory",
																					key: "Actual Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Sellthrough (%)",
																					key: "Actual Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual WOS",
																					key: "Actual WOS",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Units on Markdown",
																					key: "Deviation% Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Revenue",
																					key: "Deviation% Revenue",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Margin",
																					key: "Deviation% Margin",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Inventory",
																					key: "Deviation% Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "BPS Margin %",
																					key: "BPS Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearancePlanningTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_planning_view_by",
															checkValue: "s6",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	displayFooter: true,
																	tableId:
																		"clearance_planning_s6_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store ID",
																					key: "Store ID",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Store Description",
																					key: "Store Description",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Week",
																					key: "Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Markdown Type",
																					key: "Markdown Type",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin %",
																					key: "Finalised Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Markdown Spend ($)",
																					key: "Finalised Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																					width: 220,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Inventory",
																					key: "Finalised Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Sellthrough (%)",
																					key: "Finalised Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised WOS",
																					key: "Finalised WOS",
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Discount (%)",
																					key: "Actual Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Units on Markdown",
																					key: "Actual Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Revenue ($)",
																					key: "Actual Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "MSRP ($)",
																					key: "MSRP ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "COST ($)",
																					key: "COST ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin ($)",
																					key: "Actual Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin %",
																					key: "Actual Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Markdown Spend ($)",
																					key: "Actual Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Inventory",
																					key: "Actual Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Sellthrough (%)",
																					key: "Actual Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual WOS",
																					key: "Actual WOS",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Units on Markdown",
																					key: "Deviation% Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Revenue",
																					key: "Deviation% Revenue",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Margin",
																					key: "Deviation% Margin",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Deviation% Inventory",
																					key: "Deviation% Inventory",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "BPS Margin %",
																					key: "BPS Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearancePlanningTableData",
																	},
																},
															},
														],
													},
												},
												{
													type: "div",
													dataProps: {
														is_visible: {
															type: "derived",
															dataKey:
																"clearance_planning_view_by",
															checkValue: "s7",
														},
													},
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	tableId:
																		"clearance_planning_s7_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Division",
																					key: "Division",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Department",
																					key: "Department",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Class",
																					key: "Class",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Subclass",
																					key: "Subclass",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Item ID",
																					key: "Item ID",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Item Description",
																					key: "Item Description",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Year Week",
																					key: "Year Week",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Discount (%)",
																					key: "Finalised Discount (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Discount Bucket",
																					key: "Discount Bucket",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Inventory Units",
																					key: "Inventory Units",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingClearancePlanningTableData",
																	},
																},
															},
														],
													},
												},
											],
										},
										functionProps: [
											{
												functionName: "onLoad",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"clearance_planning_view_by",
																dataType:
																	"basic",
																value: "s1",
															},
														],
													},
													{
														type: "api_function",
														runOnLoad: true,
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"id",
																value: 8001,
															},
															{
																source: "filters",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "fixed",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"view",
																value: "s1",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"reportingClearancePlanningTableData",
																dataType:
																	"array",
																overwrite: true,
															},
														],
													},
												],
											},
										],
									},
									{
										type: "div",
										tabContentId: "strategy_report",
										componentProps: {
											content: [
												{
													type: "div",
													staticProps: {
														customStyle: {
															display: "flex",
															justifyContent:
																"flex-end",
															gap: "10px",
															marginTop: "10px",
														},
													},
													componentProps: {
														content: [
															{
																alignment:
																	"right",
																id: "strategy-report-download-button",
																type: "iconButton",
																staticProps: {
																	variant:
																		"primary",
																	iconClass:
																		"colorWhite",
																	icon: "file_download",
																	className:
																		"icon-button",
																	customStyle:
																	{
																		height: "35px",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report-download",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8002,
																							isDownload: true,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "strategy-report",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																],
															},
														],
													},
												},
												{
													type: "div",
													componentProps: {
														content: [
															{
																type: "aggrid",
																staticProps: {
																	height: "400px",
																	tableId:
																		"strategy_report_table",
																	uniqueKey:
																		"row_id",
																	tableConfig:
																	{
																		column_headers:
																			[
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "",
																					key: "row_id",
																					action: null,
																					hidden: true,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Strategy ID",
																					key: "Strategy ID",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Strategy Name",
																					key: "Strategy Name",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Status",
																					key: "Status",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Start Date",
																					key: "Start Date",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "End Date",
																					key: "End Date",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Units on Markdown",
																					key: "Actual Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Revenue ($)",
																					key: "Actual Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: true,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin ($)",
																					key: "Actual Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Margin %",
																					key: "Actual Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Markdown Spend ($)",
																					key: "Actual Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Actual Sellthrough (%)",
																					key: "Actual Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Target Units for Markdown",
																					key: "Target Units for Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Target Revenue ($)",
																					key: "Target Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Target Margin ($)",
																					key: "Target Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Target Sellthrough (%)",
																					key: "Target Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Units on Markdown",
																					key: "Finalised Units on Markdown",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Revenue ($)",
																					key: "Finalised Revenue ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin ($)",
																					key: "Finalised Margin ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Margin %",
																					key: "Finalised Margin %",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Markdown Spend ($)",
																					key: "Finalised Markdown Spend ($)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																					width: 220,
																				},
																				{
																					type: "static_column",
																					mapping:
																						"data",
																					header: "Finalised Sellthrough (%)",
																					key: "Finalised Sellthrough (%)",
																					action: null,
																					hidden: false,
																					aggregate:
																						null,
																					sorting: true,
																					filter: false,
																					pinned: false,
																					row_group: false,
																				},
																			],
																	},
																},
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"strategyReportTableData",
																	},
																},
															},
														],
													},
												},
											],
										},
										functionProps: [
											{
												functionName: "onLoad",
												actions: [
													{
														type: "api_function",
														runOnLoad: true,
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"id",
																value: 8002,
															},
															{
																source: "filters",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"strategyReportTableData",
																dataType:
																	"array",
																overwrite: true,
															},
														],
													},
												],
											},
										],
									},
									{
										type: "div",
										tabContentId: "md_heatMap",
										componentProps: {
											content: [
												{
													type: "div",
													staticProps: {
														customStyle: {
															display: "flex",
															justifyContent:
																"flex-end",
															gap: "10px",
															marginTop: "10px",
														},
													},
													componentProps: {
														content: [
															{
																type: "select",
																id: "md_heatMap_view_by",
																staticProps: {
																	customStyle:
																	{
																		width: "200px",
																	},
																	isMultipleSelection: false,
																	hideClearSelection: true,
																	hideSearch: true,
																	updateSelectedOnEachSelection: true,
																	options: [
																		{
																			value: "subclass",
																			label: "Subclass",
																		},
																		{
																			value: "class",
																			label: "Class",
																		},
																		{
																			value: "department",
																			label: "Department",
																		},
																		{
																			value: "division",
																			label: "Division",
																		},
																	],
																	selectedOptions:
																		[
																			{
																				value: "subclass",
																				label: "Subclass",
																			},
																		],
																},
																functionProps: [
																	{
																		functionName:
																			"onSelect",
																		actions:
																			[
																				{
																					type: "reducer_function",
																					params: [
																						{
																							source: "self",
																							apiRequestKey:
																								"selectedData",
																							dataType:
																								"array",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"md_heatMap_view_by",
																								apiResponseKey:
																									"selectedData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																				{
																					type: "api_function",
																					apiEndPoint:
																						"model",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8003,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"md_heatMap_view_by",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view",
																						},
																					],
																					responseFormatter:
																						[
																							{
																								destination:
																									"reducer",
																								dataKey:
																									"reportingMdHeatmapData",
																								dataType:
																									"array",
																								overwrite: true,
																							},
																						],
																				},
																			],
																	},
																],
															},
															{
																alignment:
																	"right",
																id: "md-heatMap-download-button",
																type: "iconButton",
																staticProps: {
																	variant:
																		"primary",
																	iconClass:
																		"colorWhite",
																	icon: "file_download",
																	className:
																		"icon-button",
																	customStyle:
																	{
																		height: "35px",
																	},
																},
																functionProps: [
																	{
																		functionName:
																			"onClick",
																		actions:
																			[
																				{
																					type: "api_function",
																					apiEndPoint:
																						"report-download",
																					apiMethod:
																						"post",
																					params: [
																						{
																							source: "fixed",
																							apiRequestKey:
																								"id",
																							value: 8003,
																							isDownload: true,
																							dataType:
																								"basic",
																						},
																						{
																							source: "filters",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																						},
																						{
																							source: "reducer",
																							dataType:
																								"basic",
																							sourceId:
																								"md_heatMap_view_by",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"view",
																						},
																						{
																							source: "fixed",
																							dataType:
																								"object",
																							apiRequestKey:
																								"parameters",
																							apiRequestKeyNested:
																								"report_name",
																							value: "markdown-heatmap-report",
																						},
																					],
																					responseFormatter:
																						[],
																				},
																			],
																	},
																],
															},
														],
													},
												},
												{
													type: "div",
													id: "reporting-md-heatMap-container",
													staticProps: {
														customStyle: {
															marginTop: "10px",
														},
													},
													componentProps: {
														content: [
															{
																type: "heatmap",
																id: "reporting-md-heatMap",
																dataProps: {
																	data: {
																		type: "derived",
																		dataKey:
																			"reportingMdHeatmapData",
																	},
																},
															},
														],
													},
												},
											],
										},
										functionProps: [
											{
												functionName: "onLoad",
												actions: [
													{
														type: "reducer_function",
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"md_heatMap_view_by",
																dataType:
																	"basic",
																value: "subclass",
															},
														],
													},
													{
														type: "api_function",
														runOnLoad: true,
														apiEndPoint: "model",
														apiMethod: "post",
														params: [
															{
																source: "fixed",
																dataType:
																	"object",
																apiRequestKey:
																	"id",
																value: 8003,
															},
															{
																source: "filters",
																dataType:
																	"object",
																apiRequestKey:
																	"parameters",
															},
															{
																source: "fixed",
																apiRequestKey:
																	"parameters",
																apiRequestKeyNested:
																	"view",
																value: "subclass",
																dataType:
																	"basic",
															},
														],
														responseFormatter: [
															{
																destination:
																	"reducer",
																dataKey:
																	"reportingMdHeatmapData",
																dataType:
																	"array",
																overwrite: true,
															},
														],
													},
												],
											},
										],
									},
								],
							},
							dataProps: {
								activeTab: {
									type: "derived",
									dataKey: "reportingTab",
								},
							},
						},
					],
				},
			},
		],
	},
};
