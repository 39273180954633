/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
import { configureStore } from "@reduxjs/toolkit";
// import { ThunkAction } from "redux-thunk";

import { combineReducers } from "redux";
import authReducer from "./features/auth/auth";
import filterReducer from "./features/filters/filters";
import globalReducer from "./features/global/global";
import appReducer from "./features/appReducer/appReducer";

const rootReducer = combineReducers({
	auth: authReducer,
	global: globalReducer,
	filters: filterReducer,
	app: appReducer,
});

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				// Ignore these paths in the state
				ignoredPaths: ["global.screenConfig"],
			},
		}),
});

// export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof rootReducer>;
// export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export default store;
