export const RULES_PAGE = {
  screen_composition: {
    components: [
      //Filter modal open
      {
        type: "div",
        id: "rules-filter-container",
        staticProps: {
          className: "rules-filter-container",
          display: {
            type: "controlled",
            // default: {
            //   show: true,
            // },
          },
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "reducer_function",
                confirmation: {
                  conditions: [
                    {
                      comparison: "exists",
                      source: "reducer",
                      sourceId:
                        "is_rule_strategy_id_temperory",
                    },
                  ],
                },
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "componentDisplayControls",
                    subjectId: "rules-filter-container",
                    dataType: "object",
                    value: {
                      show: false,
                    },
                    overwrite: false,
                  },
                ],
              },
            ],
          },
        ],
        componentProps: {
          content: [
            {
              id: "all_rules_filter",
              type: "filter",
              staticProps: {
                showSaveFilter: true,
                showPageHeader: true,
                filterHeader: "Filters",
                pageHeader: "Filters",
                accordionLabel: "Filters",
                showFilterButtons: true,
                showFilterChips: true,
                filter_config: [
                  {
                    type: "product_h1",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "product_hierarchy",
                    filter_group: 1,
                    filter_group_label: "Select Hierarchy",
                    hideSearch: false,
                  },
                  {
                    type: "product_h2",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "product_hierarchy",
                    filter_group: 1,
                    hideSearch: false,
                  },
                  {
                    type: "product_h3",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "product_hierarchy",
                    filter_group: 1,
                    hideSearch: false,
                  },
                  {
                    type: "product_h4",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "product_hierarchy",
                    filter_group: 1,
                    hideSearch: false,
                  },
                  {
                    type: "brand",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "product_hierarchy",
                    filter_group: 1,
                    hideSearch: false,
                  },
                  {
                    type: "store_h1",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "store_hierarchy",
                    filter_group: 2,
                    filter_group_label: "Select Stores",
                    hideSearch: false,
                  },
                  {
                    type: "store_h2",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "store_hierarchy",
                    filter_group: 2,
                    hideSearch: false,
                  },
                  {
                    type: "store_h3",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "store_hierarchy",
                    filter_group: 2,
                    hideSearch: false,
                  },
                  {
                    type: "store_h4",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "store_hierarchy",
                    filter_group: 2,
                    hideSearch: false,
                  },
                  {
                    type: "store_h5",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "filters",
                    filter_type: "store_hierarchy",
                    filter_group: 2,
                    hideSearch: false,
                  },
                  {
                    type: "rule_type",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "model",
                    filter_model: 9002,
                    filter_type: "rule_type",
                    filter_group: 3,
                    filter_group_label: "Rule selection",
                    hideSearch: false,
                  },
                  {
                    type: "rule_flexibility_type_id",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "model",
                    filter_model: 9003,
                    filter_type: "rule_flexibility_type_id",
                    filter_group: 3,
                    filter_group_label: "Flexibility",
                    hideSearch: false,
                  },
                  {
                    type: "rule_id",
                    is_mandatory: false,
                    is_multiple_selection: true,
                    filter_endpoint: "model",
                    filter_model: 9004,
                    filter_type: "rule_id",
                    filter_group: 3,
                    filter_group_label: "Rule Name",
                    hideSearch: false,
                  },
                ],
              },
              functionProps: [
                {
                  functionName: "onFilter",
                  actions: [
                    {
                      type: "api_function",
                      apiEndPoint: "model",
                      apiMethod: "post",
					  confirmation: {
						conditions: [
						  {
							comparison: "notExists",
							source: "reducer",
							sourceId: "is_rule_strategy_id_temperory",
							dataType: "basic",
						  },
						],
						conditionOperator: "and",
					  },
                      params: [
                        {
                          source: "fixed",
                          apiRequestKey: "id",
                          value: 9001,
                          dataType: "basic",
                        },
                        {
                          source: "filters",
                          dataType: "object",
                          apiRequestKey: "parameters",
                        },
                      ],
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey: "all_rules_table",
                          subjectId: "all_rules",
                          overwrite: true,
                          dataType: "array",
                        },
                      ],
					  onComplete: {
						actions: [
							{
								type: "reducer_function",
								responseFormatter: [
									{
										destination:
											"reducer",
										dataKey:
											"filterModalOpen",
										value: false,
									},
								],
							},
						]
					  }
                    },
                  ],
                },
              ],
              dataProps: {
                filterModalOpen: {
                  type: "derived",
                  dataKey: "filterModalOpen",
                },
              },
            },
          ],
        },
      },
      // Rules landing page configuration
      {
        type: "div",
        id: "rules_page",
        staticProps: {
          className: "common-content-container",
          display: {
            type: "controlled",
            // default: {
            //   show: true,
            // },
          },
        },
        componentProps: {
          content: [
            {
              id: "all_rules",
              type: "aggrid",
              staticProps: {
                tableTitle: "All Rules",
                height: "350px",
                borderBottom: "1px solid #EBECF0",
                paddingBottom: "20px",
                rowSelection: "single",
                disableDomLayout: true,
                rowDraggable: false,
                tableId: "all_rules",
                uniqueKey: "rule_id",
                tableConfig: {
                  column_headers: [
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "",
                      key: "",
                      action: "",
                      hidden: false,
                      sorting: false,
                      filter: true,
                      pinned: true,
                      suppressMenu: true,
                      checkbox_selection: true,
                      row_group: false,
                      is_edit: false,
                      maxWidth: 50,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Rule Name",
                      key: "rule_name",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: true,
                      sorting: true,
                      suppressMenu: false,
                      row_group: false,
                      cellRenderer:
                        "clickActionCellRenderer",
                      cellRendererParams: {
                        actions: [
                          {
                            type: "reducer_function",
                            params: [
                              {
                                source: "self",
                                apiRequestKey:
                                  "selectedRule",
                                dataType:
                                  "basic",
                              },
                            ],
                            responseFormatter: [
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "rule_det",
                                apiResponseKey:
                                  "selectedRule",
                                dataType:
                                  "basic",
                              },
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "createEditRuleFlag",
                                dataType:
                                  "basic",
                                value: true,
                              },
                            ],
                          },
                          {
                            type: "redirect",
                            link: "rules/create-rule",
                          },
                        ],
                      },
                      // width: 400,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Flexibility",
                      key: "rule_flexibility",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 150,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Rule Type",
                      key: "rule_type",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 360,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Division",
                      key: "product_h1_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 360,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Department",
                      key: "product_h2_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Class",
                      key: "product_h3_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Sub Class",
                      key: "product_h4_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "# Products",
                      data_type: "number",
                      key: "products",
                      action: null,
                      hidden: false,
                      pinned: false,
                      sorting: true,
                      row_group: false,
                      suppressMenu: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Description",
                      key: "rule_description",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      checkbox_selection: false,
                      suppressMenu: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Last Revised by",
                      key: "updated_by",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      checkbox_selection: false,
                      suppressMenu: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Last Revised On",
                      key: "updated_at",
                      data_type: "date",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: false,
                      pinned: false,
                      row_group: false,
                      suppressMenu: false,
                      // valueFormatter:
                      // 	"dateRangeFormatter",
                      // width: 240,
                    },
                  ],
                },
                // className: "rules_strategy",
                stopEditingWhenCellsLoseFocus: true,
                // rowStyleClass: "rowstyle_rules_strategy",
                enableDisableRowFunc: true,
                // orderKey: "priority",
                // sortByKeys: ["priority", "row_num"],
                // disabled_key: "is_disabled",
                domLayout: true,
                updateParent: true,
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "all_rules_table",
                  subjectId: "all_rules",
                },
              },
              dependantControls: [
                {
                  action: "rowSelection",
                  controls: [
                    {
                      id: "rules-create-button",
                      selection: "none",
                      switchKey: "show",
                      // actions: [
                      //   {
                      //     conditions: [
                      //       {
                      //         sourceId:
                      //           "is_rule_strategy_id_temperory",
                      //         source: "reducer",
                      //         comparison:
                      //           "notExists",
                      //       },
                      //     ],
                      //   },
                      // ],
                    },
                    {
                      id: "edit-rules-create-button",
                      selection: "single",
                      switchKey: "show",
                    },
                  ],
                },
              ],
              functionProps: [
                {
                  functionName: "onCellValueChanged",
                  actions: [
                    {
                      type: "reducer_function",
                      params: [
                        {
                          source: "self",
                          apiRequestKey: "all_rules",
                          dataType: "array",
                        },
                      ],
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey: "all_rules_table",
                          sourceId: "all_rules",
                          dataType: "array",
                        },
                      ],
                    },
                  ],
                },
              ],
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "rules-create-button",
                    type: "button",
                    pathSrc: "components",
                    componentPath: "ui/button/Button",
                    staticProps: {
                      variant: "primary",
                      label: "Create New Rule",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "reducer_function",
                            responseFormatter: [
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "rules_overlay_flag",
                                dataType:
                                  "basic",
                                value: true,
                                overwrite: true,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    id: "edit-rules-create-button",
                    type: "button",
                    pathSrc: "components",
                    componentPath: "ui/button/Button",
                    staticProps: {
                      variant: "primary",
                      label: "Edit Selected Rule",
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                          enabled: true,
                        },
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "reducer_function",
                            params: [
                              {
                                source: "table",
                                sourceId:
                                  "all_rules",
                                subjectId:
                                  "rule_id",
                                apiRequestKey:
                                  "selectedRule",
                                dataType:
                                  "basic",
                              },
                            ],
                            responseFormatter: [
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "rule_det",
                                subjectId:
                                  "rule_id",
                                apiResponseKey:
                                  "selectedRule",
                                dataType:
                                  "basic",
                                overwrite: true,
                              },
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "createEditRuleFlag",
                                dataType:
                                  "basic",
                                value: true,
                              },
                            ],
                          },
                          {
                            type: "redirect",
                            link: "rules/create-rule",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "reducer_function",
                confirmation: {
                  conditions: [
                    {
                      comparison: "exists",
                      source: "reducer",
                      sourceId:
                        "is_rule_strategy_id_temperory",
                    },
                  ],
                },
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "componentDisplayControls",
                    subjectId: "rules_page",
                    dataType: "object",
                    value: {
                      show: false,
                    },
                    overwrite: false,
                  },
                ],
              },
              {
                type: "api_function",
                apiEndPoint: "model",
                apiMethod: "post",
                confirmation: {
                  type: "check",
                  showErrorMessage: true,
                  conditions: [
                    {
                      comparison: "exists",
                      source: "reducer",
                      sourceId:
                        "is_rule_strategy_id_temperory",
                      // subjectId: "rule_product_level",
                      dataType: "basic",
                    },
                  ],
                  conditionOperator: "and",
                },
                params: [
                  {
                    source: "fixed",
                    apiRequestKey: "id",
                    value: 9001,
                    dataType: "object",
                    // overwrite:true,
                  },
                  {
                    source: "reducer",
                    dataType: "object",
                    sourceId: "rule_strategy_id_temperory",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "strategy_id",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "all_rules_table",
                    subjectId: "all_rules",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              {
                type: "api_function",
                apiEndPoint: "model",
                apiMethod: "post",
                confirmation: {
                  type: "check",
                  showErrorMessage: true,
                  conditions: [
                    {
                      comparison: "notExists",
                      source: "reducer",
                      sourceId:
                        "is_rule_strategy_id_temperory",
                      // subjectId: "rule_product_level",
                      dataType: "basic",
                    },
                  ],
                  conditionOperator: "and",
                },
                params: [
                  {
                    source: "fixed",
                    apiRequestKey: "id",
                    value: 9001,
                    dataType: "object",
                    // overwrite:true,
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "all_rules_table",
                    subjectId: "all_rules",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      // Import Rules page configuration
      {
        type: "div",
        id: "import_rules_page",
        staticProps: {
          className: "common-content-container",
          display: {
            type: "controlled",
            // default: {
            //   show: true,
            // },
          },
        },
        componentProps: {
          content: [
            {
              id: "all_rules",
              type: "aggrid",
              staticProps: {
                tableTitle: "All Rules",
                height: "350px",
                borderBottom: "1px solid #EBECF0",
                paddingBottom: "20px",
                rowSelection: "multiple",
                disableDomLayout: true,
                rowDraggable: false,
                tableId: "all_rules",
                uniqueKey: "rule_id",
                tableConfig: {
                  column_headers: [
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "",
                      key: "",
                      action: "",
                      hidden: false,
                      sorting: false,
                      filter: true,
                      pinned: true,
                      suppressMenu: true,
                      checkbox_selection: true,
                      row_group: false,
                      is_edit: false,
                      maxWidth: 50,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Rule Name",
                      key: "rule_name",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: true,
                      sorting: true,
                      suppressMenu: false,
                      row_group: false,
                      // width: 400,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Flexibility",
                      key: "rule_flexibility",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 150,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Rule Type",
                      key: "rule_type",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 360,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Division",
                      key: "product_h1_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 360,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Department",
                      key: "product_h2_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Class",
                      key: "product_h3_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Sub Class",
                      key: "product_h4_name",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      suppressMenu: false,
                      checkbox_selection: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "# Products",
                      data_type: "number",
                      key: "products",
                      action: null,
                      hidden: false,
                      pinned: false,
                      sorting: true,
                      row_group: false,
                      suppressMenu: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Description",
                      key: "rule_description",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      checkbox_selection: false,
                      suppressMenu: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Last Revised by",
                      key: "updated_by",
                      action: "",
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: true,
                      pinned: false,
                      checkbox_selection: false,
                      suppressMenu: false,
                      row_group: false,
                      // width: 240,
                    },
                    {
                      type: "static_column",
                      mapping: "data",
                      header: "Last Revised On",
                      key: "updated_at",
                      data_type: "date",
                      action: null,
                      hidden: false,
                      aggregate: null,
                      sorting: true,
                      filter: false,
                      pinned: false,
                      row_group: false,
                      suppressMenu: false,
                      // valueFormatter:
                      // 	"dateRangeFormatter",
                      // width: 240,
                    },
                  ],
                },
                // className: "rules_strategy",
                stopEditingWhenCellsLoseFocus: true,
                // rowStyleClass: "rowstyle_rules_strategy",
                enableDisableRowFunc: true,
                // orderKey: "priority",
                // sortByKeys: ["priority", "row_num"],
                // disabled_key: "is_disabled",
                domLayout: true,
                updateParent: true,
              },
              dataProps: {
                data: {
                  type: "derived",
                  dataKey: "all_rules_table",
                  subjectId: "all_rules",
                },
              },
              dependantControls: [
                {
                  action: "rowSelection",
                  controls: [
                    {
                      id: "strategy-rules-import-button",
                      selection:
                        "multiple-and-external-actions",
                      switchKey: "show",
                      actions: [
                        {
                          conditionOperator: "or",
                          conditions: [
                            {
                              sourceId:
                                "is_rule_strategy_id_temperory",
                              source: "reducer",
                              comparison:
                                "equals",
                              value: true,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              functionProps: [
                {
                  functionName: "onCellValueChanged",
                  actions: [
                    {
                      type: "reducer_function",
                      params: [
                        {
                          source: "self",
                          apiRequestKey: "all_rules",
                          dataType: "array",
                        },
                      ],
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey: "all_rules_table",
                          sourceId: "all_rules",
                          dataType: "array",
                        },
                      ],
                    },
                  ],
                },
              ],
              componentProps: {
                content: [
                  {
                    alignment: "bottom",
                    id: "strategy-rules-import-button",
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Submit and add selected rule",
                      display: {
                        type: "controlled",
                        default: {
                          show: false,
                          enabled: true,
                        },
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "api_function",
                            apiEndPoint: "model",
                            apiMethod: "post",
                            params: [
                              {
                                source: "fixed",
                                apiRequestKey:
                                  "id",
                                value: 9012,
                                dataType:
                                  "basic",
                              },
                              {
                                source: "reducer",
                                dataType:
                                  "object",
                                sourceId:
                                  "rule_strategy_id_temperory",
                                apiRequestKey:
                                  "parameters",
                                apiRequestKeyNested:
                                  "strategy_id",
                              },
                              {
                                source: "table",
                                dataType:
                                  "array",
                                sourceId:
                                  "all_rules",
                                subjectId:
                                  "rule_id",
                                apiRequestKey:
                                  "parameters",
                                apiRequestKeyNested:
                                  "rule_ids",
                              },
                            ],
                            responseFormatter: [
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "import_rule_validation_detail",
                                apiRequestKey:
                                  "data",
                                overwrite: true,
                              },
                            ],
                            onComplete: {
                              actions: [
                                {
                                  type: "validation",
                                  conditions:
                                    [
                                      {
                                        comparison:
                                          "isEmptyArray",
                                        source: "response",
                                        // sourceId: "data",
                                        // value: 200
                                      },
                                    ],
                                  conditionOperator:
                                    "or",
                                  onValidationSuccess:
                                  {
                                    actions:
                                      [
                                        {
                                          type: "api_function",
                                          apiEndPoint:
                                            "model",
                                          apiMethod:
                                            "post",
                                          params: [
                                            {
                                              source: "fixed",
                                              apiRequestKey:
                                                "id",
                                              value: 9013,
                                              dataType:
                                                "basic",
                                            },
                                            {
                                              source: "reducer",
                                              dataType:
                                                "object",
                                              sourceId:
                                                "rule_strategy_id_temperory",
                                              apiRequestKey:
                                                "parameters",
                                              apiRequestKeyNested:
                                                "strategy_id",
                                            },
                                            {
                                              source: "table",
                                              dataType:
                                                "array",
                                              sourceId:
                                                "all_rules",
                                              subjectId:
                                                "rule_id",
                                              apiRequestKey:
                                                "parameters",
                                              apiRequestKeyNested:
                                                "rule_ids",
                                            },
                                          ],
                                          responseFormatter:
                                            [
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rule_strategy_id_temperory",
                                                // apiRequestKey:
                                                // 	"data",
                                                apiResponseKey:
                                                  "strategy_id",
                                                overwrite: true,
                                              },
                                            ],
                                          onComplete:
                                          {
                                            actions:
                                              [
                                                {
                                                  type: "reducer_function",
                                                  params: [
                                                    {
                                                      source: "reducer",
                                                      sourceId:
                                                        "rule_strategy_id_temperory",
                                                      apiRequestKey:
                                                        "strategy_id",
                                                      dataType:
                                                        "basic",
                                                    },
                                                  ],
                                                  responseFormatter:
                                                    [
                                                      {
                                                        destination:
                                                          "reducer",
                                                        dataKey:
                                                          "step_1_A",
                                                        subjectId:
                                                          "strategy_id",
                                                        apiResponseKey:
                                                          "strategy_id",
                                                        overwrite: true,
                                                      },
                                                      {
                                                        destination:
                                                          "reducer",
                                                        dataKey:
                                                          "step_1_A",
                                                        subjectId:
                                                          "currentStep",
                                                        value: 2,
                                                        overwrite: false,
                                                      },
                                                      {
                                                        destination:
                                                          "reducer",
                                                        dataKey:
                                                          "changeFlagForRules",
                                                        value: true,
                                                        overwrite: true,
                                                      },
                                                    ],
                                                },
                                                {
                                                  type: "redirect",
                                                  link: "workbench/create-strategy",
                                                },
                                              ],
                                          },
                                        },
                                      ],
                                  },
                                },
                                {
                                  type: "validation",
                                  conditions:
                                    [
                                      {
                                        comparison:
                                          "isNotEmptyArray",
                                        source: "response",
                                      },
                                    ],
                                  conditionOperator:
                                    "or",
                                  onValidationSuccess:
                                  {
                                    actions:
                                      [
                                        {
                                          type: "reducer_function",
                                          params: [],
                                          responseFormatter:
                                            [
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "ruleValidationErrorPanel",
                                                dataType:
                                                  "basic",
                                                value: true,
                                                overwrite: true,
                                              },
                                            ],
                                        },
                                      ],
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    alignment: "bottom",
                    id: "strategy-rules-import-back-button",
                    type: "button",
                    staticProps: {
                      variant: "secondary",
                      label: "Back",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                    dataProps: {
                      is_visible: {
                        type: "derived",
                        dataKey:
                          "is_rule_strategy_id_temperory",
                        checkValue: true,
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "reducer_function",
                            params: [
                              {
                                source: "reducer",
                                sourceId:
                                  "rule_strategy_id_temperory",
                                apiRequestKey:
                                  "strategy_id",
                                dataType:
                                  "basic",
                              },
                            ],
                            responseFormatter: [
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "step_1_A",
                                subjectId:
                                  "strategy_id",
                                apiResponseKey:
                                  "strategy_id",
                                overwrite: true,
                              },
                              {
                                destination:
                                  "reducer",
                                dataKey:
                                  "step_1_A",
                                subjectId:
                                  "currentStep",
                                value: 2,
                                overwrite: false,
                              },
                              {
                                destination: "reducer",
                                dataKey: "step4ScreenMount",
                                value: false,
                                overwrite: true,
                              },
                            ],
                          },
                          {
                            type: "redirect",
                            link: "workbench/create-strategy",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "reducer_function",
                confirmation: {
                  conditions: [
                    {
                      comparison: "notExists",
                      source: "reducer",
                      sourceId:
                        "is_rule_strategy_id_temperory",
                    },
                  ],
                },
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "componentDisplayControls",
                    subjectId: "import_rules_page",
                    dataType: "object",
                    value: {
                      show: false,
                    },
                    overwrite: false,
                  },
                ],
              },
              {
                type: "api_function",
                apiEndPoint: "model",
                apiMethod: "post",
                confirmation: {
                  type: "check",
                  showErrorMessage: true,
                  conditions: [
                    {
                      comparison: "exists",
                      source: "reducer",
                      sourceId:
                        "is_rule_strategy_id_temperory",
                      // subjectId: "rule_product_level",
                      dataType: "basic",
                    },
                  ],
                  conditionOperator: "and",
                },
                params: [
                  {
                    source: "fixed",
                    apiRequestKey: "id",
                    value: 9001,
                    dataType: "object",
                    // overwrite:true,
                  },
                  {
                    source: "reducer",
                    dataType: "object",
                    sourceId: "rule_strategy_id_temperory",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "strategy_id",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "all_rules_table",
                    subjectId: "all_rules",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              {
                type: "api_function",
                apiEndPoint: "model",
                apiMethod: "post",
                confirmation: {
                  type: "check",
                  showErrorMessage: true,
                  conditions: [
                    {
                      comparison: "notExists",
                      source: "reducer",
                      sourceId:
                        "is_rule_strategy_id_temperory",
                      // subjectId: "rule_product_level",
                      dataType: "basic",
                    },
                  ],
                  conditionOperator: "and",
                },
                params: [
                  {
                    source: "fixed",
                    apiRequestKey: "id",
                    value: 9001,
                    dataType: "object",
                    // overwrite:true,
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "all_rules_table",
                    subjectId: "all_rules",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
      // Overlay
      {
        type: "fullScreenOverlay",
        componentProps: {
          content: [
            {
              type: "div",
              staticProps: {
                // className: "common-content-container",
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    staticProps: {
                      className:
                        "flex-container justify-space-between",
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            className:
                              "common-page-title",
                            customStyle: {
                              margin: "0",
                            },
                            variant: "h2",
                            content: "All Rules",
                          },
                        },
                        {
                          id: "return-to-all-rules-button",
                          type: "button",
                          staticProps: {
                            icon: "return",
                            iconClass: "",
                            className: "",
                            customStyle: {
                              fontSize: "14px",
                              margin: 0,
                              fontWeight: 500,
                              background:
                                "transparent",
                            },
                            iconType: "svg",
                            variant: "url",
                            label: "Return to All Rules",
                          },
                          functionProps: [
                            {
                              functionName:
                                "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter:
                                    [
                                      {
                                        destination:
                                          "reducer",
                                        dataKey:
                                          "rules_overlay_flag",
                                        dataType:
                                          "basic",
                                        value: false,
                                        overwrite: true,
                                      },
                                    ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    type: "div",
                    staticProps: {
                      className: "flex-container",
                      customStyle: {
                        marginTop: "20px",
                        flexWrap: "wrap",
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "div",
                          staticProps: {
                            className:
                              "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant:
                                    "h3",
                                  content:
                                    "Min - Max Discount %",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className:
                                    "rules-description",
                                  content:
                                    "Defines relationship between Private labels and National brands or between different brands",
                                },
                              },
                              {
                                type: "button",
                                pathSrc:
                                  "components",
                                componentPath:
                                  "ui/button/Button",
                                staticProps: {
                                  className:
                                    "rules-select-button",
                                  variant:
                                    "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName:
                                      "onClick",
                                    actions:
                                      [
                                        {
                                          type: "reducer_function",
                                          responseFormatter:
                                            [
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rules_overlay_flag",
                                                dataType:
                                                  "basic",
                                                value: false,
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "create_rule_page_header",
                                                dataType:
                                                  "basic",
                                                value: "Create New Min-Max Rule",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rule_type",
                                                dataType:
                                                  "basic",
                                                value: "min_max_percent",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "createEditRuleFlag",
                                                dataType:
                                                  "basic",
                                                value: true,
                                                overwrite: true,
                                              },
                                            ],
                                        },
                                        {
                                          type: "redirect",
                                          link: "rules/create-rule",
                                        },
                                      ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className:
                              "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant:
                                    "h3",
                                  content:
                                    "Step Size %",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className:
                                    "rules-description",
                                  content:
                                    "Defines size-value relationship by making sure larger products have higher prices and also offers better value",
                                },
                              },
                              {
                                type: "button",
                                pathSrc:
                                  "components",
                                componentPath:
                                  "ui/button/Button",
                                staticProps: {
                                  className:
                                    "rules-select-button",
                                  variant:
                                    "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName:
                                      "onClick",
                                    actions:
                                      [
                                        {
                                          type: "reducer_function",
                                          responseFormatter:
                                            [
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rules_overlay_flag",
                                                dataType:
                                                  "basic",
                                                value: false,
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "create_rule_page_header",
                                                dataType:
                                                  "basic",
                                                value: "Create New Step Size % Rule",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rule_type",
                                                dataType:
                                                  "basic",
                                                value: "step_size_percent",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "createEditRuleFlag",
                                                dataType:
                                                  "basic",
                                                value: true,
                                                overwrite: true,
                                              },
                                            ],
                                        },
                                        {
                                          type: "redirect",
                                          link: "rules/create-rule",
                                        },
                                      ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className:
                              "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant:
                                    "h3",
                                  content:
                                    "Number of Markdowns",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className:
                                    "rules-description",
                                  content:
                                    "Allows you to decide number of markdowns that are applicable to selected products",
                                },
                              },
                              {
                                type: "button",
                                pathSrc:
                                  "components",
                                componentPath:
                                  "ui/button/Button",
                                staticProps: {
                                  className:
                                    "rules-select-button",
                                  variant:
                                    "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName:
                                      "onClick",
                                    actions:
                                      [
                                        {
                                          type: "reducer_function",
                                          responseFormatter:
                                            [
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rules_overlay_flag",
                                                dataType:
                                                  "basic",
                                                value: false,
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "create_rule_page_header",
                                                dataType:
                                                  "basic",
                                                value: "Create New Number of Markdowns Rule",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rule_type",
                                                dataType:
                                                  "basic",
                                                value: "no_of_markdown",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "createEditRuleFlag",
                                                dataType:
                                                  "basic",
                                                value: true,
                                                overwrite: true,
                                              },
                                            ],
                                        },
                                        {
                                          type: "redirect",
                                          link: "rules/create-rule",
                                        },
                                      ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className:
                              "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant:
                                    "h3",
                                  content:
                                    "Minimum Weeks on same price point",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className:
                                    "rules-description",
                                  content:
                                    "Allows how often does a MD needs to be changed for the selected lot",
                                },
                              },
                              {
                                type: "button",
                                pathSrc:
                                  "components",
                                componentPath:
                                  "ui/button/Button",
                                staticProps: {
                                  className:
                                    "rules-select-button",
                                  variant:
                                    "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName:
                                      "onClick",
                                    actions:
                                      [
                                        {
                                          type: "reducer_function",
                                          responseFormatter:
                                            [
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rules_overlay_flag",
                                                dataType:
                                                  "basic",
                                                value: false,
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "create_rule_page_header",
                                                dataType:
                                                  "basic",
                                                value: "Create New Minimum Weeks on same price point",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rule_type",
                                                dataType:
                                                  "basic",
                                                value: "min_week_pp",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "createEditRuleFlag",
                                                dataType:
                                                  "basic",
                                                value: true,
                                                overwrite: true,
                                              },
                                            ],
                                        },
                                        {
                                          type: "redirect",
                                          link: "rules/create-rule",
                                        },
                                      ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                        {
                          type: "div",
                          staticProps: {
                            className:
                              "box-container rules-card",
                          },
                          componentProps: {
                            content: [
                              {
                                type: "text",
                                staticProps: {
                                  variant:
                                    "h3",
                                  content:
                                    "Applicable Discount %",
                                },
                              },
                              {
                                type: "text",
                                staticProps: {
                                  className:
                                    "rules-description",
                                  content:
                                    "Defines the allowable discounts for the selected products and stores",
                                },
                              },
                              {
                                type: "button",
                                pathSrc:
                                  "components",
                                componentPath:
                                  "ui/button/Button",
                                staticProps: {
                                  className:
                                    "rules-select-button",
                                  variant:
                                    "secondary",
                                  label: "Select",
                                },
                                functionProps: [
                                  {
                                    functionName:
                                      "onClick",
                                    actions:
                                      [
                                        {
                                          type: "reducer_function",
                                          responseFormatter:
                                            [
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rules_overlay_flag",
                                                dataType:
                                                  "basic",
                                                value: false,
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "create_rule_page_header",
                                                dataType:
                                                  "basic",
                                                value: "Create New Applicable Discount % Rule",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "rule_type",
                                                dataType:
                                                  "basic",
                                                value: "applicable_discount_percent",
                                                overwrite: true,
                                              },
                                              {
                                                destination:
                                                  "reducer",
                                                dataKey:
                                                  "createEditRuleFlag",
                                                dataType:
                                                  "basic",
                                                value: true,
                                                overwrite: true,
                                              },
                                            ],
                                        },
                                        {
                                          type: "redirect",
                                          link: "rules/create-rule",
                                        },
                                      ],
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
        dataProps: {
          showModal: {
            type: "derived",
            dataKey: "rules_overlay_flag",
          },
        },
      },
      //validation error modal
      {
        type: "div",
        staticProps: {
          customStyle: {
            zIndex: 2,
            position: "absolute",
          },
        },
        dataProps: {
          is_visible: {
            type: "derived",
            dataKey: "ruleValidationErrorPanel",
          },
        },
        componentProps: {
          content: [
            {
              type: "panel",
              staticProps: {
                size: "large",
                primaryButton: true,
              },
              dataProps: {
                isOpen: {
                  type: "derived",
                  dataKey: "ruleValidationErrorPanel",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      content: "Import rule validations",
                      variant: "h3",
                    },
                  },
                  {
                    type: "text",
                    staticProps: {
                      className: "rule_validation_header ",
                      content:
                        "Rules import has been terminated because following rules violates the validations.",
                      variant: "h5",
                    },
                  },
                  {
                    id: "rule_validation_error_table",
                    type: "aggrid",
                    staticProps: {
                      height: "400px",
                      tableId:
                        "rule_validation_error_table",
                      uniqueKey: "rule_id",
                      tableConfig: {
                        column_headers: [
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Rule Id",
                            key: "rule_id",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: true,
                            filter: true,
                            pinned: false,
                            row_group: false,
                            width: 100,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Rule Name",
                            key: "rule_name",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: true,
                            filter: true,
                            pinned: false,
                            row_group: false,
                            width: 250,
                          },
                          {
                            type: "static_column",
                            mapping: "data",
                            header: "Validation error",
                            key: "validation_message",
                            action: null,
                            hidden: false,
                            aggregate: null,
                            sorting: true,
                            filter: true,
                            pinned: false,
                            row_group: false,
                            width: 350,
                          },
                        ],
                      },
                    },
                    dataProps: {
                      data: {
                        type: "derived",
                        dataKey:
                          "import_rule_validation_detail",
                      },
                    },
                  },
                ],
              },
              functionProps: [
                {
                  functionName: "onClose",
                  actions: [
                    {
                      type: "reducer_function",
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey:
                            "ruleValidationErrorPanel",
                          dataType: "basic",
                          value: false,
                          overwrite: true,
                        },
                      ],
                    },
                  ],
                },
                {
                  functionName: "onConfirm",
                  actions: [
                    {
                      type: "reducer_function",
                      responseFormatter: [
                        {
                          destination: "reducer",
                          dataKey:
                            "ruleValidationErrorPanel",
                          dataType: "basic",
                          value: false,
                          overwrite: true,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
  on_load_functions: [
    {
      type: "reducer_function",
      on_load_confirmation: {
        type: "check",
        conditions: [
          {
            comparison: "exists",
            source: "reducer",
            sourceId: "is_rule_strategy_id_temperory",
            dataType: "basic",
            // value: true,
          },
        ],
      },
      params: [],
      responseFormatter: [
        {
          destination: "reducer",
          dataType: "object",
          dataKey: "componentDisplayControls",
          subjectId: "rules-create-button",
          overwrite: false,
          value: {
            show: false,
            enabled: true,
          },
        },
      ],
    },
  ],
};
