import store from "../../store";
import moment from "moment";
import { toastError } from "../../store/features/global/global";
import { invokeReducerAction } from "../../store/features/appReducer/appReducer";
import { loadResponseIntoStore } from "./dynamicActionHelper";
import { DEFAULT_DATE_FORMAT } from "../../constants/Constants";
import _ from "lodash";

export const pcdValidation = () => {
	const activeTab = store.getState()?.app?.step_1_A?.customise_by;
	const step_1_A = store.getState()?.app?.step_1_A;
	const tableData = store.getState()?.app?.tableData;

	// If total number of days < 7 for default, throw validation
	if (
		step_1_A?.customise_by === "weekly" &&
		moment(moment(step_1_A?.end_date).startOf("day")).diff(
			moment(step_1_A?.start_date).startOf("day"),
			"days"
		) +
			1 <
			7
	) {
		store.dispatch(
			toastError(
				"Please select a date range of more than 7 days to go ahead with default configuration"
			)
		);
		return false;
	}

	// Check that each pcd config duration is equal to or greater than the frequency entered
	let frequencyValidationFailed = false;
	step_1_A?.calendar_pcds?.every((pcd) => {
		if (
			pcd.frequency >
			moment(moment(pcd.end_date).startOf("day")).diff(
				moment(pcd.start_date).startOf("day"),
				"days"
			) +
				1
		) {
			frequencyValidationFailed = true;
			return false;
		}
	});

	if (frequencyValidationFailed) {
		store.dispatch(
			toastError(
				"Please make sure the date range has atleast as many days as the frequency value"
			)
		);
		return false;
	}

	// Verify that the full date range is accounted for
	if (activeTab === "weekly" || activeTab === "full_custom") {
		if (
			step_1_A?.calendar_pcds?.[0] &&
			moment(
				step_1_A?.calendar_pcds?.[0]?.details?.[0]?.pcd_start_date
			).isSame(step_1_A?.start_date, "day") &&
			step_1_A?.calendar_pcds?.[step_1_A?.calendar_pcds?.length - 1] &&
			moment(
				step_1_A?.calendar_pcds?.[step_1_A?.calendar_pcds?.length - 1]
					?.details?.[
					step_1_A?.calendar_pcds?.[
						step_1_A?.calendar_pcds?.length - 1
					]?.details?.length - 1
				]?.pcd_end_date
			).isSame(step_1_A?.end_date, "day")
		) {
			// Do nothing
		} else {
			store.dispatch(
				toastError(
					"PCDs doesn’t match the date range selected. Please update accordingly"
				)
			);
			return false;
		}
	} else if (activeTab === "config_object") {
		if (tableData?.["strategy-pcd-listing"]?.length) {
			loadResponseIntoStore(
				[
					{
						destination: "reducer",
						dataKey: "step_1_A",
						subjectId: "calendar_config_id",
					},
				],
				tableData?.["strategy-pcd-listing"]?.[0]?.calendar_config_id
			);
		} else {
			store.dispatch(
				toastError("Please select atleast one Calendar PCD")
			);
			return false;
		}
	}

	return true;
};

export const compareCalendarPcds = (newPcd, originalPcd) => {
	let hasPcdChanged = false;
	newPcd.every((pcd, index) => {
		// check start date, end date, frequency, details
		if (
			!moment(pcd.start_date).isSame(
				originalPcd?.[index]?.start_date,
				"day"
			) ||
			!moment(pcd.end_date).isSame(
				originalPcd?.[index]?.end_date,
				"day"
			) ||
			pcd.frequency != originalPcd?.[index]?.frequency
		) {
			hasPcdChanged = true;
			return false;
		}

		pcd?.details?.every((split, i) => {
			if (
				!moment(split.pcd_start_date).isSame(
					originalPcd?.[index]?.details?.[i]?.pcd_start_date,
					"day"
				) ||
				!moment(split.pcd_end_date).isSame(
					originalPcd?.[index]?.details?.[i]?.pcd_end_date,
					"day"
				)
			) {
				hasPcdChanged = true;
				return false;
			}
		});
		return true;
	});
	return hasPcdChanged;
};

export const checkForDateChange = () => {
	const step_1_A = store.getState()?.app?.step_1_A;
	const step_1_A_copy = store.getState()?.app?.step_1_A_copy;

	let hasDataChanged = false;
	if (step_1_A?.strategy_id) {
		if (
			!moment(step_1_A_copy?.start_date).isSame(
				step_1_A?.start_date,
				"day"
			) ||
			(!_.isEmpty(step_1_A_copy?.end_date) &&
				!moment(step_1_A_copy?.end_date).isSame(
					step_1_A?.end_date,
					"day"
				))
		) {
			hasDataChanged = true;
		}

		if (step_1_A_copy?.customise_by !== step_1_A.customise_by) {
			hasDataChanged = true;
		}

		// If edit flow, set date change flag when pcd config changes
		if (step_1_A_copy?.calendar_pcds && step_1_A?.calendar_pcds) {
			// Check if calendar pcds actually changes
			const hasPcdChanged = compareCalendarPcds(
				step_1_A?.calendar_pcds,
				step_1_A_copy?.calendar_pcds
			);
			if (hasPcdChanged) {
				hasDataChanged = true;
			}
		}

		if (
			step_1_A?.calendar_config_id !== step_1_A_copy?.calendar_config_id
		) {
			hasDataChanged = true;
		}
	}

	loadResponseIntoStore(
		[
			{
				destination: "reducer",
				dataKey: "step_1_A",
				subjectId: "date_change",
			},
		],
		hasDataChanged
	);

	return hasDataChanged;
};

export const getPcdConfigurationList = async () => {
	const step_1_A = store.getState()?.app?.step_1_A;

	const getPcdList = {
		id: 6002,
		parameters: {
			start_date: moment(step_1_A?.start_date).format(
				DEFAULT_DATE_FORMAT
			),
			end_date: moment(step_1_A?.end_date).format(DEFAULT_DATE_FORMAT),
			date_range_exact_match: true,
		},
	};

	const pcdConfigurationList = await store.dispatch(
		invokeReducerAction({
			payload: getPcdList,
			apiMethod: "post",
			apiEndPoint: "model",
		})
	);
	loadResponseIntoStore(
		[
			{
				destination: "reducer",
				dataKey: "pcdConfigurationList",
				overwrite: true,
			},
		],
		pcdConfigurationList
	);
	return pcdConfigurationList;
};

export const savePcdConfigToReducer = (pcdConfig) => {
	loadResponseIntoStore(
		[
			{
				destination: "reducer",
				dataKey: "step_1_A",
				subjectId: "calendar_pcds",
			},
		],
		pcdConfig
	);
};

export const splitDateRange = (
	startDate,
	endDate,
	frequency,
	activeTab,
	cycleCount //existing configurations count
) => {
	const startMoment = moment(startDate);
	const endMoment = moment(endDate);
	const result = [];
	const maxPCDLimit = 17; //including 0
	let count = cycleCount || 0;
	const step_1_A = store.getState()?.app?.step_1_A;

	if (activeTab === "weekly") {
		while (startMoment.isSameOrBefore(endMoment)) {
			let blockStart = startMoment.format(DEFAULT_DATE_FORMAT);
			// Calculate the end of the current week (Sunday)
			let currentWeekEnd = startMoment.clone().endOf("isoWeek");

			if (count === maxPCDLimit) {
				currentWeekEnd = endMoment.clone();
				if (
					!_.isEmpty(step_1_A?.calendar_pcds) &&
					(step_1_A?.step_count || 0) <= 1 &&
					step_1_A?.is_edited &&
					moment(currentWeekEnd).diff(blockStart, "days") >= frequency
				) {
					store.dispatch(
						toastError(
							"Number of PCDs cannot exceed 18. The last PCD will be adjusted to adhere to this limit"
						)
					);
				}
			}

			// Check if the end of the week exceeds the overall end date
			let blockEnd = moment
				.min(currentWeekEnd, endMoment)
				.format(DEFAULT_DATE_FORMAT);

			result.push({ pcd_start_date: blockStart, pcd_end_date: blockEnd });

			// Move to the start of the next week
			startMoment.add(
				moment(blockEnd).diff(blockStart, "days") + 1,
				"days"
			);
			count++;
		}
	} else {
		while (startMoment.isSameOrBefore(endMoment)) {
			let blockStart = startMoment.format(DEFAULT_DATE_FORMAT);
			let blockEnd = startMoment
				.add(frequency - 1, "days")
				.format(DEFAULT_DATE_FORMAT);

			if (moment(blockEnd).isAfter(endMoment)) {
				blockEnd = endMoment.format(DEFAULT_DATE_FORMAT);
			}
			if (count === maxPCDLimit) {
				blockEnd = endMoment.format(DEFAULT_DATE_FORMAT);

				if (
					(step_1_A?.step_count || 0) <= 1 &&
					moment(step_1_A?.end_date).isAfter(endMoment)
				) {
					//if the main end date is after cycle end date
					// and the PCD max limit is reached
					// then move current cycle enddate to be main date range enddate
					store.dispatch(
						toastError(
							"Number of PCDs cannot exceed 18. The last PCD will be adjusted to adhere to this limit"
						)
					);
					blockEnd = moment(step_1_A?.end_date).format(
						DEFAULT_DATE_FORMAT
					);
				}
			}

			result.push({ pcd_start_date: blockStart, pcd_end_date: blockEnd });

			if (count === maxPCDLimit) {
				startMoment.add(
					moment(blockEnd).diff(startMoment, "days") + 1,
					"days"
				);
			} else {
				startMoment.add(1, "days");
			}
			count++;
		}
	}
	return result;
};

export const resetPcdConfigOnDateChange = async (activeTab) => {
	// If the date range changes, reset pcdConfig
	// If activeTab = 1, recalculate
	// If activeTab = 2, check if start date is same and end date < = new end date within range, do nothing
	// If activeTab = 2, start date is different, clear everything
	// If activeTab = 2, check if start date is same and end date > new end date, truncate what's extra
	const step_1_A = store.getState()?.app?.step_1_A;

	let pcdConfig = _.cloneDeep(step_1_A?.calendar_pcds);
	if (activeTab === "full_custom") {
		if (
			!_.isEmpty(pcdConfig?.[0]) &&
			pcdConfig?.[0]?.start_date &&
			moment(pcdConfig?.[0]?.start_date).isSame(
				moment(step_1_A?.start_date),
				"day"
			) &&
			pcdConfig?.[pcdConfig.length - 1]?.end_date &&
			!moment(pcdConfig?.[pcdConfig.length - 1]?.end_date).isSameOrBefore(
				moment(step_1_A?.end_date)
			)
		) {
			let updatedPcdConfig = _.cloneDeep(pcdConfig);
			// Find the index where the new end date falls within the range. Change that end date and splice the rest
			let lastValidPcdIndex = pcdConfig.findIndex((config) => {
				return moment(step_1_A?.end_date).isBetween(
					moment(config.start_date),
					moment(config.end_date),
					null,
					[]
				);
			});
			if (lastValidPcdIndex !== -1) {
				// Remove all greater indices
				updatedPcdConfig.length = lastValidPcdIndex + 1;
				// In the last valid pcd Index, find the split where the new end date falls and
				let lastValidPcd = _.cloneDeep(
					updatedPcdConfig[lastValidPcdIndex]
				);
				let updatedPcdSplit = lastValidPcd?.details;
				let lastValidSplitIndex = lastValidPcd?.details.findIndex(
					(split) => {
						return moment(
							moment(step_1_A?.end_date).format(
								DEFAULT_DATE_FORMAT
							)
						).isBetween(
							moment(split.pcd_start_date),
							moment(split.pcd_end_date),
							null,
							[]
						);
					}
				);
				if (lastValidSplitIndex === -1) return;
				updatedPcdSplit.length = lastValidSplitIndex + 1;
				updatedPcdSplit[lastValidSplitIndex].pcd_end_date = moment(
					step_1_A?.end_date
				);
				lastValidPcd.details = updatedPcdSplit;
				lastValidPcd.end_date = moment(step_1_A?.end_date);
				// If there's only 1 PCD split and the frequency is greater than the PCD split length, throw an error
				if (
					updatedPcdSplit.length === 1 &&
					lastValidPcd.frequency >
						moment(
							moment(updatedPcdSplit[0].pcd_end_date).startOf(
								"day"
							)
						).diff(
							moment(updatedPcdSplit[0].pcd_start_date).startOf(
								"day"
							),
							"days"
						) +
							1
				) {
					store.dispatch(
						toastError(
							"Please check that the frequency of the PCD is lesser than or equal to the PCD length"
						)
					);
					lastValidPcd.frequency =
						moment(
							moment(updatedPcdSplit[0].pcd_end_date).startOf(
								"day"
							)
						).diff(
							moment(updatedPcdSplit[0].pcd_start_date).startOf(
								"day"
							),
							"days"
						) + 1;
				}
				updatedPcdConfig[lastValidPcdIndex] = lastValidPcd;
				savePcdConfigToReducer(updatedPcdConfig);
			}
		} else if (
			!_.isEmpty(pcdConfig?.[0]) &&
			pcdConfig?.[0]?.start_date &&
			!moment(pcdConfig?.[0]?.start_date).isSame(
				moment(step_1_A?.start_date),
				"day"
			)
		) {
			savePcdConfigToReducer([]);
		}
	} else if (activeTab === "weekly") {
		let defaultPcdConfig = {
			start_date: step_1_A?.start_date,
			end_date: step_1_A?.end_date,
			frequency: 7,
		};
		const details = splitDateRange(
			step_1_A?.start_date,
			step_1_A?.end_date,
			7,
			activeTab
		);

		defaultPcdConfig.details = details;
		savePcdConfigToReducer([defaultPcdConfig]);
	} else if (activeTab === "config_object") {
		// Get pcd config list
		const pcdConfigurationList = await getPcdConfigurationList();
		loadResponseIntoStore(
			[
				{
					destination: "reducer",
					dataKey: "pcdConfigurationList",
					overwrite: true,
				},
			],
			pcdConfigurationList
		);

		// Reset the previously selected calendar config
		loadResponseIntoStore(
			[
				{
					destination: "reducer",
					dataKey: "step_1_A",
					subjectId: "calendar_config_id",
					dataType: "object",
				},
			],
			null
		);
	}
};

export const revertDateChange = () => {
	const step_1_A_copy = store.getState()?.app?.step_1_A_copy;
	// Reset the dates to original in the redux store
	loadResponseIntoStore(
		[
			{
				destination: "reducer",
				dataKey: "step_1_A",
				subjectId: "start_date",
			},
		],
		step_1_A_copy?.start_date
	);

	loadResponseIntoStore(
		[
			{
				destination: "reducer",
				dataKey: "step_1_A",
				subjectId: "end_date",
			},
		],
		step_1_A_copy?.end_date
	);
};
