/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-escape */
import React from "react";
import moment from "moment";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { jsonToCSV } from "react-papaparse";
import _ from "lodash";
import {
	DEFAULT_DATE_FORMAT,
	columnsForIaTable,
	metricsColumns,
	metricsColumnsForIaTable,
	optimizationType,
} from "../../constants/Constants";
import { getStateFromRedux } from "./reducerUtility";
import store from "../../store";
import { toDollar } from "./formatter";

export const setErrorMessage = (error) => {
	let errorMessage = "Something went wrong. Try again";
	if (error.response) {
		const { data } = error.response;
		errorMessage = data && data.message ? data.message : errorMessage;
	}
	if (error.response && error.response.status === 404) {
		errorMessage = "We couldn't find what you're looking for";
	}
	if (error.response && error.response.status === 418) {
		errorMessage =
			"A new version of our application is now available. Please clear the browser cache and reload the application to access the latest version.";
	}
	return errorMessage;
};

export const toBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			return resolve({
				fileName: file.name,
				base64: reader.result,
			});
		};
		reader.onerror = (error) => {
			console.log(error);
			return reject(error);
		};
	});
};

export const isArrayEmpty = (data = []) => {
	if (!data) return true;
	if (data && data.length === 0) return true;
	return false;
};

export const isArrayEqual = (array1, array2) => {
	let isEqual = false;
	if (array1 && array2 && array1.length === array2.length) {
		isEqual = array1.every((element, index) => {
			return element === array2[index];
		});
	}
	return isEqual;
};

export const isArrayEqualCaseInsensitive = (array1, array2) => {
	let isEqual = false;
	if (array1 && array2 && array1.length === array2.length) {
		isEqual = array1.every((element, index) => {
			return element == array2[index];
		});
	}
	return isEqual;
};

export const arrayHasDuplicates = (array1) => {
	const array2 = array1.map((v) => v.toLowerCase());
	return array2.some((val, i) => array2.indexOf(val.toLowerCase()) !== i);
};

export const uniqueArray = (array1) => {
	return array1.filter((value, index, self) => {
		return self.indexOf(value) === index;
	});
};

export const htmlEncode = (text) => {
	const charMapping = {
		"&": "&amp;",
		"<": "&lt;",
		">": "&gt;",
		'"': "&quot;",
		"'": "&#039;",
		",": "&comma;",
	};
	return text.replace(/[&<>"',]/g, (m) => {
		return charMapping[m];
	});
};

export const htmlDecode = (text) => {
	const doc = new DOMParser().parseFromString(text, "text/html");
	return doc.documentElement.textContent;
};

export const htmlTagDecode = (text) => {
	return <span dangerouslySetInnerHTML={{ __html: text }} />;
};

export const dateFormatter = (params, format) => {
	switch (format) {
		case "hh:mm A":
			return moment(params.value).format("hh:mm A");
		default:
			return params.value
				? moment(params.value).format(DEFAULT_DATE_FORMAT)
				: null;
	}
};

export const getMonth = (date) => {
	const newDate = moment(date, "MM/DD/YYYY");
	const monthName = newDate.format("MMMM");
	const monthId = newDate.format("M");
	return {
		monthName,
		monthId,
	};
};

export const getYear = (date) => {
	const newDate = moment(date, "MM/DD/YYYY");
	return newDate.format("YYYY");
};

export const formatThousandSeparators = (num) => {
	const number = Math.round(num * 100) / 100;
	const numParts = number.toString().split(".");
	numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return numParts.join(".");
};

export const base64Regex = new RegExp(
	"^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$"
);

export const isArrayMissingItems = (array1, array2) => {
	const missingItems = _.difference(array1, array2);
	if (missingItems && missingItems.length) {
		return true;
	}
	return false;
};

// _.differenceBy(Orig, New, 'product_h5_id'); - In the original, but not in new - removed items
// _.differenceBy(New, Orig, 'product_h5_id'); - In new, but not in original - added items

export const compareArrayOfObjects = (array1, array2, key) => {
	const missingItems = _.differenceBy(array1, array2, key);
	if (missingItems && missingItems.length) {
		return missingItems;
	}
	return false;
};

export const compareObjectsByKey = (array1, array2, key) => {
	const formattedArray1 = _.uniq(array1.map((a) => a[key]));
	const formattedArray2 = _.uniq(array2.map((a) => a[key]));

	let isEqual = false;
	isEqual = formattedArray2.every((element, index) => {
		return formattedArray1.includes(element);
	});
	return isEqual;
};

export const downloadCSV = (csvData, fileName, delimiter = ";") => {
	let parsedCsvData = jsonToCSV(csvData, { delimiter: delimiter });
	const blob = new Blob([parsedCsvData], {
		type: "data:text/csv;charset=utf-8,",
	});
	const fileExtension = ".csv";
	FileSaver.saveAs(blob, fileName + fileExtension);
};

export const downloadXLSX = (excelData, fileName) => {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";

	const ws = XLSX.utils.json_to_sheet(excelData);
	const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
	const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + fileExtension);
};

export const readExcelFile = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			const data = e.target.result;
			const workbook = XLSX.read(data, { type: "binary" });

			// Assume the first sheet is the one you want to read
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];

			// Parse sheet data as JSON
			const jsonData = XLSX.utils.sheet_to_json(sheet);
			resolve(jsonData);
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsBinaryString(file.get("file"));
	});
};

export const findUniqueObjectsInArray = (array1, array2) => {
	const combinedArray = [...array1, ...array2];

	// Use a Set to store unique objects based on JSON representation
	const uniqueObjectsSet = new Set(
		combinedArray.map((obj) => JSON.stringify(obj))
	);

	// Convert the Set back to an array
	const uniqueObjectsArray = Array.from(uniqueObjectsSet, JSON.parse);

	return uniqueObjectsArray;
};

export const getQueryParameter = (payload) => {
	let parameterString = "";
	Object.keys(payload)?.forEach((key, i) => {
		if (i !== 0) {
			parameterString += "&";
		}
		parameterString += key + "=" + payload[key];
	});
	return parameterString;
};

export const replaceItemByKeys = (array, newObject, keysToMatch) => {
	// Find the index of the object with matching keys
	let updatedArray = [...array];
	const index = updatedArray.findIndex((item) =>
		keysToMatch.every((key) => item[key] === newObject?.[0]?.[key])
	);

	// If the keys are found, replace the object at that index; otherwise, add the new object
	if (index !== -1) {
		updatedArray[index] = { ...updatedArray?.[index], ...newObject?.[0] }; // Replace the object
	} else {
		updatedArray = [...updatedArray, ...newObject]; // Add the new object if the keys are not found
	}
	return updatedArray;
};

export const replaceArrayItemByKey = (array1, newData, uniqueKey) => {
	if (_.isEmpty(array1) || _.isEmpty(newData)) return array1;
	let updatedArray = [...array1];
	let existingItemIndex;
	if (uniqueKey?.length > 0) {
		uniqueKey?.map((key_item) => {
			existingItemIndex = updatedArray.findIndex(
				(item) => item[uniqueKey] === newData?.[0][uniqueKey]
			);
		});
	}
	if (existingItemIndex === -1) {
		return [...updatedArray, ...newData];
	}
	updatedArray[existingItemIndex] = newData[0];
	return updatedArray;
};

export const removeDuplicatesFromArray = (array1, uniqueKey) => {
	if (!uniqueKey) return array1;
	if (!_.isEmpty(array1)) {
		if (
			typeof array1[0] === "object" ||
			array1[0].hasOwnProperty(uniqueKey)
		) {
			// return _.uniqBy(array1, uniqueKey);
			return _.uniqBy(array1, (item) => String(item?.[uniqueKey]));
		} else {
			return _.uniqBy(array1);
		}
	}
	return array1;
};

export const arrayWithEllipsis = (array, number) => {
	// Check if the array has at least two elements
	if (Array.isArray(array) && array.length > number) {
		// Take the first two elements
		const [element1, element2] = array.slice(0, 2);

		// Display elements with a comma and ellipsis
		return `${element1}, ${element2}...+`;
	} else {
		return array ? `${array}` : "";
	}
};

export const createMultiplesOFInRange = (start, end, multipleOf) => {
	if ((start === 0 || start) && (end === 0 || end) && multipleOf) {
		if (start > end) {
			console.error(
				"Invalid range. Start should be less than or equal to end."
			);
			return null;
		}
		let resultObject = [];
		for (let i = start; i <= end; i++) {
			if (i % multipleOf === 0) resultObject.push(i);
		}
		return resultObject;
	}
};

export const columnParserForIaTable = (responseDataKey) => {
	let temp = getStateFromRedux(responseDataKey);
	// return temp;
};

export const convertToLabel = (phrase) =>
	phrase
		.toLowerCase()
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");

export const customData = {
	product: {
		label: "Product",
		data: "-200",
	},
	store: {
		label: "Store",
		data: "-200",
	},
	week: {
		label: "Week",
		data: [
			{
				id: 33,
				label: "Week 1",
				value: 33,
				pcd_end_date: "2023-12-05",
				pcd_start_date: "2023-11-28",
			},
			{
				id: 34,
				label: "Week 2",
				value: 34,
				pcd_end_date: "2023-12-13",
				pcd_start_date: "2023-12-06",
			},
			{
				id: 35,
				label: "Week 3",
				value: 35,
				pcd_end_date: "2023-12-21",
				pcd_start_date: "2023-12-14",
			},
			{
				id: 36,
				label: "Week 4",
				value: 36,
				pcd_end_date: "2023-12-31",
				pcd_start_date: "2023-12-22",
			},
		],
	},
};

export const valueDatatypeParser = (data_type, value) => {
	let regex = /^([0-9]+(?:[.][0-9]{0,2})?|.[0-9]+)$/;
	// Will add this condition based on input/metric type later, removed this for now as the NAN issue is resolvd just by this condition
	switch (data_type) {
		case "number":
			if (value) return value.replace(/[^0-9.]/g, "");
			break;
		default:
			return value;
	}
};

export const checkValueForNull = (value) => {
	return _.isUndefined(value) || _.isNull(value) ? "-" : value;
};
export const formatForIaPayload = (params, value) => {
	let temppayloadObject = {
		rowid: params?.data?.rowid,
		product_level_id: params?.data?.product_level_id,
		product_level_value: params?.data?.product_level_value,
		store_level_value: params?.data?.store_level_value,
		store_level_id: params?.data?.store_level_id,
		pcd_id: params?.colDef?.extra?.weekValue,
		discount_percent: value,
		is_locked: params?.data?.extra?.[params?.colDef?.key]?.is_locked
			? 1
			: 0,
	};
	return temppayloadObject;
};
